import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { adjacentPairs, safeStringify } from ".";
import { NEGATIVES, POSITIVES } from "../components/tempConstants";
import { LanguageISO, LanguageList } from "../constants/locales";
import { FallbackLang } from "../constants/screenings";

import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
  useStore,
} from "react-redux";
import type { AppDispatch, AppStore, RootState } from "../store/store";

// Use throughout your app instead of plain `useDispatch` and `useSelector` when
// trying to integrate TypeScript types
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppStore: () => AppStore = useStore;

/**
 * Quick hook for getting access to search parameters.
 * NOTE: URLSearchParams objects do not support bracket or dot access. You must
 * instead use `paramObject.get("KEYNAME")`
 *
 * @returns URLSearchParams the parsed parametes
 */
export function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * Retrieve a value from search parameters as a boolean. Note this is only a
 * helper and does not actually set up the useQuery hook.
 */
export function queryBoolean(
  usp: URLSearchParams,
  key: string,
  fallback: boolean
): boolean {
  for (let [k, v] of Array.from(usp.entries())) {
    if (k.toLocaleLowerCase() === key.toLocaleLowerCase()) {
      if (POSITIVES.includes(v.toLocaleLowerCase())) {
        return true;
      }
      if (NEGATIVES.includes(v.toLocaleLowerCase())) {
        return false;
      }
      console.warn(
        `Found matching key '${k}' but value '${v}' isn't boolean-like`
      );
      return fallback;
    }
  }
  return fallback;
}

/**
 * Extract a Language from the "lang" query parameter. Note this is only a
 * helper and does not actually set up the useQuery hook.
 */
export function queryLanguage(usp: URLSearchParams, fallback = FallbackLang) {
  for (let [k, v] of Array.from(usp.entries())) {
    if (k.toLocaleLowerCase() === "lang") {
      if (LanguageList.includes(v.toLocaleLowerCase() as LanguageISO)) {
        return v.toLocaleLowerCase();
      }
      break;
    }
  }
  return fallback;
}

/**
 * Quick local state wrapper to create sets designed to be updated by appending
 * only. Instead of a setter fn, the 2nd returned value is an 'append' function
 * that takes the item or items (as an array) to be added.
 *
 * @returns [current set, append function]
 */
export function useAppendingSet<T>() {
  const [baseSet, saveNewSet] = useState(new Set<T>());
  const append = (newItem: T | T[]) => {
    if (Array.isArray(newItem)) {
      newItem.forEach((i) => baseSet.add(i));
    } else {
      baseSet.add(newItem);
    }
    saveNewSet(new Set(baseSet));
    return baseSet.size;
  };
  return [baseSet, append];
}

/**
 * A version of useState for booleans that returns an object with pre-made
 * functions for setting to true / false, and for "toggling" the value.
 * Basically it's a flip-flop circuit in hook format.
 *
 * @param {boolean} init The initial value
 * @returns {{value: boolean, on: () => void, off: () => void, toggle: () => void}}
 */
export function useBooleanState(init?: boolean) {
  const [value, setBool] = useState(!!init);
  return {
    value,
    on: () => setBool(true),
    off: () => setBool(false),
    toggle: () => setBool(!value),
  };
}

/**
 * Simplify creation and attachment of simple keyboard event handlers
 * @param {Array<string|function>} list Alternating entries of keys and associated callbacks to run
 * @param {string} eventName Event to listen for, by default "keyup"
 * @param {() => boolean} attachmentCondition Predicate to determine if listener should be attached each update
 * @param {any[]} watchItems Values used in the triggering of the event listener attachment
 * @param {boolean} onlyFirst Whether to limit the handlers to firing only the first matching key predicate
 */
export function useKeyboardEvents(
  list: Array<string | Function>,
  eventName = "keyup",
  attachmentCondition?: () => boolean,
  watchItems: any[] = [],
  onlyFirst: boolean = false
) {
  if (list.length % 2 === 1)
    throw new Error(
      "List must be alternating pairs of keys/predicates and handlers"
    );
  // transform the left entry to always be a predicate function
  const eventMatches = adjacentPairs(list, () => {}).map(
    ([keyOrFn, handler]: [string | Function, (e: KeyboardEvent) => void]) => {
      if (typeof keyOrFn === "function") return [keyOrFn, handler];
      if (typeof keyOrFn === "string") {
        // We allow a "key" to be defined as a string of modifier keys joined by
        // periods, followed by the final modified key. e.g. "shift.ctrl.f"
        const chunks = keyOrFn.split(".");
        if (chunks.length === 1) {
          return [(ev: KeyboardEvent) => ev.key === keyOrFn, handler];
        }
        // The modifiers must be one of: alt, meta, ctrl, shift
        const modifiers = chunks.slice(0, -1);
        const finalKey = chunks[chunks.length - 1];
        return [
          (ev: KeyboardEvent) =>
            (modifiers as Array<"alt" | "meta" | "ctrl" | "shift">).every(
              (m) => ev[`${m}Key`]
            ) && ev.key === finalKey,
          handler,
        ];
      }
      throw new Error(
        `Key comparisons should be predicates or strings, got ${keyOrFn} (${typeof keyOrFn})`
      );
    }
  );
  const listener = (keyboardEvent: Event) => {
    for (let [keyPredicate, handler] of eventMatches) {
      if (keyPredicate(keyboardEvent)) {
        handler(keyboardEvent);
        if (onlyFirst) {
          return;
        }
      }
    }
  };
  useEffect(() => {
    if (!attachmentCondition || attachmentCondition()) {
      // console.warn("attaching keyboard listener!");
      window.addEventListener(eventName, listener);
      return () => {
        // console.warn("removing keyboard listener!");
        window.removeEventListener(eventName, listener);
      };
    }
  }, watchItems);
}

/**
 * Observe the size of an HTML element and emit its width and height when they
 * change.
 *
 * @param debugLogs Whether to emit console messages about detected size changes
 * @returns A tuple of the ref (which must be attached to an HTML element) and
 *          the most recently observed width and height for that ref
 */
export function useSize(
  debugLogs: boolean = false
): [MutableRefObject<HTMLElement | undefined>, number, number] {
  const ref = useRef<HTMLElement>();
  const [width, setWidth] = useState<number>(-1);
  const [height, setHeight] = useState<number>(-1);
  useEffect(() => {
    const obs = new ResizeObserver((entries) => {
      if (debugLogs) {
        console.log({ resize: entries });
        console.log(
          "new width(s): " + entries.map((e) => e.contentRect?.width).join(" ")
        );
      }
      const entry = entries[0];
      if (entry.contentRect) {
        setWidth(entry.contentRect.width);
        setHeight(entry.contentRect.height);
      }
    });
    if (ref.current) {
      obs.observe(ref.current);
    }
    return () => {
      if (ref.current) obs.unobserve(ref.current);
    };
  }, []);
  return [ref, width, height];
}

/**
 * Emit console logging every time any of the watched values changes within
 * this component.
 *
 * @param watchItems An object map of values to watch
 * @param label A user-friendly label, to avoid confusion when multiple change
 *              debuggers are logging to the console simultaneously
 */
export function useChangeDebugging(watchItems: object, label: string) {
  const cached = useRef<{ run: number; values: any[] }>({ run: 0, values: [] });
  useEffect(() => {
    let firstThisLoop = true;
    let emitted = 0;
    const entries = Object.entries(watchItems);
    for (let i = 0; i < entries.length; i++) {
      if (cached.current.values[i] !== entries[i][1]) {
        emitted += 1;
        if (firstThisLoop) {
          console.log(`### CD ${label} ${cached.current.run}`);
          firstThisLoop = false;
        }
        console.log(` |  ${entries[i][0]}`);
        if (cached.current.run === 0) {
          console.log(` ⮑ ∅ → ${safeStringify(entries[i][1])}`);
        } else {
          console.log(
            ` ⮑ ${safeStringify(cached.current.values[i])} → ${safeStringify(
              entries[i][1]
            )}`
          );
        }
      }
      cached.current.values[i] = entries[i][1];
    }
    console.log(` ♮ ${entries.length - emitted} values unchanged.`);
    cached.current.run++;
  });
}
