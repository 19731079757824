import { ComponentProps, useEffect, useRef } from "react";
import "./cards.css";
import { useAppSelector } from "../../../utils/hooks";
import { getAudioLocationFromId, getLanguageEntryAudioFilename } from "../../../models/audio";

export interface CardProps {
  stack: boolean;
  isSelected: boolean;
  art?: string;
  title?: string; // TODO -> label
  labelTranslationId?: string;
  description?: string;
  descriptionTranslationId?: string;

  scrollOnAudio?: boolean;
  audioHighlightKeys?: string[]
  useGlyphForArt?: string;
}

export const Card = ({stack = false, isSelected, audioHighlightKeys, ...props}: ComponentProps<"div"> & CardProps) => {
  const divWrapperRef = useRef<null|HTMLDivElement>(null)
  const activeAudio = useAppSelector(s => s.audio.activeAudio);
  const locations = audioHighlightKeys ?? [
    getAudioLocationFromId(props.labelTranslationId ?? "/missing/").filename,
    getAudioLocationFromId(props.descriptionTranslationId ?? "/missing/").filename
  ];
  function audioIsPlaying (): boolean {
    return (!!activeAudio.playing && locations.includes(activeAudio.filename));
  }

  useEffect(() => {
    if (props.scrollOnAudio && divWrapperRef.current && audioIsPlaying()) {
      divWrapperRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  })
  
  return (
    <div {...props} ref={divWrapperRef} className={`card ${stack ? "stack-card" : "grid-card"} ${isSelected ? "selected" : ""} ${props.className ?? ""} ${audioIsPlaying() ? "pulsing" : ""}`}>
      <div className="card-art">
        {props.useGlyphForArt ? 
          <span className="glyph-as-image">
            {props.useGlyphForArt}
          </span>
        : <img src={props.art}/>}
      </div>
      <div className="card-text-container">
        <div className="card-title answer-text">
          {props.title}
        </div>
        <div className="card-description answer-text">
          {props.description}
        </div>
      </div>
    </div>
  );
};