import { LanguageISO, SelectorAssets } from "../constants/locales";
import { ResponseLayout } from "./layouts";
import { isChoiceLike, QuestionDefinition, ResponseChoice } from "./questions";


export type AudioLocation = {filename: string, isLocal: boolean};

/**
 * Metadata about an audio track that may or may not be currently playing.
 */
export interface AudioInfo {
  /**
   * The symbolic URI of this audio media; this is checked as a key against the
   * map of "true" audio locations that have been registered in the slice.
   */
  filename: string;
  /**
   * NOT YET IMPLEMENTED
   */
  translationId?: string;
  /**
   * The actual URL that can be used to retrieve the media file.
   */
  src?: string;
  /**
   * Whether the file is actively playing, as detected by audio element events.
   */
  playing?: boolean;
  /**
   * The general state of attempts to play this file.
   */
  status?: 'loading' | 'error' | 'ok';
  /**
   * Error(s) that occurred while trying to request the audio resource.
   */
  requestError?: any;
  /**
   * Whether this file was queued as part of an AudioSequence.
   */
  isInSequence?: boolean;
}

export interface AudioSequence {
  /**
   * Unique name for this sequence, used to distinguish if actions are intended
   * to target it or another (potentially outdated) sequence. This is important
   * since we use timeouts to advance between tracks, so the actions may have
   * been scheduled during a different sequence.
   */
  id: string;
  /**
   * The list of filenames representing the tracks of this sequence.
   */
  files: string[];
  /**
   * Index of the last position within the sequence.
   */
  current: number;
  /**
   * Whether the sequence should loop back to the start or play only once.
   */
  loop: boolean;
  /**
   * The number of milliseconds from when one track finishes until the next
   * should start. (This is never perfectly accurate for various reasons.)
   */
  gap: number;
  /**
   * The number of milliseconds from when the last track finishes until the
   * first track should play again; only relevant if loop == true. (playSequence
   * will initialize this to be the same as gap, if not provided.)
   */
  loopGap: number;
  /**
   * The browser's numeric identifier for the last timeout scheduled for this
   * sequence, useful if you want to clear the timeout early, such as when audio
   * is terminated.
   */
  timerId?: number;
}

export function getAudioLocationFromId (translationId: string): AudioLocation {
  return {filename: `/audio/test_batch/${translationId}.mp3`, isLocal: true};
}
export function getAudioLocationFromIdOrNull (translationId?: string): AudioLocation | null {
  if (translationId) {
    return getAudioLocationFromId(translationId);
  }
  return null;
}

export function idToLocalFile (id: string, isTest: boolean = false): string {
  if (isTest) {
    return `/audio/test_batch/${id}.mp3`
  } else {
    return `/audio/${id}.mp3`;
  }
}

export function getLanguageEntryAudioFilename (l: LanguageISO): string {
    return `/audio/${SelectorAssets[l]?.narrationFile ?? "BADLANG:" + l}`;
}

/**
 * This is where we manually set the translationIds for instructional audio
 * used when building autoplay sequences for various question types.
 */
export function defaultInstructionalAudioForType (layout: ResponseLayout | '!! language selector') : string | null {
  switch (layout) {
    case ResponseLayout.Dropdown:
    case ResponseLayout.GridCards:
    case ResponseLayout.StackCards:
      return null;
    case ResponseLayout.AnatomicalRegion:
      return null;
    case ResponseLayout.Calendar:
      return "INS006";
    case ResponseLayout.ShortAnswer:
      return "INS008";
    case ResponseLayout.Measurement:
    case ResponseLayout.Numeric:
      return "INS001";
    case ResponseLayout.Empty:
      return null;
    case '!! language selector':
      return "P_GEN011";
    default:
      return null;
  }
}
export function getInstructionalAudio (q: QuestionDefinition) : AudioLocation | null {
  const translationId = q.instructionalTranslationId ?? defaultInstructionalAudioForType(q.layout);
  if (!translationId) return null;
  return {filename: idToLocalFile(translationId, true), isLocal: true};
}

export function getBasicUIAudio (element: 'next button') : AudioLocation {
  switch (element) {
    case "next button":
      return {filename: "--NEXT_AUDIO_MISSING--", isLocal: true};
  }
}

/**
 * Create an AudioSequence to narrate the elements of this page
 */
export function buildAudioLocationListForQuestion (q : QuestionDefinition|undefined) : AudioLocation[] {
  if (!q) return [];

  const locations: AudioLocation[] = [];

  // 1) the prompt audio
  if (q.translationId) {
    locations.push(getAudioLocationFromId(q.translationId));
  }
  if (q.subtextTranslationId) {
    locations.push(getAudioLocationFromId(q.subtextTranslationId));
  }

  // 2) the "instructions" for each response type
  const instructions = getInstructionalAudio(q);
  if (instructions) {
    locations.push(instructions);
  }

  // 3a) the audio for each choice
  if (isChoiceLike(q)) {
    q.choices.forEach((c : ResponseChoice) => {
      if (c.labelTranslationId) {
        locations.push(getAudioLocationFromId(c.labelTranslationId));
      }
      if (c.descriptionTranslationId) {
        locations.push(getAudioLocationFromId(c.descriptionTranslationId));
      }
    });
  }

  if (Array.isArray(q.nonconformingResponses)) {
    q.nonconformingResponses.forEach((c : ResponseChoice) => {
      if (c.labelTranslationId) {
        locations.push(getAudioLocationFromId(c.labelTranslationId));
      }
      if (c.descriptionTranslationId) {
        locations.push(getAudioLocationFromId(c.descriptionTranslationId));
      }
    });
  }

  // 3b) any audio elements for non-choice-like responses (TODO LATER!)

  // submit
  return locations;
}

