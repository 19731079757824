import React from "react";
import StackResponse, { ListChoiceCard } from "../Responses/StackResponse";
import { toggleChoice } from "../../../models/choices";
import GridResponse from "../Responses/GridResponse";
import CalendarDateResponse from "../Responses/CalendarDateResponse";
import FreeTextResponse from "../Responses/FreeTextResponse";
import NumericResponse from "../Responses/NumericResponse";
import MeasurementResponse from "../Responses/MeasurementResponse";
import Slider from "../Responses/deprecated/Slider";
import { safeStringify } from "../../../utils";
import AnatomicalModelResponse from "../Responses/AnatomicalModel/AnatomicalModelResponse";

export default function QuestionResponse(props) {
  const type = props?.questionDefinition?.type;
  if (props.questionDefinition.error) {
    return (
      <div>
        <h3 className="text-muted">Processing failure:</h3>;
        <pre
          className="p-2 mb-0"
          style={{
            whiteSpace: "pre-wrap",
            background: "#E0E0FF",
            borderRadius: "8px",
          }}
        >
          {safeStringify(props.questionDefinition)}
        </pre>
      </div>
    );
  }

  const exclusionary = props?.questionDefinition?.nonconformingResponses ?? [];

  function acceptExclusionary(item) {
    // debugger;
    const updatedAnswerObj = toggleChoice(
      item,
      props.answer.value?.value === item.value,
      exclusionary,
      false,
      props.answer,
      {
        id: props.questionDefinition.id,
        isExclusionary: true,
      }
    );
    if (typeof props.updateAnswer === "function") {
      props.updateAnswer(updatedAnswerObj);
    }
  }

  // TODO: this is bad practice for update/render lifecycles, hence why we
  // use below as {MainInput()} instead of <MainInput/> -- dfn. should be moved
  // outside this component to resolve issues with latter usage
  function MainInput() {
    const mainAnswer = props?.answer?.isExclusionary ? {} : props?.answer;
    switch (type) {
      case "cards":
        return <GridResponse answer={mainAnswer} {...props} />;
      case "stack": // new official term for list
      case "list": // deprecated name
        if (type === "list") {
          // TODO: maybe only when first loaded and validated?
          console.warn("Label 'list' is deprecated, please update to 'stack'!");
        }
        return <StackResponse answer={mainAnswer} {...props} />;
      case "short answer":
        return <FreeTextResponse {...props} answer={mainAnswer} />;
      case "date":
        return <CalendarDateResponse answer={mainAnswer} {...props} />;
      case "number":
        return <NumericResponse answer={mainAnswer} {...props} />;
      case "measurement":
        return <MeasurementResponse answer={mainAnswer} {...props} />;
      case "range":
        return <Slider answer={mainAnswer} {...props} />;
      case "anatomical area":
        return <AnatomicalModelResponse answer={mainAnswer} {...props} />;

      // removed options
      // case "static range":
      //   return <StaticRangePicker answer={mainAnswer} {...props} />;

      // unimplemented below here:
      case "dropdown":
        console.warn(`Unimplemented question type: ${type}`);
        break;
      default:
        console.log(`Unknown question type specified (${type})`);
    }
    return (
      <div>
        <h3 className="text-muted">Question ({type}) not available</h3>;
        <pre
          className="p-2 mb-0"
          style={{
            whiteSpace: "pre-wrap",
            background: "#E0E0FF",
            borderRadius: "8px",
          }}
        >
          {safeStringify(props.questionDefinition)}
        </pre>
      </div>
    );
  }

  const EXCLUSIONARY_FIRST_WHEN_MULTIPLE = true;

  return !!exclusionary?.length && type !== "cards" ? (
    <>
      {props?.questionDefinition?.exclusionaryFirst ||
      (EXCLUSIONARY_FIRST_WHEN_MULTIPLE && exclusionary?.length > 1)
        ? null
        : MainInput()}
      {/* the width of this needs to be similar to the regular card in stack */}
      <div className="exclusion-btn rect-img-card-container">
        {exclusionary.map?.((ec, i) => (
          <ListChoiceCard
            key={ec.id ?? `EXCLUSIONARY[${i}]`}
            item={ec}
            handleClick={acceptExclusionary}
            isSelected={props.answer?.value?.value === ec.value}
            style={{ marginBottom: "1rem" }}
            {...ec}
          />
        ))}
      </div>
      {props?.questionDefinition?.exclusionaryFirst ||
      (EXCLUSIONARY_FIRST_WHEN_MULTIPLE && exclusionary?.length > 1)
        ? MainInput()
        : null}
    </>
  ) : (
    <>{MainInput()}</>
  );
}
