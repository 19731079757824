import { METRIC, IMPERIAL, SYSTEMS, Dimension, SubdividedUnit, AtomicUnit } from "../models/measurements";

export const Length : Dimension = {
  // internal length is stored in pure inches
  [METRIC]: {
    unit: "cm",
    unitLong: "centimeters",
    conversionFactor: 2.54,
    listExhaustively: true,
    max: 250,
    default: 160
  } as AtomicUnit,

  [IMPERIAL]: {
    isProviderPreferred: true,
    isTuple: true,
    unit: ["ft", "in"],
    unitLong: ["feet", "inches"],
    default: [4, 4],
    conversionFactor: [12.0, 1.0],
    max: [7, 11],
    min: [0, 0],
    listExhaustively: [true, true]
  } as SubdividedUnit
};

export const Weight = {
  [METRIC]: {
    unit: "kg",
    unitLong: "kilograms",
    conversionFactor: 0.453592
  } as AtomicUnit,

  [IMPERIAL]: {
    isProviderPreferred: true,
    unit: "lb",
    unitLong: "pounds",
    conversionFactor: 1.0
  } as AtomicUnit
}

// const Volume = {

// }

export const KNOWN_MEASUREMENT_TYPES = {
  length: Length,
  weight: Weight
};