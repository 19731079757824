import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { Language } from "../../constants/locales";
import {
  AnswerSetSubmissionStatus,
  GeneralAnswer,
  MultiValuedAnswer,
  onlyAcceptMultiChoice,
  SingleValuedAnswer,
} from "../../models/answers";
import { DisplayableEntity } from "../../models/formula";
import { MetricsEvent } from "../../models/metrics";
import { Page, PAGE_LOADING, PageType } from "../../models/pages";
import { isChoiceLike, loopIndexedAnswerId, ResponseChoice } from "../../models/questions";
import { answerSetSlice } from "../../store/slices/answerSets";
import { audioSlice } from "../../store/slices/audio";
import { QuestionnaireDefinition } from "../../store/slices/definitions";
import { metricsSlice } from "../../store/slices/metrics";
import {
  AnswerUpdatePart,
  patientFlowSlice,
} from "../../store/slices/patient-flow";
import {
  emptyQuestionnaire,
  getQuestionnaireByTypeAndKind,
} from "../../store/slices/questionnaire-old.js";
import { safeStringify, spliceOutOf } from "../../utils";
import { evaluator } from "../../utils/evaluator";
import {
  useAppDispatch,
  useAppSelector,
  useChangeDebugging,
  useKeyboardEvents,
} from "../../utils/hooks";
import { CTA } from "../UI/buttons/CTA";
import {
  AUTO_ADVANCE_TIMEOUT,
  AUTO_ADVANCE_TYPES,
  COMPLEX_UI_TYPES,
  EMPTY_ANSWER,
} from "./constants_to_move";
import { CountdownTimer } from "./CountdownTimer";
import { HybridQuestionSummaryPage } from "./Pages/HybridQuestionSummaryPage";
import { StandardPage } from "./Pages/StandardPage";
import { NavigationalOverlay } from "./Partials/NavigationalOverlay";
import SingleQuestion from "./Partials/SingleQuestion";
import { StandardModalDialog } from "./Partials/StandardModalDialog";
import { IndexedString, ValueAtom } from "../../models/core-data-types";
import { SmallModalDialog } from "../UI/dialogs/SmallModalDialog";

// TODO once we have an evaluator service, this should live there
let universalFormulaCount = 0;

export interface SubpageCount {
  // loopPage: number;
  loopIteration: number;
  loopLength: number;
  loopChoiceIndex: number;
  loopPageCount: number;
  loopPageIndex: number;
  questionStage: number;
  questionStageMax: number;
  infomationStage: number;
}
function subpageless(): SubpageCount {
  return {
    // loopPage: 0,
    loopIteration: -1,
    loopLength: -1,
    loopChoiceIndex: -1,
    loopPageCount: -1,
    loopPageIndex: -1,
    questionStage: -1,
    questionStageMax: -1,
    infomationStage: -1,
  };
}

export interface ModalWindow {
  type: "jump dialog" | "markdown";
  imgUrl?: string;
  title?: string;
  content?: string | React.ReactNode;
}

// todo: update const name
export const RedesignedQuestionnaire = ({ reportType, language, quitFn }) => {
  // TODO: we need a way to toggle whether these values are loaded from the
  // simulator or the real patient flow
  const simulatorLanguage = useAppSelector((s) => s.simulator.language);
  const flowLanguage = useAppSelector((s) => s.patientFlow.language);
  const dualLanguage = useAppSelector((s) => s.simulator.dualLanguage);
  const debugHighlighting = useAppSelector(
    (s) => s.simulator.debugHighlighting
  );
  const showTrackingInfo = useAppSelector((s) => s.simulator.trackingInfo);
  const useNavLogic = useAppSelector((s) => s.simulator.navLogicEnabled);
  const autoProgression = useAppSelector(
    (s) => s.simulator.autoProgressScalars
  );
  const noDisableProgress = useAppSelector(
    (s) => s.simulator.noDisableProgress
  );

  function locale() {
    return { language: language ?? simulatorLanguage };
  }

  const dispatch = useAppDispatch();
  const questionnaire: QuestionnaireDefinition = useAppSelector(
    (s) =>
      getQuestionnaireByTypeAndKind(s, reportType, locale()) ??
      emptyQuestionnaire()
  ) as any;
  const globalAnswers = useAppSelector(
    (s) => s.patientFlow.answers.entities ?? {}
  );
  // const scrollContainer = useRef();

  const [pageNumber, setPageNumber_raw] = useState(0);
  const [subpage, setSubpage] = useState<SubpageCount>(subpageless());
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [questionHidden, setQuestionHidden] = useState<Record<string, string>>(
    {}
  );

  const [fullPageModal, setFullPageModal] = useState<ModalWindow | null>(null);
  function closeModal() {
    setFullPageModal(null);
  }

  useChangeDebugging([pageNumber, subpage], "questionnaire flow");

  /**
   * The actual current Page object retrieved from the questionnaire definition
   * based on the pageNumber. This is calculated because it handles cases where
   * the index is malformed or questionnaire definition is invalid/not loaded.
   * In all cases currentPage will always be a valid Page object.
   */
  const currentPage = useMemo(() => {
    if (questionnaire.loading) {
      return PAGE_LOADING;
    }
    if (
      Array.isArray(questionnaire?.pages) &&
      pageNumber > -1 &&
      pageNumber < questionnaire.pages.length
    ) {
      return questionnaire.pages[pageNumber];
    } else {
      console.error({ pageNav: pageNumber, pages: questionnaire?.pages });
      return PAGE_LOADING;
    }
  }, [questionnaire, pageNumber]);

  /**
   * Retrieve the current length of a loop.
   *
   * As of right now, all loops are "answer-as-iterable" so the logic is pretty
   * simple: if the loop anchor has answers, the loop is as long as the number
   * of answers.
   *
   * If/when we reintroduce other types, this will need more.
   */
  function determineLoopLength(loopAnchorKey?: string, isInset: boolean = false): number {
    if (typeof loopAnchorKey === "string") {
      const anchorQuestion = questionnaire.questions[loopAnchorKey];
      if (!anchorQuestion) {
        console.error(
          `Cannot find question ${loopAnchorKey} to anchor loop to!`
        );
        return -1;
      }
      if (!anchorQuestion.isMulti) {
        console.error(
          `Anchor questions must be muli-valued, otherwise loop is nonsensical`
        );
        return -1;
      }

      if (isInset) return 0;
      if (loopAnchorKey in globalAnswers) {
        return Array.isArray(globalAnswers[loopAnchorKey].values)
          ? globalAnswers[loopAnchorKey].values.length
          : 0;
      } else {
        console.warn(
          `Asked to determine loop length for unanswered question '${loopAnchorKey}'`
        );
        debugger;
        // question has not been answered!
        return 0;
      }
    }
    return -1;
  }

  function exitInsetLoop () {
    debugger;
  }

  /**
   * Determine if a displayable (an item with skipWhen or displayWhen formulas)
   * should be shown, given the current contextual data, primarily the answers
   * the user has provided.
   */
  function evaluateDisplayLogic(
    displayable: DisplayableEntity,
    answerContext: Record<string, any>,
    valueCache: Record<string, any>,
    label = `ad-hoc formula ${universalFormulaCount++}`,
    showFailReason = false
  ): boolean | string {
    if (!displayable) {
      debugger;
      console.error("Attempted to evaluate display logic for null object");
      return false;
    }
    if (!useNavLogic) return true;
    if (Object.keys(answerContext)?.length) {
      console.warn({ answerContext });
    }
    const expandedValues = {
      ...valueCache,
      ...globalAnswers,
    };
    const expandedContext = {
      ...questionnaire,
      _currentRow: subpage.loopIteration,
    };
    if (Array.isArray(displayable.skipWhen)) {
      const result = !evaluator(
        displayable.skipWhen,
        expandedValues,
        expandedContext,
        true,
        false,
        label + ".skipWhen"
      );
      return result
        ? true
        : showFailReason
        ? "question skipWhen evaluated to true"
        : false;
    }
    if (Array.isArray(displayable.displayWhen)) {
      const result = evaluator(
        displayable.displayWhen,
        expandedValues,
        expandedContext,
        true,
        false,
        label + ".displayWhen"
      );
      return result
        ? true
        : showFailReason
        ? "question displayWhen evaluated to false"
        : false;
    }
    return true;
  }

  /**
   * Determine which questions on the current page are hidden or displayed.
   */
  function reevaluateQuestionVisibility(questionKeys: string[]) {
    const hideMapping: Record<string, string> = {};
    questionKeys.forEach((q) => {
      // if (pageNumber > 100) debugger;
      const showOrFailReason = evaluateDisplayLogic(
        questionnaire.questions?.[q],
        {},
        {},
        q,
        true
      );
      if (typeof showOrFailReason === "string") {
        hideMapping[q] = showOrFailReason;
      }
    });
    setQuestionHidden(hideMapping);
  }
  useEffect(() => {
    reevaluateQuestionVisibility(currentPage?.questions || []);
  }, [currentPage, globalAnswers]);

  /**
   * Set the new page number. This accepts the number as-is. It does not check
   * if the page would be visible, nor does it even check if the index is valid.
   */
  function setPageNumber(
    n: number,
    refresh: boolean = false,
    forward: boolean = pageNumber < n,
    needsSubpageUpdates: boolean = false
  ) {
    recordActivityForTimeout("page_navigation");
    if (n === pageNumber && !refresh) return;
    const newPage = questionnaire?.pages?.[n];
    const questionsOnPage = newPage?.questions;
    setActiveQuestionIndex(
      forward ?? n > pageNumber ? 0 : (questionsOnPage?.length || 1) - 1
    );
    if (newPage?.type === PageType.Question) {
      reevaluateQuestionVisibility(questionsOnPage || []);
    }

    if (needsSubpageUpdates) {
      const nextLoop = newPage?.loopId;
      if (nextLoop && nextLoop !== currentPage.loopId) {
        alert("This should not be used to enter new loops until code is redone!");
        debugger;
        return;
        const loopLength = determineLoopLength(nextLoop, newPage.insetLoop);
        setSubpage({
          ...subpage,
          loopLength,
          loopIteration: forward ? 0 : loopLength - 1,
        });
      } else if (!nextLoop && currentPage.loopId) {
        setSubpage(subpageless());
      }
    }
    setPageNumber_raw(n);
  }
  function advanceOneNoUpdate () {
    setPageNumber(pageNumber + 1, false, true, false);
  }
  function setPageByQuestionID (
    id: string,
    refresh: boolean = false,
    forward?: boolean,
    needsSubpageUpdates: boolean = false
  ) {
    const newPageNum = questionnaire.pages.findIndex(p => p.questions?.includes(id));
    if (newPageNum === -1) {
      console.error(`Could not find page with question '${id}'`);
      return;
    }
    if (forward === undefined) {
      forward = newPageNum > pageNumber
    }
    setPageNumber(newPageNum, refresh, forward, needsSubpageUpdates);
  }

  const autoplayAudio = useAppSelector((s) => s.audio.autoplay);

  const activeQuestionId = useMemo(() => {
    return currentPage.questions?.[activeQuestionIndex] ?? "";
  }, [activeQuestionIndex, currentPage]);
  const activeQuestion = useMemo(() => {
    return questionnaire?.questions?.[activeQuestionId] ?? {};
  }, [questionnaire, activeQuestionId]);
  const activeAnswer = useMemo(() => {
    return globalAnswers[
      loopIndexedAnswerId(activeQuestionId, subpage.loopIteration)
    ];
  }, [globalAnswers, activeQuestionId]);

  /**
   * Walk through pages until we find one that is valid to display. This method
   * is shared for both forward and backward navigations (based on the forward
   * argument); the word "next" in the title is to be understood *temporally*.
   */
  function findNextValidPage(
    startingPageNumber: number,
    forward: boolean = true,
    ignore = {},
    [min, max] = [0, questionnaire.pages.length]
  ): [number, Page] {
    if (startingPageNumber < min || startingPageNumber >= max) {
      throw new Error(
        `Initial page num ${startingPageNumber} is outside bounds [${min}, ${max}]`
      );
    }
    const delta = forward ? 1 : -1;
    /**
     * The value cache is used to keep track of the results of formulas we've
     * already run this update cycle, to avoid duplication. It gets passed
     * down to all the evaluator calls and is updated within them.
     */
    const valueCache = new Map();
    const startingPage = questionnaire.pages[startingPageNumber];

    let targetPageNumber = startingPageNumber;
    let targetPage = questionnaire.pages[targetPageNumber];
    function tryNextPage(specific = targetPageNumber + delta) {
      targetPageNumber = specific;
      targetPage = questionnaire.pages[targetPageNumber];
    }

    while (targetPageNumber >= min && targetPageNumber < max) {
      // until we walk off the edge of the questionnaire...

      if (targetPage.loopId) {
        // when we hit a loopId, this inner handler walks through the loop pages
        // until we've left that loop's contiguous block
        const targetLoopId = targetPage.loopId;
        const startedInThisLoop = startingPage.loopId === targetLoopId;
        const loopAnchor = questionnaire.questions[targetLoopId];
        const loopAnchorPageNumber = questionnaire.pages.findIndex(p => p.questions?.[0] === targetLoopId);
        const loopAnchorPage = questionnaire.pages[loopAnchorPageNumber];
        const loopLength = (targetPage as any).insetLoop ?
          (startedInThisLoop ? 1 : 0)
          : determineLoopLength(targetLoopId);
        if (loopAnchor && loopLength > 0) {
          console.error(`loop '${targetLoopId}' has length ${loopLength}`);
          // if we're in the same loop as the initial question, use the active
          // loop iteration value, otherwise we start at the first or last:
          const initialIteration =
            targetLoopId === startingPage.loopId
              ? subpage.loopIteration
              : forward
              ? 0
              : loopLength - 1;
          let targetLoopIteration = initialIteration;
          // find the first and last page in the contiguous run of pages with the
          // same loopId as the initial page
          let firstLoopPage = targetPageNumber;
          while (
            questionnaire.pages[firstLoopPage - 1]?.loopId === targetLoopId
          ) {
            firstLoopPage--;
          }
          let lastLoopPage = targetPageNumber;
          while (
            questionnaire.pages[lastLoopPage + 1]?.loopId === targetLoopId
          ) {
            lastLoopPage++;
          }

          // until we've left the loop entirely...
          while (targetLoopIteration >= 0 && targetLoopIteration < loopLength) {
            if (targetLoopIteration !== initialIteration) {
              // when we start an iteration OTHER THAN THE VERY FIRST WE SEE, we
              // need to reset the target page to the "edge" of the loop
              tryNextPage(forward ? firstLoopPage : lastLoopPage);
            }
            while (
              targetPageNumber >= firstLoopPage &&
              targetPageNumber <= lastLoopPage
            ) {
              if (
                targetPageNumber === startingPageNumber &&
                targetLoopIteration === initialIteration
              ) {
                // we haven't yet moved from starting postion!
                console.log("First page is inside loop");
              } else {
                const [valid] = pageIsDisplayable(
                  targetPage,
                  startingPage,
                  forward,
                  valueCache,
                  { index: targetLoopIteration, length: loopLength },
                  ignore
                );
                if (valid) {
                  // right now we can be confident this will be defined since
                  // the determineLoopLength fn wouldn't return >0 otherwise
                  const answer: MultiValuedAnswer<IndexedString> | undefined =
                    onlyAcceptMultiChoice(globalAnswers[targetLoopId]);
                  const choiceOfNthAnswer = answer?.values?.[targetLoopIteration]?.choiceIndex ?? -999;
                  // NOTE: we always KEEP the previous loopChoiceIndex if there
                  // is one -- this is how we preserve it for hybrid/inset loops
                  // where we may not be on the expected iteration
                  const loopChoiceIndex = subpage.loopChoiceIndex > -1 ? subpage.loopChoiceIndex : choiceOfNthAnswer;
                  setSubpage({
                    ...subpage,
                    loopLength: loopLength,
                    loopIteration: targetLoopIteration,
                    loopPageCount: lastLoopPage - firstLoopPage,
                    loopPageIndex: targetPageNumber - firstLoopPage,
                    loopChoiceIndex
                  });
                  setPageNumber(targetPageNumber, false, forward);
                  return [targetPageNumber, targetPage];
                }
              }
              tryNextPage(); // target page num += delta
              // remember we don't call continue until we leave the block of
              // matching loop pages
            }
            // we walked off the edge of the loop, but may have more iterations to
            // visit
            targetLoopIteration += delta;
          } // targetLoopIteration is no longer in [0, loopLength]

          if (startedInThisLoop && (startingPage.insetLoop || loopAnchorPage?.type === PageType.HybridQuestionSummary)) {
            // SPECIAL: if we started in an "inset loop" or one whose anchor
            // page is a hybrid summary, then WHENEVER we leave the loop, we
            // should return to the summary page
            setSubpage(subpageless());
            setPageNumber(loopAnchorPageNumber, false, forward);
            return [loopAnchorPageNumber, loopAnchorPage];
          }
        } else {
          // loop definition was not found for ID, so bail on looping behavior
          tryNextPage();
          continue;
        }
        // END OF LOOP HANDLER
      } else {
        if (targetPageNumber !== startingPageNumber) {
          // this isn't a loop, so just test the page as is
          const [valid, displayedQuestions] = pageIsDisplayable(
            targetPage,
            startingPage,
            forward,
            valueCache,
            undefined,
            ignore
          );
          if (valid) {
            // we have finally found a displayable page
            setSubpage(subpageless());
            setPageNumber(targetPageNumber, false, forward);
            return [targetPageNumber, targetPage];
          }
        }
        tryNextPage();
        continue;
      }
    }

    console.warn("Found no valid pages to advance to!");
    return [startingPageNumber, questionnaire.pages[startingPageNumber]];
  }

  // return [boolean, Array<string>?]
  function pageIsDisplayable(
    targetPage,
    currentPage,
    forwards = true,
    valueCache = new Map(),
    loopSettings = {},
    ignore = {}
  ) {
    if (loopSettings) {
      if (
        loopSettings.length === 0 &&
        !ignore.loopLengthLimits &&
        !currentPage.showOnEmptyLoop
      ) {
        return [false];
      }
    }

    const tempAnswers = {};

    const shouldDisplayPage = evaluateDisplayLogic(
      targetPage,
      tempAnswers,
      valueCache
    );
    if (!shouldDisplayPage) {
      return [false];
    }

    if (targetPage.type === PageType.UniqueQuestion) {
      if (targetPage.uniqueType === "chief complaint followup") {
        const unrefinedCC = globalAnswers["chief complaint unrefined"];
        if (unrefinedCC && unrefinedCC.values?.length > 1) {
          return [true];
        } else {
          return [false];
        }
      } else if (targetPage.uniqueType === "region of problem followup") {
        const unrefinedCC = globalAnswers["region of problem unrefined"];
        if (unrefinedCC && unrefinedCC.values?.length > 1) {
          return [true];
        } else {
          return [false];
        }
      } else {
        console.error(
          `Displaying unknown unique question type ${targetPage.uniqueType}`,
          targetPage
        );
        return [true];
      }
    }

    if (targetPage.type === "question") {
      const activeQuestions = targetPage.questions.map((qId) =>
        evaluateDisplayLogic(
          questionnaire.questions[qId],
          tempAnswers,
          valueCache,
          qId
        )
      );
      if (activeQuestions.every((x) => x === false)) {
        return [false];
      } else {
        return [
          true,
          targetPage.questions.filter((q, i) => activeQuestions[i]),
        ];
      }
    }

    return [true];
  }

  function goToNextPage(ignore?: object) {
    if (autoplayAudio) {
      dispatch(audioSlice.actions.stopAudio()); // TEMPORARY FOR TESTING
    }
    const [newNum, newObj] = findNextValidPage(pageNumber, true, ignore);
    metricsForPageTransition(newNum, newObj, MetricsEvent.PageForward);
  }

  function goToPreviousPage(ignore?: object) {
    if (autoplayAudio) {
      dispatch(audioSlice.actions.stopAudio()); // TEMPORARY FOR TESTING
    }
    const [newNum, newObj] = findNextValidPage(pageNumber, false, ignore);
    metricsForPageTransition(newNum, newObj, MetricsEvent.PageForward);
  }

  function activateByIndex(i) {
    setActiveQuestionIndex(i);
  }

  const HARDCODED_DERIVED_ANSWERS: Record<
    string,
    (a: GeneralAnswer) => Record<string, GeneralAnswer>
  > = {
    "chief complaint unrefined": (a: GeneralAnswer) => {
      if (a.values?.length === 1) {
        return {
          "chief complaint": {
            value: { ...a.values[0] },
            questionKey: "chief complaint unrefined",
            key: "chief complaint",
            isMulti: false,
          } as SingleValuedAnswer<any>,
        };
      } else {
        return {
          "chief complaint": {
            value: { value: null },
            isMulti: false,
            key: "chief complaint",
          },
        };
      }
    },
    "region of problem unrefined": (a: GeneralAnswer) => {
      if (a.values?.length === 1) {
        return {
          "region of problem": {
            value: { ...a.values[0] },
            questionKey: "region of problem unrefined",
            key: "region of problem",
            isMulti: false,
          } as SingleValuedAnswer<any>,
        };
      } else {
        return {
          "region of problem": {
            value: { value: null },
            isMulti: false,
            key: "region of problem",
          },
        };
      }
    },
  };

  function calculateDerivedAnswers(
    newAnswer: GeneralAnswer,
    id: string
  ): Record<string, GeneralAnswer> {
    if (typeof HARDCODED_DERIVED_ANSWERS[id] === "function") {
      return HARDCODED_DERIVED_ANSWERS[id](newAnswer);
    } else {
      return {};
    }
  }

  function acceptAnswer(newAnswer: GeneralAnswer, id: string) {
    recordActivityForTimeout("answer_change");
    // const updatedAnswers = {
    //   ...globalAnswers,
    //   [id]: newAnswer,
    //   ...calculateDerivedAnswers(newAnswer, id),
    // };
    // console.log({ updatedAnswers });
    // setAnswers(updatedAnswers);
    const activeQuestion = questionnaire.questions?.[activeQuestionId];
    const answerUpdate: AnswerUpdatePart[] = [[id, activeQuestion, newAnswer]];
    Object.entries(calculateDerivedAnswers(newAnswer, id)).forEach(
      ([dak, dav]) => {
        answerUpdate.push([dak, { special: "HARDCODED_DERIVED_ANSWER" }, dav]);
      }
    );
    dispatch(patientFlowSlice.actions.acceptAnswers(answerUpdate));

    if (
      autoProgression &&
      (AUTO_ADVANCE_TYPES.includes(activeQuestion?.layout) ||
        activeQuestion?.asList) &&
      !activeQuestion?.isMulti
    ) {
      setTimeout(() => {
        // TODO: make this check if this is still the same question once the time is up
        completeQuestion();
      }, AUTO_ADVANCE_TIMEOUT);
    }
  }

  function whenNoInputFocused(event, shiftCB, nonShiftCB) {
    const focused = event.target.getRootNode()?.activeElement || event.target;
    if (["INPUT", "TEXTAREA"].includes(focused.tagName)) {
      return;
    }
    if (event.shiftKey) {
      if (typeof shiftCB === "function") {
        shiftCB(event);
      }
    } else {
      if (typeof nonShiftCB === "function") {
        nonShiftCB(event);
      }
    }
  }

  function metricsForPageTransition(newPageNum, newPageObj, eType) {
    const pageData = {
      fromPage: pageNumber,
      fromPageType: currentPage.type,
      toPage: newPageNum,
      toPageType: newPageObj,
      navSkipped: Math.abs(newPageNum - pageNumber),
      toPageQuestions: Array.isArray(newPageObj.questions)
        ? newPageObj.questions.slice()
        : undefined,
    };
    console.log({ pageData });
    dispatch(
      metricsSlice.actions.recordEvent({ type: eType, addlProps: pageData })
    );
  }

  function jumpToReview() {
    const r = questionnaire.pages?.findIndex((p) => p.type === "review");
    if (r !== -1) {
      setPageNumber(r, true, true, true);
      metricsForPageTransition(
        r,
        questionnaire.pages[r] ?? {},
        MetricsEvent.PageJump
      );
    }
  }

  function showJumpOptions() {
    setFullPageModal({ type: "jump dialog" });
  }

  function clearCurrentAnswer(e: KeyboardEvent) {
    if (e.altKey) {
      dispatch(patientFlowSlice.actions.clearAll({isEarly: true, reason: "Debug answer wipe"}));
      return;
    }
    const aId = loopIndexedAnswerId(activeQuestionId, subpage.loopIteration);
    if (aId in globalAnswers) {
      console.warn(`Found '${aId}' in answers, deleting`);
      dispatch(patientFlowSlice.actions.clearAnswers([aId]));
      // delete answers[aId];
      // setAnswers({ ...answers });
    } else {
      console.log(`Did not find '${aId}' in answers list`);
    }
  }

  const [renderForcer, setRenderForcer] = useState(0);
  function forceRender() {
    setRenderForcer((i) => i + 1);
  }

  useKeyboardEvents(
    [
      "ArrowRight",
      (e) => whenNoInputFocused(e, goToNextPage, completeQuestion),
      "ArrowLeft",
      (e) => whenNoInputFocused(e, goToPreviousPage, navigateBack),
      "~",
      (e) => whenNoInputFocused(e, jumpToReview, jumpToReview),
      "J",
      (e) => whenNoInputFocused(e, showJumpOptions),
      "C",
      (e) => whenNoInputFocused(e, clearCurrentAnswer),
      "Escape",
      (e) => (fullPageModal ? setFullPageModal(null) : forceRender()),
    ],
    "keyup",
    undefined,
    [
      pageNumber,
      activeQuestionIndex,
      findNextValidPage,
      goToNextPage,
      goToPreviousPage,
    ]
  );

  useEffect(() => {
    const listener = (beforeUnloadEvent) => {
      console.error(beforeUnloadEvent);
      beforeUnloadEvent.returnValue = true;
    };
    const result = window.addEventListener("beforeunload", listener);
    console.warn(`attached before unload listener with result ${result}`);
    return () => {
      console.error(`removing before unload listener`);
      window.removeEventListener("beforeunload", listener);
    };
  }, []);

  function completeQuestion() {
    console.warn(`COMPLETING P${pageNumber} (${currentPage.type})`);
    if (currentPage.type === PageType.Review) {
      dispatch(
        answerSetSlice.actions.saveCurrentAnswerSet(
          AnswerSetSubmissionStatus.FlowCompleted
        )
      );
      dispatch(metricsSlice.actions.saveMetricsSession());
    }
    if (
      currentPage.type !== PageType.Question ||
      activeQuestionIndex === currentPage.questions.length - 1
    ) {
      goToNextPage();
    } else {
      activateByIndex(activeQuestionIndex + 1);
    }
  }

  const [showDeletionModal, setShowDeletionModal] = useState(false);
  function navigateBack() {
    if (currentPage.type === "question") {
      if (currentPage.loopId && !!currentPage.insetLoop) {
        setShowDeletionModal(true);
        return;
      }
      if (activeQuestionIndex > 0) {
        activateByIndex(activeQuestionIndex - 1);
      } else {
        goToPreviousPage();
      }
    } else {
      // TODO: more logic here potentially
      goToPreviousPage();
    }
  }

  function deleteCurrentLoopIteration (withAnchorEntry: boolean) {
    if (!currentPage.loopId) return;
    const loopQuestions = questionnaire.pages.filter(p => p.loopId === currentPage.loopId).map(p => p.questions ?? []).flat();
    const anchorQuestion = questionnaire.questions[currentPage.loopId];
    const answerUpdates: AnswerUpdatePart[] = loopQuestions.map(qId => {
      const answerKey = loopIndexedAnswerId(qId, subpage.loopChoiceIndex);
      if (globalAnswers[answerKey]) {
        const empty = {...globalAnswers[answerKey]};
        if (empty.value) {empty.value = {value: null};}
        if (empty.values) {empty.values = [];}
        if (empty.nonconformingValues) {empty.nonconformingValues = [];}
        return [answerKey, questionnaire.questions[qId], empty] as AnswerUpdatePart;
      }
      return null;
    }).filter(u => u !== null);

    if (withAnchorEntry) {
      const anchorAnswer = onlyAcceptMultiChoice(globalAnswers[currentPage.loopId]);
      if (anchorAnswer) {
        answerUpdates.push([currentPage.loopId, anchorQuestion, {
          ...anchorAnswer,
          values: spliceOutOf(v => v.choiceIndex === subpage.loopChoiceIndex, anchorAnswer.values)
        }]);
      }
    }
    dispatch(patientFlowSlice.actions.acceptAnswers(answerUpdates));
    setPageByQuestionID(currentPage.loopId, false, undefined, true);
    setShowDeletionModal(false);
  }

  const headerMode: "backdrop" | "bubble" | "no-art" = useMemo(() => {
    if (fullPageModal && fullPageModal.type !== "jump dialog") {
      // when a modal is set
      return "no-art";
    }
    if (currentPage?.type === "language-selector") {
      return "backdrop";
    }
    if (!currentPage || !activeQuestion) {
      // when there is nothing active,
      return "no-art";
    }
    if (
      (!activeQuestion?.art && !activeQuestion?.contextualArt) ||
      COMPLEX_UI_TYPES.includes(activeQuestion.layout)
    ) {
      return "no-art";
    }
    return currentPage.questions?.indexOf(activeQuestion.id) > 0
      ? "bubble"
      : "backdrop";
  }, [activeQuestion, currentPage, fullPageModal]);

  const headerButtonState = useMemo(() => {
    function nextButtonState() {
      // highest priority is to acknowledge errors
      if (
        currentPage.error ||
        activeQuestion?.error ||
        questionHidden[activeQuestionId]
      ) {
        return {
          visible: true,
          active: true,
          error: true,
        };
      }

      // the last page never gets a next button
      if (pageNumber == questionnaire?.pages?.length - 1) {
        return {
          visible: false,
          active: false,
        };
      }

      // introduction modal is deprecated, but it uses standard next button
      if (fullPageModal?.type === "introduction") {
        return { active: true, visible: true };
      }
      // any page with a CTA
      if (
        currentPage?.type === "kiosk_start" ||
        currentPage?.callToAction ||
        questionnaire?.pages?.[pageNumber + 1]?.type === "success"
      ) {
        return {
          visible: false,
          active: false,
        };
      }

      if (currentPage.type === "question") {
        if (activeQuestion.type === "anatomical area") {
          return {
            visible: false,
            active: false,
          };
        }
        if (
          AUTO_ADVANCE_TYPES.includes(activeQuestion.type) &&
          !activeQuestion.isMulti
        ) {
          // we are on an auto-progression question
          if (!!(activeAnswer?.value?.value || activeAnswer?.values?.length)) {
            // but there is already an answer
            return {
              visible: true,
              active: true,
            };
          } else {
            return {
              visible: !autoProgression,
              active: noDisableProgress || false,
            };
          }
        } else {
          return {
            visible: true,
            // active: (activeAnswer.isAnswered)  // eventually?
            active:
              noDisableProgress ||
              !!(activeAnswer?.value?.value || activeAnswer?.values?.length),
          };
        }
      } else {
        // for now we give every other kind of page a next button...
        return {
          visible: true,
          active: true,
        };
      }
    }

    return {
      next: nextButtonState(),
      back: {
        visible: fullPageModal || pageNumber > 0 || activeQuestionIndex > 0,
        active: true,
        icon:
          fullPageModal // || currentPage?.type === "question-modal"
            ? "quit"
            : "back",
      },
      callToAction:
        questionnaire?.pages?.[pageNumber + 1]?.type === PageType.Success
          ? "I'm done"
          : null,
    };
  }, [
    activeQuestion,
    activeAnswer,
    fullPageModal,
    pageNumber,
    autoProgression,
  ]);

  const hiddenChoices = useMemo(() => {
    if (
      currentPage?.type !== "question" ||
      !currentPage.questions?.length ||
      !questionnaire?.questions
    )
      return [];
    const hiddenByQuestion = {};
    currentPage.questions.forEach((qID, i) => {
      if (Array.isArray(questionnaire.questions[qID]?.choices)) {
        const hiddenForThis = [];
        questionnaire.questions[qID].choices.forEach((c, i) => {
          if (c.displayWhen || c.skipWhen) {
            if (!evaluateDisplayLogic(c, {}, {}, `${qID}.choices.${i}`)) {
              hiddenForThis.push(i);
            }
          }
        });
        hiddenByQuestion[qID] = hiddenForThis;
      } else {
        hiddenByQuestion[qID] = [];
      }
    });
    console.log({ hiddenChoices: JSON.stringify(hiddenByQuestion) });
    return hiddenByQuestion;
  }, [currentPage]);

  const DEFAULT_BG_COLOR = "var(--evergreen)";
  const COLOR_PAGES = ["title", "kiosk_consent", "success"];
  const STYLE_BGS = {
    light: null,
    cool: "var(--sky-blue)",
  };
  const fullBackground = useMemo(() => {
    if (currentPage.style in STYLE_BGS) {
      return STYLE_BGS[currentPage.style];
    }
    if (COLOR_PAGES.includes(currentPage.type)) {
      return DEFAULT_BG_COLOR;
    }
    return null;
  }, [currentPage]);

  const contextualArt = useMemo(() => {
    if (!activeQuestion.contextualArt) return null;
    const givenAnswer = globalAnswers[activeQuestion.contextualArt];
    const relevantValue : ValueAtom | undefined = (
      (currentPage.loopId === activeQuestion.contextualArt) && givenAnswer.isMulti) 
        ? givenAnswer?.values?.find(v => v.choiceIndex === subpage.loopChoiceIndex)
        : givenAnswer?.value;
    const choiceNum = (relevantValue as IndexedString)?.choiceIndex;
    if (Number.isSafeInteger(choiceNum)) {
      const questionKey =
        givenAnswer.questionKey ?? activeQuestion.contextualArt;
      const contextualQuestion = questionnaire.questions[questionKey];
      if (isChoiceLike(contextualQuestion)) {
        const choice =
          contextualQuestion.choices[choiceNum as number];
        return choice?.art;
      }
      debugger;
      console.warn(
        `Found answer for contextualArt, but no matching question. Was there a mismatch?`
      );
      return null;
    }
    return null;
  }, [activeQuestion]);

  const [launchTimestamp] = useState(Date.now());
  const lastActionTimestamp = useRef(Date.now());
  const [lastActionType, setLastActionType] = useState("uninitialized");
  const [inactivityDialogTimerId, setInactivityDialogTimerId] = useState(-1);
  const [inactivityDialogActive, setInactivityDialogActive] = useState(false);
  const INACTIVITY_LIMIT = 30 * 60 * 1000;
  const INACTIVITY_WARNING_LENGTH = 1 * 60 * 1000;
  function inactiveDuration() {
    return Date.now() - lastActionTimestamp;
  }
  function idleLogoutTimestamp() {
    return lastActionTimestamp.current + INACTIVITY_LIMIT;
  }
  function displayIdleWarningTimestamp() {
    return (
      lastActionTimestamp.current + INACTIVITY_LIMIT - INACTIVITY_WARNING_LENGTH
    );
  }
  function logoutForInactivity() {
    dispatch(
      patientFlowSlice.actions.clearAll({ isEarly: true, reason: "inactive" })
    );
    quitFn();
  }
  function showInactivityDialog() {
    if (Date.now() >= displayIdleWarningTimestamp() - 200) {
      setInactivityDialogActive(true);
      setTimeout(() => logoutForInactivity(), INACTIVITY_WARNING_LENGTH);
    }
  }
  function recordActivityForTimeout(activityType) {
    clearTimeout(inactivityDialogTimerId);
    setLastActionType(activityType);
    lastActionTimestamp.current = Date.now();
    const timerId = setTimeout(
      () => showInactivityDialog(),
      INACTIVITY_LIMIT - INACTIVITY_WARNING_LENGTH
    );
    setInactivityDialogTimerId(timerId);
    if (inactivityDialogActive) setInactivityDialogActive(false);
  }
  useEffect(() => {
    recordActivityForTimeout("first_render");
  }, []);

  const [showQuitDialog, setShowQuitDialog] = useState(false);
  function providerQuit(saveData = true) {
    if (saveData) {
      // NOTE: we don't issue a report ID this way though, which is fine ig?
      dispatch(
        answerSetSlice.actions.saveCurrentAnswerSet(
          AnswerSetSubmissionStatus.QuitDialog
        )
      );
    }
    dispatch(metricsSlice.actions.saveMetricsSession());
    setTimeout(() => {
      dispatch(
        patientFlowSlice.actions.clearAll({
          isEarly: true,
          reason: "quit-button",
        })
      );
      setShowQuitDialog(false);
      quitFn();
    }, 1);
  }
  function longPressHandler(event) {
    if (event?.target?.id === "backward-button") {
      setShowQuitDialog(true);
    } else {
      // disable for demos because it was happening accidentally
      // showJumpOptions();
    }
  }

  const uniques_chiefComplaintFollowup = useMemo(() => {
    const CCs_chosen = Array.isArray(
      globalAnswers["chief complaint unrefined"]?.values
    )
      ? globalAnswers["chief complaint unrefined"].values.map(
          (a) =>
            questionnaire.questions["chief complaint unrefined"].choices[
              a.choiceIndex
            ]
        ) ?? []
      : [];
    return {
      type: "cards",
      art: "/images/symptoms.png",
      text: "You selected more than one choice. Which of these is your most important complaint?",
      // "textAudioFile": "Q_HPI001",
      // "subtext": "Select only one answer. This is the main reason you are seeing a doctor today.",
      reportLabel: "Chief Complaint (Singular)",
      choices: CCs_chosen,
    };
  }, [globalAnswers]);

  const uniques_regionOfProblemFollowup = useMemo(() => {
    const CCs_chosen = Array.isArray(
      globalAnswers["region of problem unrefined"]?.values
    )
      ? globalAnswers["region of problem unrefined"].values.map((a) => ({
          ...a,
          label: a.value,
        })) ?? []
      : [];
    return {
      type: "cards",
      art: "/images/symptoms.png",
      text: "You selected more than one region. Which of these has the worst problem/pain?",
      // "textAudioFile": "Q_HPI001",
      // "subtext": "Select only one answer. This is the main reason you are seeing a doctor today.",
      reportLabel: "Problem Region (Singular)",
      choices: CCs_chosen,
    };
  }, [globalAnswers]);

  const KNOWN_UNIQUES = [
    "chief complaint followup",
    "region of problem followup",
  ];

  // TEMP until navigation is reduxified
  function setPage_Overload(
    pageNum?: number,
    subPageConfig?: Partial<SubpageCount>,
    activeQI?: number
  ) {
    if (pageNum !== undefined) {
      setPageNumber(pageNum, false, undefined, subPageConfig === undefined);
    }
    if (subPageConfig !== undefined) {
      setSubpage({ ...subpage, ...subPageConfig });
    }
    if (activeQI !== undefined) {
      setActiveQuestionIndex(activeQI);
    }
  }

  const RTLLangs = [Language.Arabic, Language.Urdu];

  return (
    <div
      className={`redesign page-${currentPage?.type} ${
        headerMode === "backdrop" ? "has-backdrop" : ""
      } ${debugHighlighting ? "debugHighlighting" : ""}`}
      style={{ background: fullBackground ?? "transparent" }}
      dir={RTLLangs.includes(language) ? "rtl" : "ltr"}
    >
      <NavigationalOverlay
        page={currentPage}
        mode={headerMode}
        activeQuestion={activeQuestion}
        artOverride={
          currentPage?.type === "language-selector"
            ? "/images/AMA_md.png"
            : contextualArt
        }
        activeAnswer={activeAnswer}
        buttonState={headerButtonState}
        hideFade={!!fullPageModal}
        goBackExit={fullPageModal ? closeModal : navigateBack}
        goForward={completeQuestion}
        longPress={longPressHandler}
        useNeutralColor={true || fullBackground}
      />
      {fullPageModal ? (
        <StandardModalDialog
          {...fullPageModal}
          modalPage={subpage.infomationStage}
          questionnaire={questionnaire}
          setPage={setPage_Overload}
          closeModal={closeModal}
        />
      ) : null}

      {/* ============ STANDARD PAGE TYPES ============ */}
      <StandardPage
        questionnaire={questionnaire}
        answers={globalAnswers}
        activeQuestionId={activeQuestionId}
        currentPage={currentPage}
        pageNumber={pageNumber}
        subpage={subpage}
        questionHidden={questionHidden}
        hiddenChoices={hiddenChoices}
        // actions
        completeQuestion={completeQuestion}
        navigateBack={navigateBack}
        acceptAnswer={acceptAnswer}
        setFullPageModal={setFullPageModal}
        restart={() => {
          setPageNumber(0, true, false, true);
          return true;
        }}
      />

      {/* ============ CUSTOM PAGE TYPES ============ */}
      {/* we will work to standardize these, but for now, here be dragons */}

      {currentPage.type === PageType.HybridQuestionSummary ? (
        <HybridQuestionSummaryPage
          {...currentPage}
          questionnaire={questionnaire}
          answers={globalAnswers}
          activeQuestionId={activeQuestionId}
          questionHidden={questionHidden}
          hiddenChoices={hiddenChoices}
          setSubpage={setSubpage}
          advanceOneNoUpdate={advanceOneNoUpdate}
          openModal={setFullPageModal}
          acceptAnswer={acceptAnswer}
          completeQuestion={completeQuestion}
          navigateBack={navigateBack}
        />
      ) : null}

      {currentPage.type === PageType.UniqueQuestion ? (
        <div
          className=""
          style={{
            height: "100vh",
            maxWidth: "100%",
            overflowY: "hidden",
            paddingBottom: "33dvh",
          }}
        >
          {KNOWN_UNIQUES.includes(currentPage.uniqueType) ? null : (
            <h3 className="text-danger">
              Unknown unique type: {safeStringify(currentPage.uniqueType)}
            </h3>
          )}
          {currentPage.uniqueType === "chief complaint followup" ? (
            <div style={{ width: "100%", height: "100vh" }}>
              <SingleQuestion
                page={currentPage}
                index={0}
                isActive={true}
                question={uniques_chiefComplaintFollowup}
                answer={globalAnswers["chief complaint"] ?? EMPTY_ANSWER}
                answerId={"chief complaint"}
                submitAnswerFor={(a, i) =>
                  acceptAnswer(
                    { ...a, questionKey: "chief complaint unrefined" },
                    i
                  )
                }
                complete={completeQuestion}
                // containerRef={questionRefs[i]}
                hiddenChoices={[]}
                trackingInfo={showTrackingInfo}
                keywordAction={(label, content) =>
                  setFullPageModal({ type: "markdown", content })
                }
                displayInfoModal={(choice) =>
                  setFullPageModal({
                    type: "markdown",
                    content: choice.moreInfo,
                    imgUrl: choice.imgUrl,
                    title: choice.label ?? choice.value,
                  })
                }
                contextualResponse={null}
              />
            </div>
          ) : null}
          {currentPage.uniqueType === "region of problem followup" ? (
            <div style={{ width: "100%", height: "100vh" }}>
              <SingleQuestion
                page={currentPage}
                index={0}
                isActive={true}
                question={uniques_regionOfProblemFollowup}
                // textOverride={}
                answer={globalAnswers["region of problem"] ?? EMPTY_ANSWER}
                answerId={"region of problem"}
                submitAnswerFor={acceptAnswer}
                complete={completeQuestion}
                // containerRef={questionRefs[i]}
                hiddenChoices={[]}
                trackingInfo={showTrackingInfo}
                keywordAction={(label, content) =>
                  setFullPageModal({ type: "markdown", content })
                }
                displayInfoModal={(choice) =>
                  setFullPageModal({
                    type: "markdown",
                    content: choice.moreInfo,
                    imgUrl: choice.imgUrl,
                    title: choice.label ?? choice.value,
                  })
                }
                contextualResponse={null}
              />
            </div>
          ) : null}
        </div>
      ) : null}

      {/* ======================= debug info display ====================== */}
      {showTrackingInfo ? (
        <div
          style={{
            position: "absolute",
            left: "2em",
            bottom: "1em",
            color: "cadetblue",
            opacity: 0.75,
          }}
        >
          <button className="btn btn-default btn-xs mt-0 mx-1" onClick={quitFn}>
            Quit
          </button>
          page: {pageNumber} ({`${currentPage?.type}`}) | loop:{" "}
          #{`${subpage.loopIteration}`}(`{subpage.loopChoiceIndex}`)/{`${subpage.loopLength}`} p: {subpage.loopPageIndex}{subpage.loopPageCount}| info subpage:{" "}
          {`${subpage.infomationStage}`}| activeQ: {activeQuestionIndex}/
          {currentPage?.questions?.length} ({`${activeQuestionId}`}) | scroll:
          (-{activeQuestionIndex * 100}vh) | idle in{" "}
          {Math.floor((idleLogoutTimestamp() - Date.now()) / 1000)}s (
          {moment(idleLogoutTimestamp()).format("HH:mm:ss")}), last action was{" "}
          <u>{lastActionType}</u> at{" "}
          {moment(lastActionTimestamp.current).format("HH:mm:ss")}
        </div>
      ) : null}

      {/* ======================= timeout popup ====================== */}
      {inactivityDialogActive ? (
        <SmallModalDialog quit={() => setInactivityDialogActive(false)} background="green">
          <h2>Are you still there?</h2>
          <p>Tap Yes to continue</p>
          <CountdownTimer
            timestamp={Date.now() + 60000}
            completionMessage={"Ending session..."}
          />
          <button
            className="btn btn-light w-100"
            onClick={() => recordActivityForTimeout("manual_de_idle")}
          >
            Yes
          </button>
        </SmallModalDialog>
      ) : null}

      {/* ====================== provider quit dialog ==================== */}
      {showQuitDialog ? (
        <SmallModalDialog quit={() => setShowQuitDialog(false)} background="green">
          <h2>Do you want to quit?</h2>
          <p>
            Both options will restart the kiosk mode, so that it is ready for
            the next patient.
          </p>
          <p>
            Selecting "Save and Quit" will still create a report that can be
            viewed by staff. Selecting "Erase and Quit" will remove this
            patient's data entirely, and should only be used when the
            collection was in error (such as a nonconsenting or nonqualifying
            study participant) or is otherwise completely junk data.
          </p>
          <CTA onClick={() => providerQuit(true)} light={true}>
            Save and Quit
          </CTA>
          <CTA
            onClick={() => providerQuit(false)}
            style={{ backgroundColor: "var(--salmon-red)" }}
          >
            ERASE and Quit
          </CTA>
        </SmallModalDialog>
      ) : null}

      {showDeletionModal ? 
        <SmallModalDialog quit={() => setShowDeletionModal(false)}>
          <h2>This will delete your answers. Are you sure?</h2>
          <CTA onClick={() => setShowDeletionModal(false)}>
            Keep
          </CTA>
          <CTA
            onClick={() => deleteCurrentLoopIteration(true)}
            style={{ backgroundColor: "var(--salmon-red)" }}
          >
            Delete
          </CTA>
        </SmallModalDialog>
      : null}
    </div>
  );
};
