import { useEffect, useMemo, useState } from "react";
import { GeneralAnswer, onlyAcceptMultiChoice } from "../../../models/answers";
import { buildAudioLocationListForQuestion } from "../../../models/audio";
import { loopIndexedAnswerId, QuestionDefinition } from "../../../models/questions";
import { AudioSequence, audioSlice, requestOrRegister } from "../../../store/slices/audio";
import { QuestionnaireDefinition } from "../../../store/slices/definitions";
import { assignOnly, safeStringify, shortOrdinal } from "../../../utils";
import { IDed } from "../../../utils/database";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { EMPTY_ANSWER } from "../constants_to_move";
import SingleQuestion from "../Partials/SingleQuestion";
import type { ModalWindow, SubpageCount } from "../QuestionnaireFlow";

export const QuestionPage = ({
  questions,
  answers,
  questionnaire,
  activeQuestionId,
  questionHidden,
  hiddenChoices,
  loopId,
  subpage,
  openModal,
  acceptAnswer,
  completeQuestion,
  navigateBack,
  ...props
}: {
  questions: string[],
  answers: Record<string, GeneralAnswer>,
  questionnaire: QuestionnaireDefinition,
  activeQuestionId: string,
  questionHidden: Record<string, string | boolean>,
  hiddenChoices: number[],
  loopId?: string,
  subpage: SubpageCount
  openModal: (w: ModalWindow) => void
}) => {
  const dispatch = useAppDispatch();
  const [manuallyShown, setManuallyShown] = useState<string[]>([]);
  const questionHiddenLocal = useMemo(() => {
    let hiddenMap : Record<string, boolean> = {};
    questions.forEach((qID) => {
      if (questionHidden[qID] && !manuallyShown.includes(qID)) {
        hiddenMap[qID] = true;
      }
    });
    return hiddenMap;
  }, [questionHidden, manuallyShown]);

  const autoplayAudio = useAppSelector(s => s.audio.autoplay);
  /** Queue up the AudioSequence for the page */
  useEffect(() => {
    if (!autoplayAudio) return;
    const firstQ = questionnaire.questions[questions[0]] as QuestionDefinition;
    if (firstQ.coreType === "multi choice anatomy") {
      return; // model handles its own sequence
    }
    const locs = buildAudioLocationListForQuestion(firstQ);
    if (locs.length > 0) {
      requestOrRegister(locs, dispatch);
      const sequence: Partial<AudioSequence> & IDed = {
        id: `question-${activeQuestionId}`,
        files: locs.map(l => l.filename),
        current: 0,
        loop: true,
        gap: 500, // 0.5 sec
        loopGap: 10000 // 3 sec
      };
      dispatch(audioSlice.actions.playSequence(sequence));
    }
  }, [questions, autoplayAudio]);

  function acceptAnswerInner (...args: any[]) {
    dispatch(audioSlice.actions.stopAudio());
    acceptAnswer(...args);
  }

  type ContextualResponseMaybe = {value: {value: any}} | null;
  function contextualResponseFor (qID: string): ContextualResponseMaybe {
    const q: QuestionDefinition | undefined = questionnaire.questions[qID];
    if (!q?.contextualResponse) return null;
    if (loopId && q.contextualResponse) {
      // special case, we are looking at our own loop anchor, so be sure to get
      // the value associated with the current iteration
      const loopAnchorAnswer = onlyAcceptMultiChoice(answers[loopId]);
      if (loopAnchorAnswer) {
        const value = loopAnchorAnswer.values.find(v => v.choiceIndex === subpage.loopChoiceIndex);
        return value ? ({value}) : null;
      }
      return null;
    } else {
      const a = answers[q.contextualResponse];
      if (a && !a.isMulti) {
        return a;
      }
      return null;
    }
  }

  return (
    <
    // These styles appear to do nothing important
      // className=""
      // style={{
      //   height: "100vh",
      //   width: "100%",
      //   overflowY: "hidden",
      //   paddingBottom: "33dvh",
      // }}
    >
      {questions?.map((qID, i) => (
        <div key={qID} 
        // style={{ width: "100%", height: "100vh" }}
        >
          {questionHiddenLocal[qID] ? (
            <
              // className="centered-flex-container"
              // style={{ height: "100vh", justifyContent: "center" }}
            >
              <h3 className="text-center text-warning">
                "{qID}" hidden because {questionHiddenLocal[qID]}
                <button
                  className="btn btn-sm btn-primary"
                  onClick={(e) => setManuallyShown([...manuallyShown, qID])}
                >
                  Force Display
                </button>
              </h3>
              <pre
                className="p-2 mb-0"
                style={{
                  whiteSpace: "pre-wrap",
                  background: "#E0E0FF",
                  borderRadius: "8px",
                }}
              >
                {safeStringify(
                  assignOnly(questionnaire.questions[qID], { id: qID }, [
                    "displayWhen",
                    "skipWhen",
                  ])
                )}
              </pre>
            </>
          ) : (
            <SingleQuestion
              key={loopIndexedAnswerId(qID, subpage.loopChoiceIndex)}
              page={props}
              index={i}
              isActive={activeQuestionId === qID}
              question={questionnaire.questions[qID]}
              textOverride={
                loopId
                  ? {
                      text: questionnaire.questions[qID].text.replace(
                        "<multiplex_ordinal>",
                        shortOrdinal(subpage.loopIteration + 1)
                      ),
                    }
                  : null
              }
              answer={
                answers[loopIndexedAnswerId(qID, subpage.loopChoiceIndex)] ?? EMPTY_ANSWER
              }
              answerId={loopIndexedAnswerId(qID, subpage.loopChoiceIndex)}
              submitAnswerFor={acceptAnswerInner}
              complete={completeQuestion}
              goBack={navigateBack}
              // containerRef={questionRefs[i]}
              hiddenChoices={hiddenChoices[qID]}
              showTrackingInfo={false}
              keywordAction={(label, content) =>
                openModal({ type: "markdown", content })
              }
              displayInfoModal={(choice) =>
                openModal({
                  type: "markdown",
                  content: choice.moreInfo,
                  imgUrl: choice.imgUrl,
                  title: choice.label ?? choice.value,
                })
              }
              contextualResponse={contextualResponseFor(qID)}
            />
          )}
        </div>
      ))}
    </>
  );
};
