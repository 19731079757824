import { KNOWN_MEASUREMENT_TYPES } from "../constants/measurements";
import { AnatomicalModel } from "./anatomical-models";
import { NonconformingValueKind } from "./answers";
import { ArtResource } from "./assets";
import { CoreDataTypeLabel } from "./core-data-types";
import { ChoiceLikeLayout, ResponseLayout } from "./layouts";

/**
 * A response choice is a single selectable option amongst a multiple choice set
 * presented to the user.
 */
export interface ResponseChoice {
  /**
   * How the choice is labeled to the user.
   */
  label?: string;
  labelTranslationId?: string;
  description?: string;
  descriptionTranslationId?: string;
  art?: ArtResource;
  /**
   * The underlying value used to represent this choice. This should always be
   * in English regardless of the current language.
   */
  value: string;
  isExclusionary?: boolean;
  isNonconforming?: boolean;

  displayWhen?: any[],
  skipWhen?: any[]
}

export interface NonconformingResponseChoice extends ResponseChoice {
  kind: NonconformingValueKind;
  isChoice: boolean;
}

export interface AbstractQuestionDefinition {
  id?: string;
  coreType: CoreDataTypeLabel;
  layout: ResponseLayout;
  isMulti: boolean;
  art?: string;
  contextualArt?: string;
  contextualResponse?: string;
  text?: string;
  translationId?: string;
  subtext?: string;
  subtextTranslationId?: string;
  instructionalTranslationId?: string;
  nonconformingResponses?: ResponseChoice[]
}

export interface NumericQuestionDefinition extends AbstractQuestionDefinition {
  min?: number;
  max?: number;
  layout: ResponseLayout.Numeric;
}

export interface ChoiceQuestionDefinition extends AbstractQuestionDefinition {
  layout: ChoiceLikeLayout;
  choices: ResponseChoice[];
}
export function isChoiceLike(q: AbstractQuestionDefinition | undefined | null): q is ChoiceQuestionDefinition {
  return !!q && ([ResponseLayout.GridCards, ResponseLayout.StackCards, ResponseLayout.Dropdown].includes(q.layout));
}

export interface CalendarQuestionDefinition extends AbstractQuestionDefinition {
  layout: ResponseLayout.Calendar;
  allowPast?: boolean;
  allowFuture?: boolean;
}

export interface MeasurementQuestionDefinition extends AbstractQuestionDefinition {
  layout: ResponseLayout.Measurement;
  measurementType: typeof KNOWN_MEASUREMENT_TYPES;
}

export interface TextQuestionDefinition extends AbstractQuestionDefinition {
  layout: ResponseLayout.ShortAnswer;
}

export interface AnatomicalRegionQuestionDefinition extends AbstractQuestionDefinition {
  layout: ResponseLayout.AnatomicalRegion;
  initialView?: string;
  model?: AnatomicalModel | string;
  leafOnly?: boolean;
}

export type QuestionDefinition =
  ChoiceQuestionDefinition
  | NumericQuestionDefinition
  | CalendarQuestionDefinition
  | MeasurementQuestionDefinition
  | TextQuestionDefinition
  | AnatomicalRegionQuestionDefinition;

export function loopIndexedAnswerId (baseId: string, index: number, element?: string) {
  if (typeof element === 'string') {
    return `${baseId}∋${element}`;
  }
  if (index < 0) {
    return baseId;
  } else {
    return `${baseId}§${index}`;
  }
}
