// This file will hold the types / constants to enforce the names of languages
// and other locale data.

import { LanguageRounded } from "@material-ui/icons";
import { rlu } from "../utils";

// not there yet, but generally trying to comply with IETF's specs
// cf. RFC-5646 https://datatracker.ietf.org/doc/html/rfc5646

export type LanguageISO = 'ar' | 'bur' | 'en' | 'es' | 'sw' | 'ur' | 'rw' | 'so' | 'und';
export type LanguageName = 'UNDETERMINED' | 'Arabic' | 'Burmese' | 'English' | 'Spanish' | 'Swahili' | 'Urdu' | 'Kinyarwanda' | 'Somali';

export const Language : Record<LanguageName, LanguageISO> = {
  UNDETERMINED: "und",
  Arabic: "ar", // inclusive, has 20+ subcodes
  Burmese: "bur", // should be "my"/"mya" for T spec
  English: "en",
  Spanish: "es", // weirdly 639-2 and -3 specify "spa"
  Swahili: "sw", // inclusive, has 4 subcodes
  Urdu: "ur",

  Kinyarwanda: "rw",
  Somali: "so",
  // Test: "test"
} as const;

export const LANGUAGE_WHITELIST : LanguageISO[] = [
  Language.Arabic,
  Language.Burmese,
  Language.English,
  Language.Spanish,
  Language.Swahili,
  Language.Urdu,
  Language.Kinyarwanda,
  Language.Somali,
  Language.UNDETERMINED
];

export const EnglishNames = rlu(Language);

const langPrioritySort : LanguageISO[] = [
  Language.English,
  Language.Spanish
];
export const LanguageList : LanguageISO[] = Object.values(Language).sort((a, b) => {
  // we sort the languages so that the entries in the langPrioritySort array
  // appear first (in the same relative order) followed by anything NOT in that
  // array in alphabetical order by lang code
  if (langPrioritySort.includes(a)) {
    if (langPrioritySort.includes(b)) {
      return langPrioritySort.indexOf(a) - langPrioritySort.indexOf(b);
    } else {
      return -1;
    }
  } else if (langPrioritySort.includes(b)) {
    return 1;
  }
  return a.localeCompare(b);
});

export function isValidLanguage (x : string) : x is LanguageISO {
  return LanguageList.includes(x as LanguageISO);
}

// an endonym is the internal label for something, i.e. self-designated
export const LanguageEndonym : Record<keyof typeof Language, string> = {
  [Language.Arabic]: "عربي",
  [Language.Burmese]: "ဗမာ",
  [Language.English]: "English",
  [Language.Spanish]: "Español",
  [Language.Swahili]: "Kiswahili",
  [Language.Test]: "Test",
  [Language.Urdu]: "اُردُو",
  [Language.Kinyarwanda]: "Kinyarwanda",
  [Language.Somali]: "Af Soomaali", // NOTE: kind of challenging to narrow, several scripts in use here?
  [Language.UNDETERMINED]: "Use language selector"
};

/**
 * Get a combined string of the endonym AND the English name for a particular 
 * language, e.g. "Español (Spanish)"
 * @param {string} lang 
 * @returns {string}
 */
export function dualName (lang: LanguageISO) {
  return ((lang === Language.English)
    ? `English`
    : `${LanguageEndonym[lang]} (${EnglishNames[lang]})`);
}

export const Region = {
  Undefined: undefined, // should not ever be used as string

  // ISO-3166-1 Alpha-2 country codes
  // cf. https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
  UnitedStates: "US",
  GreatBritain: "GB",
  Spain: "ES",
  Myanmar: "MM",
  Pakistan: "PK",

  // UN M.49 Region codes
  // cf. https://unstats.un.org/unsd/methodology/m49/
  World: "001",
  Africa: "002",
  Americas: "019",
  LatinAmerica: "419", // and carribean, as opposed to south amer. = 005
} as const;
export type RegionType = typeof Region[keyof typeof Region];

type SelectorAssetConfig = {
  flags: string,
  prompt?: string,
  narrationFile?: string
}

// {[l in keyof typeof Language]: SelectorAssetConfig}
export const SelectorAssets : Record<LanguageISO, SelectorAssetConfig> = {
  [Language.Arabic]: {
    flags: "🇪🇬🇩🇿🇸🇩🇮🇶🇲🇦🇸🇦",
    prompt: "المس هنا للغة العربية",
    narrationFile: "touch_here_AR.mp3"
  },
  [Language.Burmese]: {
    flags: "🇲🇲",
    prompt: "မြန်မာစာကို ဒီမှာနှိပ်ပါ။",
    narrationFile: "touch_here_MY.mp3"
  },
  [Language.English]: {
    flags: "🇺🇸🇬🇧",
    prompt: "Touch here for English",
    narrationFile: "test_batch/P_GEN015.mp3"
  },
  [Language.Spanish]: {
    flags: "🇲🇽🇨🇴🇪🇸🇦🇷🇵🇪🇻🇪",
    prompt: "Toque aquí para Español",
    narrationFile: "touch_here_ES.mp3"
  },
  [Language.Swahili]: {
    flags: "🇰🇪🇹🇿🇺🇬",
    prompt: "Gusa hapa upate kiswahili",
    narrationFile: "touch_here_SW.mp3",
  },
  [Language.Test]: {
    flags: "🏁",
    prompt: "«this is a special test language»"
  },
  [Language.Urdu]: {
    flags: "🇵🇰",
    prompt: "اردو کے لیے یہاں ٹچ کریں۔",
    narrationFile: "touch_here_UR.mp3"
  },
  [Language.Kinyarwanda]: {
    flags: "🇷🇼"
  },
  [Language.Somali]: {
    flags: "🇸🇴"
  },
} as const;