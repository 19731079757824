import React, { useMemo, useState } from "react";
import StackResponse from "./StackResponse";
import { ordinals } from "../../../utils/index";

export default function NumericResponse({
  answer,
  questionDefinition,
  updateAnswer,
}) {
  const asList = useMemo(() => {
    if (questionDefinition.asList) {
      const { min, max } = questionDefinition;
      if (!Number.isSafeInteger(min) || !Number.isSafeInteger(max)) {
        console.error(
          "Cannot use list mode without integer min and max properties"
        );
        return null;
      }
      const units = Array.isArray(questionDefinition.units)
        ? questionDefinition.units.slice()
        : ["", "", ""];
      if (units.length < 2) {
        units[1] = units[0] + "s";
      }
      if (units.length < 3) {
        units[2] = "or more " + units[1];
      }
      const step = questionDefinition.step ?? 1;
      const count = (max - min) / step + 1;
      const choices = ordinals(count).map((n) => {
        const value = n * step + min;
        const orMoreCase = n === count - 1 && questionDefinition.orMore;
        return {
          value,
          label: `${value} ${
            value === 1 ? units[0] : orMoreCase ? units[2] : units[1]
          }`,
          artComponentOverride: (
            <div
              style={{
                width: "66px",
                lineHeight: "66px",
                fontSize: "2rem",
                textAlign: "center",
              }}
            >
              {value}
              {orMoreCase ? "+" : ""}
            </div>
          ),
        };
      });
      return {
        ...questionDefinition,
        type: "stack",
        choices,
      };
    } else {
      return null;
    }
  }, [questionDefinition]);

  function acceptListAnswer(newAnswer) {
    updateAnswer(newAnswer);
  }

  function handleUserInput(event) {
    console.log({ value: event.target.value });
    updateAnswer({
      // id: questionDefinition.id,
      isMulti: false,
      value: {
        ...answer,
        value: event.target.value,
      },
    });
  }

  return (
    <div className="main-column" style={{ margin: "0 auto" }}>
      {asList !== null ? (
        <StackResponse
          questionDefinition={asList}
          answer={answer}
          updateAnswer={acceptListAnswer}
        />
      ) : (
        // classic input
        <input
          type="number"
          className="main-column"
          value={answer.value?.value}
          min={questionDefinition.min ?? Number.MIN_SAFE_INTEGER}
          max={questionDefinition.max ?? Number.MAX_SAFE_INTEGER}
          step={questionDefinition.step ?? 1}
          onChange={handleUserInput}
        />
      )}
    </div>
  );
}
