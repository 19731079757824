import React, { ChangeEvent, useEffect } from "react";
import { Switch } from "@material-ui/core";
import { LanguageList, Language, dualName } from "../constants/locales";
import { useSelector } from "react-redux";
import type { RootState } from "../store/store";
import { BooleanSimulatorState, changeSimulatorSettings, readSettingsFromSearchParams, SimulatorState } from "../store/slices/simulator";
import { useAppDispatch, useAppSelector } from "../utils/hooks";

export const SimulatorSettingsPanel = ({showLoadOptions = true}: {showLoadOptions: boolean}) => {
  const settings = useAppSelector(s => s.simulator);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(readSettingsFromSearchParams());
  }, []);

  function changeSetting(key: keyof SimulatorState, value: any) {
    dispatch(changeSimulatorSettings({[key]: value}));
  }
  // Build a change handler for a <Switch/> to dispatch changeSetting actions
  function settingSwitchHandler (key: keyof SimulatorState) {
    return (e: ChangeEvent, v: boolean) => changeSetting(key, v);
  }
  function BooleanSettingSwitch ({setting, ...props}: {setting: keyof BooleanSimulatorState, disabled?: boolean}) {
    return <Switch
      checked={settings[setting]}
      onChange={settingSwitchHandler(setting)}
      {...props}
    />
  }

  return (
    <div>
      {/* <h1 className="page-title">Settings</h1>
      <p>
        Complete the configuration, load the questionnaire, then proceed to
        launch.
      </p>
      <hr /> */}

      <div className="mb-5" style={{ opacity: 0.7 }}>
        <div className="row">
          <div className="col col-6">
            <h3 className="section-heading">Content Display</h3>
            <p>Define the content and flow of the questionnaire.</p>
          </div>
          <div className="col col-6">
            <label>
              <BooleanSettingSwitch setting="navLogicEnabled"/>
              Use Conditional Navigation
            </label>

            <br />
            <label>
              <Switch
                checked={settings.overrides?._bodyType === ""}
                onChange={(e, v) =>
                  changeSetting("overrides", { ...settings.overrides, _bodyType: v ? "female" : "male" })
                }
              />
              Pre-Select Female Body Type
            </label>
            {/* <p>Display questions, answer choices and images appropriate to female users.</p> */}
          </div>
        </div>

        <hr className="divider" />

        <div className="row">
          <div className="col col-6">
            <h3 className="section-heading">Navigation</h3>
            <p>Determine how to move between questions.</p>
          </div>
          <div className="col col-6">
            <label>
              <BooleanSettingSwitch setting="noDisableProgress"/>
              Allow Skipping Without Answer
            </label>
            {/* <p>Permit skipping questions without providing an answer.</p> */}
            <br />
            <label>
              <BooleanSettingSwitch setting="autoProgressScalars"/>
              Auto-Advance on Single Choice Questions
            </label>
            <p>
              {/* Automatically move to the next question after selecting a single-choice
          answer. */}
            </p>
          </div>
        </div>

        <hr className="divider" />

        {showLoadOptions ? 
        <>
        <div className="row">
          <div className="col col-6">
            {" "}
            <h3 className="section-heading">Load Parameters</h3>
            <p>Set the language and workflow parameters.</p>
          </div>
          <div className="col col-6">
            <label style={{marginRight: "2em"}}>Select Language:</label>
            {/* <p>Set the questionnaire language</p> */}
            <select
              className=""
              onChange={(ev) => changeSetting("language", ev.target.value)}
              style={{border: "1px rgba(0,0,0,0.2) solid", background: "rgba(256, 256, 256, 0.2)", borderRadius: "6px", padding: "4px", display: "inline-block", width: "initial"}}
            >
              {LanguageList.map((l) => (
                <option value={l} key={l}>
                  {dualName(l)}
                </option>
              ))}
            </select>
            <br />
            <label>
              <BooleanSettingSwitch setting="dualLanguage" disabled={settings.language === Language.English} />
              Display with English
            </label>
            {/* <p>Display the translations alongside English</p> */}
            <br />
            <label>
              <BooleanSettingSwitch setting="simulateKiosk"/>
              Simulated Kiosk Mode
            </label>
            {/* <p>Enable the kiosk mode for walk-in environments</p> */}
          </div>
        </div>
        
        <hr className="divider" />
        </>
         : null}

        

        <div className="row">
          <div className="col col-6">
            <h3 className="section-heading">Visual Debugging</h3>
            <p>Check for accuracy with visual support.</p>
          </div>
          <div className="col col-6">
            <label>
              <BooleanSettingSwitch setting="debugHighlighting"/>
              Show Container Outlines
            </label>
            {/* <p>Display outlines of all content containers for visual debugging.</p> */}
            <br />
            <label>
              <BooleanSettingSwitch setting="trackingInfo"/>
              Show Tracking Info
            </label>
            {/* <p>Display tracking information for debugging purposes.</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}
