import rehypeRaw from "rehype-raw";
import { ImageWithFallback } from "../../UI/images/ImageWithFallback";
import {
  ALLOW_BACKDROP_TO_CLOSE_MODAL,
  MODAL_Q_CONFIGS,
} from "../constants_to_move";
import ReactMarkdown from "react-markdown";
import { useDispatch } from "react-redux";

export const StandardModalDialog = ({
  type,
  imgUrl,
  title,
  content,
  modalPage,
  questionnaire,
  activeQuestionId,
  closeModal,
  setPage,
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        background: "rgba(0, 0, 0, 0.1)",
        backdropFilter: "blur(5px)",
        zIndex: 100,
      }}
      onClick={(e) => (ALLOW_BACKDROP_TO_CLOSE_MODAL ? closeModal() : null)}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          maxWidth: "600px",
          margin: "auto",
          background: "white",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            width: "100%",
            height: "100px",
            borderBottom: "1px solid rgba(0,0,0,0.8)",
          }}
        >
          {type === "jump dialog" ? (
            <h2 style={{ padding: "20px", textAlign: "center" }}>
              Jump to Page
            </h2>
          ) : null}
          {title ? (
            <h2 style={{ padding: "20px", textAlign: "center" }}>{title}</h2>
          ) : null}
        </div>
        <div
          style={{
            margin: "10px",
            maxHeight: "calc(100% - 120px)",
            overflowY: "scroll",
          }}
        >
          {imgUrl ? (
            <ImageWithFallback src={imgUrl} style={{ width: "100%" }} />
          ) : null}
          {type === "markdown" ? (
            <ReactMarkdown
              children={content ? `${content}` : "No content!"}
              rehypePlugins={[rehypeRaw]}
              components={{}}
              style={{ fontSize: "24px" }}
              className="education resource-dialog popup-education-wrapper bigger"
            />
          ) : null}
          {type === "introduction" ? (
            modalPage > -1 ? (
              <>
                <h3>{title}</h3>
                <p>{content}</p>
              </>
            ) : (
              <h3 className="text-warning">Introduction failed to load</h3>
            )
          ) : null}
          {type === "jump dialog" ? (
            <ol>
              {questionnaire.pages.map((p, pi) => (
                <li
                  key={p.id + `${pi}`}
                  className={p.type.startsWith("question") ? "" : "text-info"}
                  style={{ borderRight: p.loopId ? "1px solid gray" : "none" }}
                  onClick={() => {
                    setPage(pi);
                    closeModal();
                  }}
                >
                  {p.type} page
                  {p.loopId ? (
                    <em className="float-right badge badge-light">
                      {p.loopId}
                    </em>
                  ) : null}
                  {p.modalType ? (
                    <em className="float-right badge badge-light">
                      {p.modalType}
                    </em>
                  ) : null}
                  {p.type === "title" ? (
                    <span className="badge badge-info float-right">
                      {p.title}
                    </span>
                  ) : null}
                  {p.type === "question" ? (
                    <ul>
                      {p.questions?.map?.((q, qi) => (
                        <li
                          key={qi}
                          style={
                            q === activeQuestionId
                              ? {
                                  background: "rgba(100, 250, 150, 0.15)",
                                  borderRadius: "12px",
                                  padding: "4px",
                                }
                              : {}
                          }
                          onClick={(e) => {
                            setPage(pi, undefined, qi);
                            closeModal();
                            e.stopPropagation();
                          }}
                        >
                          <span className="badge badge-dark">
                            {q in questionnaire.questions
                              ? questionnaire.questions[q].type
                              : "NOT FOUND"}
                          </span>{" "}
                          {q}
                        </li>
                      ))}
                      {!Array.isArray(p.questions) ? (
                        <li className="text-danger">MALFORMED QUESTION LIST</li>
                      ) : null}
                    </ul>
                  ) : null}
                </li>
              ))}
            </ol>
          ) : null}
        </div>
      </div>
    </div>
  );
};


/* DEPRECATED: modal questions
  {p.type === "question-modal" ? (
    <ol>
      {MODAL_Q_CONFIGS[p.modalType]?.questionsByPage?.map(
        (qIndexList, si) => (
          <li key={si}>
            <em>subpage:</em>
            <ul>
              {qIndexList
                .map((spi) => p.questions[spi])
                .map((q, qi) => (
                  <li
                    key={qi}
                    style={
                      q === activeQuestionId
                        ? {
                            background:
                              "rgba(100, 250, 150, 0.15)",
                            borderRadius: "12px",
                            padding: "4px",
                          }
                        : {}
                    }
                    onClick={(e) => {
                      setPage(pi, si, qi);
                      closeModal();
                      e.stopPropagation();
                    }}
                  >
                    {q}
                  </li>
                ))}
            </ul>
          </li>
        )
      )}
    </ol>
  ) : null}
*/