import React, { useMemo, useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  Calendar,
  Day,
} from "@material-ui/pickers";
import { IconButton, createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import moment from "moment";
import { ModeComment } from "@material-ui/icons";

// Todo: remove the CalendarHeader.  **OR**
// Todo: have Carlos suggest design with this calendar given year selection unless can be further modified per specs
// Todo: daySelected focus backgroundColor: #E3F4E8, color: var(--evergreen) (and no outline)
// Todo: dark text color is #1B1B1B

const materialTheme = createTheme({
  overrides: {
    MuiPickersBasePicker: {
      pickerView: {
        maxWidth: 500,
        width: "100%",
      },
      container: {
        alignItems: "center",
      },
      containerLandscape: {
        flexDirection: "column",
      },
    },

    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "var(--evergreen)",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: "#fff",
        color: "#1B1B1B",
      },
      daysHeader: {
        justifyContent: "space-around",
      },
    },
    MuiPickersCalendar: {
      week: {
        justifyContent: "space-around",
        // marginTop: "0.5em"
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: "var(--evergreen)",
        "&:hover": {
          backgroundColor: "#0fd181",
        },
        // backgroundColor:  "#E3F4E8",
      },
    },
  },
});

const ARROW_KEYS = ["ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDown"];
function preventCalendarNavPropagation(e) {
  if (ARROW_KEYS.includes(e.key)) {
    e.stopPropagation();
  }
}

/**
 * A response component for handling *calendar* date inputs. We emphasize the
 * calendar aspect as these dates are not given times and their location data
 * is assumed to be contextual -- we never do date manipulations upon them
 * because this is more likely to create confusion (for example a patient's
 * date of birth being displayed as a different day due to timezones; it is
 * rarely relevant what the date was in timezones/offsets other than the one
 * that is contextually implied). Because of this, we also store them on the
 * server as YYYY-MM-DD strings.
 *
 * If a time is needed, we will use the (currently unimplemented) timestamp
 * response type, or perhaps an (unspec'ed) time+location combo type.
 *
 * @param {*} param0
 * @returns
 */
export default function CalendarDateResponse({
  questionDefinition,
  answer,
  updateAnswer,
}) {
  function onDateChange(newDate) {
    updateAnswer({
      id: questionDefinition.id,
      isMulti: false,
      value: {
        ...answer,
        value: newDate,
      },
    });
  }

  function CalendarDay(day, selectedDate, dayInCurrentMonth, dayComponent) {
    const dayInDisabledRange =
      (questionDefinition?.disableFuture && moment(day).isAfter()) ||
      (questionDefinition?.disablePast && moment(day).isBefore());
    return (
      <Day
        style={{
          opacity: dayInCurrentMonth ? (dayInDisabledRange ? 0.5 : 1) : 0.25,
        }}
        selected={
          !!answer?.value?.value &&
          moment(day).isSame(answer?.value?.value, "day")
        }
        // onClick={() => onDateChange(day)} // handled by Calendar.onChange
      >
        {day.getDate()}
      </Day>
    );
  }

  const dateAnswer = useMemo(() => {
    if (answer.isExclusionary) {
      return new Date();
    } else if (!(answer?.value?.value instanceof Date)) {
      if (Array.isArray(questionDefinition.initialDateDelta)) {
        return moment()
          .add(...questionDefinition.initialDateDelta)
          .toDate();
      } else {
        return new Date();
      }
    } else {
      return answer.value.value;
    }
  }, [answer]);

  const [currentView, setCurrentView] = useState("date");
  function handleHeaderClick() {
    setCurrentView("year");
  }
  function checkForHeaderClick(e, ...x) {
    let element = e.target;
    while (
      !element.classList.contains("dateQuestionWrapper") &&
      "parentElement" in element
    ) {
      if (
        element.classList.contains(
          "MuiPickersCalendarHeader-transitionContainer"
        )
      ) {
        return handleHeaderClick(e);
      }
      element = element.parentElement;
    }
  }

  return (
    <div
      className="dateQuestionWrapper main-column"
      style={{ maxWidth: 400, margin: "0 auto" }}
      onKeyUp={preventCalendarNavPropagation}
      onClick={checkForHeaderClick}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={materialTheme}>
          <KeyboardDatePicker
            autoOk
            variant="static"
            value={dateAnswer}
            views={["year", "month", "date"]}
            openTo={currentView}
            key={currentView}
            // above is hackish idea suggested by github commenters; basically
            // just force a rerender when you want to change view and use the
            // openTo prop to ensure it starts at the new view
            // (apparently this is all solved in v4)
            // The alternative is to use ToolbarComponent to grab a copy of the
            // internal function that changes the view state and expose it on
            // a ref, but that risks a flow loop a little bit.
            renderDay={CalendarDay}
            onChange={onDateChange}
            disableFuture={questionDefinition?.disableFuture}
            disablePast={questionDefinition?.disablePast}
            disableToolbar={true}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
}

// could potentially switch back to this now that we have renderday sorted out!
// return (
//   <div>
//   <MuiPickersUtilsProvider utils={DateFnsUtils}>
//     <ThemeProvider theme={materialTheme}>
//       <KeyboardDatePicker
//         variant="static"
//         // autoOk
//         defaultValue={null}
//         value={answer.value?.value}
//         onChange={onDateChange}
//         renderDay={CalendarDay}
//         initialFocusedDate={undefined}
//         // disableFuture={!!disableFuture}
//         // disablePast={!!disablePast}
//         // inputValue={value === null? null : inputValue}
//         // views={["month", "year"]}
//         // format={monthOnly ? "MM-yyyy" : "MM-dd-yyyy"}
//         // placeholder={monthOnly ? "mm-yyyy" : "mm-dd-yyyy"}
//         // openTo={openTo ? openTo : (monthOnly ? "month" : "date")}
//         // initialFocusedDate={suggestedDate}
//         // style={fullwidth ? {width: "100%"} : null}
//         // orientation={landscape ? "landscape" : "portrait"}
//       />
//     </ThemeProvider>
//   </MuiPickersUtilsProvider>
//   </div>
// );

/* // FROM LITERASEED CODEBASE 
export const CustomCalendar = ({
    changed,
    value,
    openTo,
    suggestedDate,
    inline = false,
    monthOnly = false,
    disableFuture = false,
    disablePast = false,
    sendEventOnInvalid = true,
    fullwidth = false,
    inPage = false,
    landscape = false
}) => {

  if (value instanceof Date) {
    throw new Error("As of 05/2022, CustomCalendar expects string dates");
  }
  const initialValue = value ? (monthOnly ? moment(value, "MM-YYYY") : moment(value, "MM-DD-YYYY")) : null;
  const [selectedDate, setDate] = useState(initialValue);
  const [inputValue, setInputValue] = useState();

  function formatForOutput (date, value) {
    if (monthOnly) {
      return moment(date).format("MM-yyyy");
    } else {
      return moment(date).format("MM-DD-yyyy");
    }
  }

  const onDateChange = (date, value) => {
    console.log({date, value});
    setDate(date);
    setInputValue(value);
    if (isNaN(date)) {
      if (sendEventOnInvalid) {
        // TODO: send something other than formatted! but first we need to see what might rely on this...
        changed(formatForOutput(date));
      }
    } else {
      changed(formatForOutput(date));
    }
  };

  return (
    <div style={{display: inline ? "inline-block" : "block"}}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={materialTheme}>
          <KeyboardDatePicker
            variant={inPage ? "static" : "inline"}
            autoOk
            value={(!value || value === "Invalid Date") ? null : selectedDate}
            onChange={onDateChange}
            disableFuture={!!disableFuture}
            disablePast={!!disablePast}
            inputValue={value === null? null : inputValue}
            views={monthOnly ? ["month", "year"] : ["date"]}
            format={monthOnly ? "MM-yyyy" : "MM-dd-yyyy"} 
            placeholder={monthOnly ? "mm-yyyy" : "mm-dd-yyyy"}
            openTo={openTo ? openTo : (monthOnly ? "month" : "date")}
            initialFocusedDate={suggestedDate}
            style={fullwidth ? {width: "100%"} : null}
            orientation={landscape ? "landscape" : "portrait"}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default CustomCalendar;
*/
