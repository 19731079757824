import { ComponentProps, createRef, useState } from "react";
import { isSelected, toggleChoice } from "../../../models/choices";
import { ResponseChoice } from "../../../models/questions";
import { JSObjectDump } from "../../../utils/UtilComponents";
import { Card } from "../../UI/Card/Card";

export function ListChoiceCard({ item, handleClick, isSelected, style = {}, description }: {item: ResponseChoice, handleClick: (i: ResponseChoice, current?: boolean) => void, isSelected: boolean, description?: string} & ComponentProps<"div">) {
  const subtextRef = createRef();
  const [expanded, setExpanded] = useState(false);

  function expandIfNeeded(event) {
    console.log({ subtext: subtextRef.current });
    if (subtextRef.current?.scrollHeight > subtextRef.current?.clientHeight) {
      setExpanded(true);
      event.stopPropagation();
    } else if (expanded) {
      setExpanded(false);
      event.stopPropagation();
    }
  }

  return (
    <Card
      isSelected={isSelected}
      onClick={() => handleClick(item, isSelected)}
      stack={true}
      art={item.art}
      title={item.label}
      description={description ?? item.description}
      {...item}
    />
  );

    /*(
    <div
      className={`rect-img-card ${isSelected && "selected"}`}
      key={item.label}
      onClick={() => handleClick(item, isSelected)}
      style={style}
    >
      <div className={`image-wrapper ${item.imgUrl ? "" : "no-image"}`}>
        {item.artComponentOverride ? (
          item.artComponentOverride
        ) : item.imgUrl ? (
          <ImageWithFallback
            src={`${item.imgUrl}`}
            alt={item.imgAlt}
            style={{ height: "100%" }}
          />
        ) : null}
      </div>
      <div className="text-wrapper">
        <p className={item.description ? "with-subtext" : "no-subtext"}>
          {item.label}
        </p>
        {item.description ? (
          <p
            className={`choice-subtext ${expanded ? "expanded" : ""}`}
            ref={subtextRef}
            onClick={expandIfNeeded}
          >
            {item.description}
          </p>
        ) : null}
      </div>
    </div>
  ); */
}

export default function StackResponse({
  questionDefinition,
  answer,
  updateAnswer,
  hiddenChoices = [],
  showTrackingInfo = false,
  subtextOverrides
}: {
  subtextOverrides?: string[]
}) {
  function handleClick(item: ResponseChoice, isCurrentlySelected: boolean) {
    const updatedAnswerObj = toggleChoice(
      item,
      isCurrentlySelected,
      questionDefinition.choices,
      questionDefinition.isMulti,
      answer,
      // { id: questionDefinition.id }
    );
    updateAnswer(updatedAnswerObj);
  }

  const responses = questionDefinition.choices.map((choice, index) => (
    <>
      {showTrackingInfo ||
      !hiddenChoices.includes(index) ||
      isSelected(index, questionDefinition, answer) ? (
        <ListChoiceCard
          key={choice.label}
          item={choice}
          description={subtextOverrides?.[index]}
          handleClick={handleClick}
          isSelected={isSelected(index, questionDefinition, answer)}
          style={hiddenChoices.includes(index) ? { opacity: 0.5 } : {}}
        />
      ) : null}
      {showTrackingInfo ? (
        <>
          {choice.displayWhen ? (
            <label>
              #{index} displayWhen: <JSObjectDump obj={choice.displayWhen} />
            </label>
          ) : null}
          {choice.skipWhen ? (
            <label>
              #{index} skipWhen: <JSObjectDump obj={choice.skipWhen} />
            </label>
          ) : null}
        </>
      ) : null}
    </>
  ));

  return (
    <div className="centered-flex-container main-column">
      <div className="stack-of-cards">{responses}</div>
    </div>
  );
}
