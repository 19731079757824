import { AnatomicalModel, ClickResolutionMethod, SVGRegionSelectorType } from "../models/anatomical-models";

/**
 * Whether the app should draw connecting lines to ALL regions, regardless of
 * whether they are selected. These lines will be rendered partially trasparent.
 */
export const USE_SEMITRANSPARENT_LINES = true;
/**
 * Whether the app should fade out non-selected choices in the legend (once at
 * least one choice other than the current view's overall is chosen). Otherwise
 * they will all be rendered completely opaque.
 */
export const USE_SEMITRANSPARENT_CHOICES = true;
/**
 * Whether the view's image should attempt to embed an SVG image as a native
 * elemen t of the page (as opposed to the src of an <img> tag or other use that
 * treats it as a separate document). This requires some trickery with webpack,
 * see DynamicSVG for more details, but allows us to do runtime JS interactions
 * with the SVG itself slash apply CSS to its children.
 * 
 * NOTE: At this point, turning this off will break a number of features!!!
 */
export const USE_DYNAMIC_SVG_EMBED = true;
/**
 * Whether the app should attempt to display region highlighting by modifying
 * the CSS of nodes within the SVG. This does nothing when USE_DYNAMIC_SVG_EMBED
 * is off, and has further configuration at SVG_SELECTION_TYPE.
 */
export const HIGHLIGHT_BY_SVG_NODES = true;
/**
 * Whether a model/view's coordinate pairs should be treated as having their
 * origin at the top left of the SVG element, or if they should be relative to
 * the box that handled the click event.
 * 
 * This setting is mostly irrelevant since now all click events are handled by
 * the overall image, so the settings are 99% equivalent.
 */
export const POINTS_ZEROED_TO_SVG_ROOT = true;

// unsure about this usage, I believe it has been phased out
export const DEFAULT_REGION_DEF = [];

export const SVG_SELECTION_TYPE : SVGRegionSelectorType =
  SVGRegionSelectorType.GroupIdMatchesRegionHighlightId;

/**
 * The previous set up before our interaction model was simplified. Typed to
 * any because its views haven't been updated to be compliant with API changes.
 */
export const DEFAULT_HUMAN_MODEL_V1 : any = {
/*  "front vs back": {
    canSelectEntire: false,
    type: "bottom cards",
    imgUrl: "view-assets/female_whole_body_covered.svg",
    choices: [
      {
        label: "Front",
        value: "Front of Body",
        viewId: "body front",
        highlightRegion: "front",
      },
      {
        label: "Back",
        value: "Back of Body",
        viewId: "body back",
        highlightRegion: "back",
      },
    ],
  },
  "body front": {
    imgUrl: "view-assets/female_front_covered.svg",
    // imgComponent: await import("./AreaSelector/view-assets/female_front_covered.svg"),
    choices: [
      {
        label: "Head",
        value: "Head",
        viewId: "head",
        lineAnchor: [206, 48],
      },
      {
        label: "Neck",
        value: "Neck",
        lineAnchor: [205, 107],
      },
      {
        label: "Chest",
        value: "Chest",
        viewId: "chest",
        lineAnchor: [200, 169],
      },
      {
        label: "Abdomen",
        value: "Abdomen",
        viewId: "abdomen",
        lineAnchor: [200, 252],
      },
      {
        label: "Arms",
        value: "Arms",
        viewId: "arms",
        lineAnchor: [274, 261],
      },
      {
        label: "Hips & Pelvis",
        value: "Pelvis",
        viewId: "pelvis",
        lineAnchor: [228, 305],
      },
      {
        label: "Legs",
        value: "Legs",
        viewId: "legs",
        lineAnchor: [230, 449],
      },
    ],
  },
  "body back": {
    imgUrl: "view-assets/female_whole_back_covered.svg",
    choices: [
      {
        label: "Head",
        value: "Head",
        viewId: "head",
      },
      {
        label: "Neck",
        value: "Neck",
      },
      {
        label: "Upper back",
        value: "Upper back",
        viewId: "upper back",
      },
      {
        label: "Middle back",
        value: "Middle back",
        viewId: "middle back",
      },
      {
        label: "Lower back",
        value: "Lower back",
        viewId: "lower back",
      },
      {
        label: "Buttocks",
        value: "Buttocks",
        viewId: "buttocks",
      }
    ],
  },
  head: {
    imgUrl: "view-assets/female_head_neck.svg",
    choices: [
      {
        label: "Top",
        value: "Top of head",
        lineAnchor: [292, 35],
      },
      {
        label: "Forehead",
        value: "Forehead",
        lineAnchor: [239, 131],
      },
      {
        label: "Temple",
        value: "Temple",
        lineAnchor: [343, 184],
      },
      {
        label: "Eyes",
        value: "Eyes",
        lineAnchor: [262, 228],
      },
      {
        label: "Nose",
        value: "Nose",
        lineAnchor: [227, 270],
      },
      {
        label: "Ears",
        value: "Ears",
        lineAnchor: [405, 247],
      },
      {
        label: "Mouth",
        value: "Mouth",
        lineAnchor: [236, 319],
      },
      {
        label: "Neck",
        value: "Neck",
        lineAnchor: [311, 444],
      },
    ],
  },
  chest: {
    imgUrl: "view-assets/female_shoulders_chest_covered.svg",
    choices: [
      {
        label: "Collarbone",
        value: "Collarbone",
        highlightRegion: "Collarbone-2",
      },
      {
        label: "Upper Chest / Pectorals",
        value: "Upper Chest / Pectorals",
      },
      {
        label: "Breasts",
        value: "Breasts",
        highlightRegion: "Breasts",
      },
      {
        label: "Ribs",
        value: "Ribs",
      },
      {
        label: "Sternum",
        value: "Sternum",
        highlightRegion: "Sternum",
      },
      {
        label: "Lower Chest",
        value: "Lower Chest",
      },
      {
        label: "Diaphragm",
        value: "Diaphragm",
        highlightRegion: "Diaphragm",
      },
    ],
  },

  abdomen: {
    imgUrl: "view-assets/female_abs_covered.svg",
    choices: [
      {
        label: "Upper Sides",
        value: "Upper Sides",
        highlightRegion: "Upper Right Ab",
      },
      {
        label: "Upper Center",
        value: "Upper Center",
        highlightRegion: "Upper Center Ab",
      },
      {
        label: "Mid Sides",
        value: "Mid Sides",
        highlightRegion: "Middle Right Ab",
      },
      {
        label: "Mid Center",
        value: "Mid Center",
        highlightRegion: "Middle Center Ab",
      },
      {
        label: "Lower Sides",
        value: "Lower Sides",
        highlightRegion: "Lower Left Ab",
      },
      {
        label: "Lower Center",
        value: "Lower Center",
        highlightRegion: "Lower Center Ab",
      },
    ],
  },

  "head & neck back": {
    imgUrl: "view-assets/female_back_body_covered.svg",
    choices: [
      {
        label: "Neck Muscles",
        value: "Neck Muscles",
        highlightRegion: "Neck",
      },
      {
        label: "Cervical Vertebrae (Neck Spine)",
        value: "Cervical Vertebrae",
      }      
    ],
  },

  "upper back": {
    imgUrl: "view-assets/female_back_body_covered.svg",
    choices: [
      {
        label: "Upper Right Back Muscles",
        value: "Upper Right Back Muscles",
        highlightRegion: "Upper Back",
      },
      {
        label: "Upper Left Back Muscles",
        value: "Upper Left Back Muscles",
      },
      {
        label: "Thoracic Vertebrae (Spine)",
        value: "Thoracic Vertebrae",
        highlightRegion: "Lower Back",
      }
    ],
  },
  "middle back": {
    imgUrl: "view-assets/female_back_body_covered.svg",
    choices: [
      {
        label: "Middle Right Back Muscles",
        value: "Middle Right Back Muscles",
        highlightRegion: "Mid Back",
      },
      {
        label: "Middle Left Back Muscles",
        value: "Middle Left Back Muscles",
      },      
      {
        label: "Thoracic Vertebrae (Spine)",
        value: "Thoracic Vertebrae",
      }
    ],
  },

  "lower back": {
    imgUrl: "view-assets/female_back_body_covered.svg",
    choices: [
      {
        label: "Lower Right Back Muscles",
        value: "Lower Right Back Muscles",
        highlightRegion: "Lower Back",
      },
      {
        label: "Lower Left Back Muscles",
        value: "Lower Left Back Muscles",
      },
      {
        label: "Lumbar Vertebrae (Spine)",
        value: "Lumbar Vertebrae",
      }
    ],
  },
  buttocks: {
    imgUrl: "view-assets/female_back_body_covered.svg",
    choices: [
      {
        label: "Gluteal Muscles",
        value: "Gluteal Muscles",
        highlightRegion: "Buttocks",
      },
      {
        label: "Sacrum (base of the spine)",
        value: "Sacrum",
      },
      {
        label: "Coccyx (tailbone)",
        value: "Coccyx",
      }
    ],
  },

  arms: {
    imgUrl: "view-assets/female_arms_covered.svg",
    choices: [
      {
        label: "Shoulders",
        value: "Shoulders",
        highlightRegion: "Shoulders",
      },
      {
        label: "Biceps",
        value: "Biceps",
        highlightRegion: "Biceps",
      },
      {
        label: "Triceps",
        value: "Triceps",
      },
      {
        label: "Elbow",
        value: "Elbow",
        highlightRegion: "Elbows",
      },
      {
        label: "Forearms",
        value: "Forearms",
        highlightRegion: "Forearms",
      },
      {
        label: "Hand",
        value: "Hand",
        viewId: "hands",
        highlightRegion: "Hands",
      },
    ],
  },

  hands: {
    imgUrl: "view-assets/front_hand.svg",
    choices: [
      {
        label: "Wrist",
        value: "Wrist",
        highlightRegion: "Wrist",
      },
      {
        label: "Palm",
        value: "Palm",
        highlightRegion: "Palm",
      },
      {
        label: "Thumb",
        value: "Thumb",
        highlightRegion: "Thumb",
      },
      {
        label: "Index Finger",
        value: "Index Finger",
        highlightRegion: "Index Finger",
      },
      {
        label: "Middle Finger",
        value: "Middle Finger",
        highlightRegion: "Middle Finer",
      },
      {
        label: "Ring Finger",
        value: "Ring Finger",
        highlightRegion: "Ring Finger",
      },
      {
        label: "Pinky Finger",
        value: "Pinky Finger",
        highlightRegion: "Pinky Finger",
      },
      {
        label: "Back of Hand",
        value: "Back of Hand",
      },
    ],
  },

  pelvis: {
    imgUrl: "view-assets/female_genitals_buttocks_covered.svg",
    choices: [
      {
        label: "Hips & pelvis",
        value: "Hips & pelvis",
        highlightRegion: "Front Hip and Pelvis",
      },
      {
        label: "Buttocks",
        value: "Buttocks",
        highlightRegion: "Buttocks",
      }
    ],
  },

  legs: {
    imgUrl: "view-assets/female_legs_covered.svg",
    choices: [
      {
        label: "Thighs",
        value: "Thighs",
        highlightRegion: "Thighs",
      },
      {
        label: "Quadriceps",
        value: "Quadriceps",
      },
      {
        label: "Hamstrings",
        value: "Hamstrings",
      },
      {
        label: "Knees",
        value: "Knees",
        highlightRegion: "Knees",
      },
      {
        label: "Lower Legs",
        value: "Lower Legs",
        highlightRegion: "Lower Legs",
      },
      {
        label: "Calves",
        value: "Calves",
      },
      {
        label: "Shin",
        value: "Shin",
      },
      {
        label: "Foot",
        value: "Foot",
        viewId: "feet",
        highlightRegion: "Feet",
      },
    ],
  },

  feet: {
    imgUrl: "view-assets/foot.svg",
    choices: [
      {
        label: "Ankle",
        value: "Ankle",
        highlightRegion: "Ankle",
      },
      {
        label: "Top of Foot",
        value: "Top of Foot",
        highlightRegion: "Top of Foot",
      },
      {
        label: "Arch",
        value: "Arch",
        highlightRegion: "Arch",
      },
      {
        label: "Ball",
        value: "Ball",
        highlightRegion: "Ball",
      },
      {
        label: "Big toe",
        value: "Big toe",
        highlightRegion: "Big Toe",
      },
      {
        label: "Second toe",
        value: "Second toe",
        highlightRegion: "Second Toe",
      },
      {
        label: "Middle toe",
        value: "Middle toe",
        highlightRegion: "Middle Toe",
      },
      {
        label: "Fore toe",
        value: "Fore toe",
        highlightRegion: "Fore Toe",
      },
      {
        label: "Pinky toe",
        value: "Pinky toe",
        highlightRegion: "Pinky Toe",
      },
    ],
  }, */
};

const DEFAULT_HUMAN_MODEL_V2 : AnatomicalModel = {
  "body front": {
    "isRoot": true,
    "isLeaf": false,
    "imgUrl": "view-assets/female_front_covered.svg",
    "choices": [
      {
        "viewId": "head",
        "label": "Head",
        "labelTranslationId": "R_ANT025",
        "lineAnchor": [
          206,
          48
        ]
      },
      {
        "viewId": "chest",
        "label": "Chest",
        "labelTranslationId": "R_ANT005",
        "lineAnchor": [
          200,
          169
        ]
      },
      {
        "viewId": "arms",
        "label": "Arm",
        "labelTranslationId": "R_ANT009",
        "lineAnchor": [
          274,
          261
        ]
      },
      {
        "viewId": "hands",
        "label": "Hand",
        "labelTranslationId": "R_ANT054"
      },
      {
        "viewId": "abdomen",
        "label": "Belly",
        "labelTranslationId": "R_ANT103",
        "lineAnchor": [
          200,
          252
        ]
      },
      {
        "viewId": "pelvis",
        "label": "Hips & Pelvis",
        "labelTranslationId": "R_ANT063",
        "lineAnchor": [
          228,
          305
        ]
      },
      {
        "viewId": "legs",
        "label": "Leg",
        "labelTranslationId": "R_ANT066",
        "lineAnchor": [
          230,
          449
        ]
      },
      {
        "viewId": "feet",
        "label": "Foot",
        "labelTranslationId": "R_ANT073"
      },
      {
        "viewId": "back",
        "label": "Back",
        "labelTranslationId": "R_ANT024",
        "lineAnchor": [
          230,
          449
        ]
      }
    ]
  },
  "head": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/female_head_neck.svg",
    "choices": [
      {
        "value": "Top of head",
        "label": "Top",
        "labelTranslationId": "R_ANT026",
        "lineAnchor": [
          292,
          35
        ]
      },
      {
        "value": "Forehead",
        "label": "Forehead",
        "labelTranslationId": "R_ANT027",
        "lineAnchor": [
          239,
          131
        ]
      },
      {
        "value": "Temple",
        "label": "Temple",
        "labelTranslationId": "R_ANT028",
        "lineAnchor": [
          343,
          184
        ]
      },
      {
        "value": "Eyes",
        "label": "Eye",
        "labelTranslationId": "R_ANT029",
        "lineAnchor": [
          262,
          228
        ]
      },
      {
        "value": "Nose",
        "label": "Nose",
        "labelTranslationId": "R_ANT030",
        "lineAnchor": [
          227,
          270
        ]
      },
      {
        "value": "Ears",
        "label": "Ear",
        "labelTranslationId": "R_ANT031",
        "lineAnchor": [
          405,
          247
        ]
      },
      {
        "value": "Mouth",
        "label": "Mouth",
        "labelTranslationId": "R_ANT032",
        "lineAnchor": [
          236,
          319
        ]
      },
      {
        "value": "Neck",
        "label": "Neck",
        "labelTranslationId": "R_ANT003",
        "lineAnchor": [
          311,
          444
        ]
      }
    ]
  },
  "chest": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/female_shoulders_chest_covered.svg",
    "choices": [
      {
        "value": "Collarbone",
        "label": "Collarbone",
        "labelTranslationId": "R_ANT033",
        "highlightRegion": "Collarbone-2"
      },
      {
        "value": "Upper Chest / Pectorals",
        "label": "Upper Chest",
        "labelTranslationId": "R_ANT034"
      },
      {
        "value": "Breasts",
        "label": "Breasts",
        "labelTranslationId": "R_ANT035",
        "highlightRegion": "Breasts"
      },
      {
        "value": "Ribs",
        "label": "Ribs",
        "labelTranslationId": "R_ANT036"
      },
      {
        "value": "Sternum",
        "label": "Sternum",
        "labelTranslationId": "R_ANT037",
        "highlightRegion": "Sternum"
      },
      {
        "value": "Lower Chest",
        "label": "Lower Chest",
        "labelTranslationId": "R_ANT038"
      },
      {
        "value": "Diaphragm",
        "label": "Diaphragm",
        "labelTranslationId": "R_PRG045",
        "highlightRegion": "Diaphragm"
      }
    ]
  },
  "abdomen": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/female_abs_covered.svg",
    "choices": [
      {
        "value": "Upper Sides",
        "label": "Upper Sides",
        "labelTranslationId": "R_ANT039",
        "highlightRegion": "Upper Right Ab"
      },
      {
        "value": "Upper Center",
        "label": "Upper Center",
        "labelTranslationId": "R_ANT040",
        "highlightRegion": "Upper Center Ab"
      },
      {
        "value": "Mid Sides",
        "label": "Mid Sides",
        "labelTranslationId": "R_ANT042",
        "highlightRegion": "Middle Right Ab"
      },
      {
        "value": "Mid Center",
        "label": "Mid Center",
        "labelTranslationId": "R_ANT043",
        "highlightRegion": "Middle Center Ab"
      },
      {
        "value": "Lower Sides",
        "label": "Lower Sides",
        "labelTranslationId": "R_ANT045",
        "highlightRegion": "Lower Left Ab"
      },
      {
        "value": "Lower Center",
        "label": "Lower Center",
        "labelTranslationId": "R_ANT046",
        "highlightRegion": "Lower Center Ab"
      }
    ]
  },
  "back": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/female_back_body_covered.svg",
    "choices": [
      {
        "value": "Neck Muscles",
        "label": "Neck Muscles",
        "labelTranslationId": "R_ANT085",
        "highlightRegion": "Neck"
      },
      {
        "value": "Cervical Vertebrae",
        "label": "Neck Bones",
        "labelTranslationId": "R_ANT064"
      },
      {
        "value": "Thoracic Vertebrae",
        "label": "Back Bones",
        "labelTranslationId": "R_ANT089",
        "highlightRegion": "Lower Back"
      },
      {
        "value": "Upper Back Muscles",
        "label": "Upper Back Muscles",
        "labelTranslationId": "R_ANT087",
        "highlightRegion": "Upper Back"
      },
      {
        "value": "Middle Back Muscles",
        "label": "Middle Back Muscles",
        "labelTranslationId": "R_ANT091",
        "highlightRegion": "Mid Back"
      },
      {
        "value": "Lower Back Muscles",
        "label": "Lower Back Muscles",
        "labelTranslationId": "R_ANT093",
        "highlightRegion": "Lower Back"
      }
    ]
  },
  "pelvis": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/female_back_body_covered.svg",
    "choices": [
      {
        "value": "Hips & pelvis",
        "label": "Hips & pelvis",
        "labelTranslationId": "R_ANT063",
        "highlightRegion": "Front Hip and Pelvis"
      },
      {
        "value": "Gluteal Muscles",
        "label": "Buttocks",
        "labelTranslationId": "R_ANT065",
        "highlightRegion": "Buttocks"
      },
      {
        "value": "Sacrum",
        "label": "Base of the spine",
        "labelTranslationId": "R_ANT097",
        "highlightRegion": "Base of the spine"
      },
      {
        "value": "Coccyx",
        "label": "Tailbone",
        "labelTranslationId": "R_ANT102",
        "highlightRegion": "Tailbone"
      }
    ]
  },
  "arms": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/female_arms_covered.svg",
    "choices": [
      {
        "value": "Shoulders",
        "label": "Shoulder",
        "labelTranslationId": "R_ANT049",
        "highlightRegion": "Shoulders"
      },
      {
        "value": "Biceps",
        "label": "Bicep",
        "labelTranslationId": "R_ANT050",
        "highlightRegion": "Biceps"
      },
      {
        "value": "Triceps",
        "label": "Tricep",
        "labelTranslationId": "R_ANT051"
      },
      {
        "value": "Elbow",
        "label": "Elbow",
        "labelTranslationId": "R_ANT052",
        "highlightRegion": "Elbows"
      },
      {
        "value": "Forearms",
        "label": "Forearm",
        "labelTranslationId": "R_ANT053",
        "highlightRegion": "Forearms"
      }
    ]
  },
  "hands": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/front_hand.svg",
    "choices": [
      {
        "value": "Wrist",
        "label": "Wrist",
        "labelTranslationId": "R_ANT055",
        "highlightRegion": "Wrist"
      },
      {
        "value": "Palm",
        "label": "Palm",
        "labelTranslationId": "R_ANT056",
        "highlightRegion": "Palm"
      },
      {
        "value": "Thumb",
        "label": "Thumb",
        "labelTranslationId": "R_ANT057",
        "highlightRegion": "Thumb"
      },
      {
        "value": "Index Finger",
        "label": "Index Finger",
        "labelTranslationId": "R_ANT058",
        "highlightRegion": "Index_Finger"
      },
      {
        "value": "Middle Finger",
        "label": "Middle Finger",
        "labelTranslationId": "R_ANT059",
        "highlightRegion": "Middle_Finer"
      },
      {
        "value": "Ring Finger",
        "label": "Ring Finger",
        "labelTranslationId": "R_ANT060",
        "highlightRegion": "Ring_Finger"
      },
      {
        "value": "Pinky Finger",
        "label": "Pinky Finger",
        "labelTranslationId": "R_ANT061",
        "highlightRegion": "Pinky_Finger"
      },
      {
        "value": "Back of Hand",
        "label": "Back of Hand",
        "labelTranslationId": "R_ANT062"
      }
    ]
  },
  "legs": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/female_legs_covered.svg",
    "choices": [
      {
        "value": "Thighs",
        "label": "Thigh",
        "labelTranslationId": "R_ANT067",
        "highlightRegion": "Thighs"
      },
      {
        "value": "Quadriceps",
        "label": "Quadricep",
        "labelTranslationId": "R_ANT068"
      },
      {
        "value": "Hamstrings",
        "label": "Hamstring",
        "labelTranslationId": "R_ANT069"
      },
      {
        "value": "Knees",
        "label": "Knee",
        "labelTranslationId": "R_ANT070",
        "highlightRegion": "Knees"
      },
      {
        "value": "Calves",
        "label": "Calve",
        "labelTranslationId": "R_ANT071"
      },
      {
        "value": "Shin",
        "label": "Shin",
        "labelTranslationId": "R_ANT072"
      }
    ]
  },
  "feet": {
    "isRoot": false,
    "isLeaf": true,
    "imgUrl": "view-assets/foot.svg",
    "choices": [
      {
        "value": "Ankle",
        "label": "Ankle",
        "labelTranslationId": "R_ANT074",
        "highlightRegion": "Ankle"
      },
      {
        "value": "Top of Foot",
        "label": "Top of Foot",
        "labelTranslationId": "R_ANT075",
        "highlightRegion": "Top of Foot"
      },
      {
        "value": "Arch",
        "label": "Arch",
        "labelTranslationId": "R_ANT077",
        "highlightRegion": "Arch"
      },
      {
        "value": "Achilles Tendon",
        "label": "Achilles Tendon",
        "labelTranslationId": "R_ANT076",
        "highlightRegion": "Achilles Tendon"
      },
      {
        "value": "Ball",
        "label": "Ball",
        "labelTranslationId": "R_ANT078",
        "highlightRegion": "Ball"
      },
      {
        "value": "Big toe",
        "label": "Big toe",
        "labelTranslationId": "R_ANT079",
        "highlightRegion": "Big Toe"
      },
      {
        "value": "Second toe",
        "label": "Second toe",
        "labelTranslationId": "R_ANT080",
        "highlightRegion": "Second Toe"
      },
      {
        "value": "Middle toe",
        "label": "Middle toe",
        "labelTranslationId": "R_ANT081",
        "highlightRegion": "Middle Toe"
      },
      {
        "value": "Fore toe",
        "label": "Fore toe",
        "labelTranslationId": "R_ANT082",
        "highlightRegion": "Fore Toe"
      },
      {
        "value": "Pinky toe",
        "label": "Pinky toe",
        "labelTranslationId": "R_ANT083",
        "highlightRegion": "Pinky Toe"
      }
    ]
  }
}
/*
let modelToSheetExport = "";
Object.entries(DEFAULT_HUMAN_MODEL_V2).forEach(([id, view], index) => {
  const viewData = [
    id,
    !!view.isRoot,
    !!view.isLeaf,
    view.imgUrl ?? ""
  ];
  modelToSheetExport += viewData.join("\t");
  view.choices.forEach((c, i) => {
    if (i > 0) {
      modelToSheetExport += viewData.map(x => "").join("\t");
    }
    const choiceData = [
      i,
      c.viewId,
      c.value,
      c.label,
      c.labelTranslationId,
      c.highlightRegion,
      c.lineAnchor ? JSON.stringify(c.lineAnchor) : ""
    ];
    modelToSheetExport += "\t" + choiceData.join("\t") + "\n";
  })
  if (view.choices.length === 0) {
    modelToSheetExport += "\n";
  }
});
console.warn(modelToSheetExport);
*/

const EmptyModel : AnatomicalModel = {"error": {imgUrl: "--", choices: [], isLeaf: true}};

export const PredefinedModels : Record<string, AnatomicalModel> = {
  "default_human": DEFAULT_HUMAN_MODEL_V2,
  "EMPTY": EmptyModel
};
