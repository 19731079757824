import { Spinner } from "react-bootstrap";

export const LoadingPage = () => {
  
  return(
    <div
      className="centered-flex-container main-columm"
      style={{ minHeight: "100vh", justifyContent: "center" }}
    >
      <h3 className="text-center text-muted">Loading...</h3>
      <Spinner
        animation="border"
        style={{ opacity: 0.7, width: 80, height: 80 }}
      />
    </div>
  );
}
