import { FaVolumeUp } from "react-icons/fa";
import { audioSlice } from "../../store/slices/audio";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";

const PLAY_STATES = ["init", "ready", "playing", "NOT FOUND", "PLAYBACK ERROR"];
const PlayStateColor = {
  init: "gray",
  ready: "black",
  playing: "green",
  "NOT FOUND": "brown",
  "PLAYBACK ERROR": "red",
};
export function AudioButton({ lockPlaying } : {lockPlaying: boolean}) {
  const dispatch = useAppDispatch();
  const audioAutoplay = useAppSelector(s => s.audio.autoplay);
  function clickAudio() {
    if (!lockPlaying) {
      dispatch(audioSlice.actions.toggleAutoplay());
    }
  }
  return (
    <div
      className={`chrome-btn centered-btn ${
        lockPlaying ?? audioAutoplay ? "pulsing" : ""
      }`}
      style={{
        float: "right",
        margin: "20px",
        pointerEvents: "visible",
        color: lockPlaying ?? audioAutoplay ? PlayStateColor.playing : PlayStateColor.ready,
      }}
      onClick={clickAudio}
    >
      {/* <img src="/images/patient_redesign/speaker.svg" style={{display: "block"}}/> */}
      <FaVolumeUp />
    </div>
  );
}
