import { useEffect, useMemo, useRef, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { useSize } from "../../../utils/hooks";
import { AudioButton } from "../PlayButtonStub";

const evergreen = "var(--evergreen)";

export const NavigationalOverlay = ({
  page,
  buttonState,
  activeQuestion = {},
  activeAnswer,
  artOverride,
  mode,
  goBackExit,
  goForward,
  longPress,
  hideFade,
  useNeutralColor,
}) => {
  const [mainArt, setMainArt] = useState(null);
  const [addlArtLeft, setAddlArtLeft] = useState(null);
  const [addlArtRight, setAddlArtRight] = useState(null);

  const [svgSizingReference, vectorWidth, vectorHeight] = useSize(true);

  // console.warn({mode, buttonState});
  const nextButtonSettings = buttonState.next;

  useEffect(() => {
    if (artOverride) {
      if (artOverride !== mainArt) {
        console.warn({ artOverride });
        setMainArt(artOverride);
      }
    } else if (activeQuestion?.art !== mainArt) {
      setMainArt(activeQuestion?.art);
    }
    if (Array.isArray(activeQuestion?.additionalArt)) {
      // TODO: more advanced directional order logic here!
      // we will eventually support reading settings from the art objects
      // to control their positioning
      if (activeQuestion?.additionalArt[0] !== addlArtLeft) {
        setAddlArtLeft(activeQuestion?.additionalArt[0]);
      }
      if (activeQuestion?.additionalArt[1] !== addlArtRight) {
        setAddlArtRight(activeQuestion?.additionalArt[1]);
      }
    } else {
      setAddlArtLeft(null);
      setAddlArtRight(null);
    }
  }, [activeQuestion, artOverride]);

  function clickBackExit() {
    goBackExit();
  }

  function conditionalGoForward(event) {
    if (event.shiftKey) {
      goForward();
    }
  }

  const pressTimer = useRef(null);
  const LONG_PRESS_MS = 1500;
  function waitForLongPress(event) {
    console.warn("mousedown");
    pressTimer.current = setTimeout(() => {
      if (typeof longPress === "function") {
        longPress(event);
      } else {
        console.warn(
          "Long press received, but longPress parameter was: ",
          longPress
        );
      }
    }, LONG_PRESS_MS);
  }
  function cancelLongPress(event) {
    console.warn("mouseup");
    if (pressTimer.current) {
      clearTimeout(pressTimer.current);
      pressTimer.current = null;
    }
  }
  function handleMobileContextMenu(event) {
    console.warn("contextmenu");
    event.preventDefault();
    if (typeof longPress === "function") {
      longPress();
    }
  }

  const ADDL_ART_OFFSET = 90;

  return (
    <div
      style={{
        position: "fixed",
        width: "max(100vw, 320px)",
        height: "100vh",
        top: "0px",
        zIndex: "50",
        pointerEvents: "none",
      }}
    >
      {mode === "backdrop" ? (
        <svg
          width="100%"
          height="25%"
          viewBox="0 0 1023 283"
          fill="none"
          preserveAspectRatio="none"
          style={{
            minHeight: "125px",
            position: "absolute",
            pointerEvents: "visible",
          }}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0H1023V260.658C1023 260.658 832.786 283 506.705 283C180.623 283 0 260.658 0 260.658V0Z"
            fill="url(#paint0_linear_758_1332)"
          />
          <path
            d="M0 0H1023V260.658C1023 260.658 832.786 283 506.705 283C180.623 283 0 260.658 0 260.658V0Z"
            fill="url(#paint1_linear_758_1332)"
          />
          <path
            d="M0 0H1023V260.658C1023 260.658 832.786 283 506.705 283C180.623 283 0 260.658 0 260.658V0Z"
            fill="var(--evergreen)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_758_1332"
              x1="511.5"
              y1="0"
              x2="511.5"
              y2="283"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FAF45F" />
              <stop offset="1" stopColor="#F8B300" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_758_1332"
              x1="511.5"
              y1="0"
              x2="511.5"
              y2="283"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#95E76E" />
              <stop offset="1" stopColor="#0A9150" />
            </linearGradient>
          </defs>
        </svg>
      ) : null}
      <div
        className="backdrop"
        style={{
          position: "relative",
          margin: "auto",
          height: "max(25dvh, 125px)",
        }}
        ref={svgSizingReference}
      >
        <svg
          width={"100%"}
          height={`max(25dvh, 125px)`}
          style={{
            background: false ? "rgba(0, 150, 250, 0.1)" : "transparent",
            position: "absolute",
          }}
        >
          <defs>
            <filter id="backdropShadow">
              <feDropShadow
                dx="0"
                dy="0"
                stdDeviation="4"
                floodColor="black"
                floodOpacity="0.2"
              />
            </filter>
            <linearGradient id="fadeBack" x1="0" x2="0" y1="1" y2="0">
              <stop offset="0%" stopColor="gray" stopOpacity="0" />
              <stop offset="10%" stopColor="white" stopOpacity="0" />
              <stop offset="100%" stopColor="white" stopOpacity="0.9" />
            </linearGradient>
          </defs>
          {/* <rect x="0" y="0" width="100%" height="100%" fill="url(#fadeBack)" style={{pointerEvents: "none", opacity: hideFade ? 0 : 1}}/> */}
          {vectorWidth ? (
            <>
              <g>
                {mode === "bubble" ? (
                  <circle
                    opacity={mode === "no-art" ? "0" : "1"}
                    cx={
                      mode === "backdrop"
                        ? vectorWidth / 2.0
                        : vectorWidth / 2.0
                    }
                    cy={mode === "backdrop" ? -512 : 48}
                    r={mode === "backdrop" ? 640 : 32}
                    fill={evergreen}
                    filter="url(#backdropShadow)"
                    style={{
                      /* transition: "cx 1s linear, cy 1s linear, r 1s linear, opacity 0.5s linear", */ filter:
                        "url(#backdropShadow)",
                    }}
                  />
                ) : null}
                {mainArt ? (
                  <image
                    href={mainArt}
                    opacity={mode === "no-art" ? "0" : "1"}
                    x={
                      mode === "backdrop"
                        ? (vectorWidth - vectorHeight) / 2
                        : vectorWidth / 2 - 28
                    } /* (vectorWidth / 2 - 64) */
                    y={mode === "backdrop" ? 0 : 16 + 4}
                    width={mode === "backdrop" ? vectorHeight : 56} /* 128 */
                    height={mode === "backdrop" ? vectorHeight : 56}
                    stroke="pink"
                    // style={{transition: "x 1s linear, y 1s linear, width 1s linear, height 1s linear, opacity 0.5s linear"}}
                    onError={(err) => {
                      setMainArt(null);
                      console.error(`Error loading art '${mainArt}'`, err);
                    }}
                  />
                ) : mode !== "no-art" ? (
                  <text
                    y="25"
                    x="50%"
                    style={{
                      textAnchor: "middle",
                      opacity: "0.6",
                      fill: "purple",
                    }}
                  >
                    missing image
                  </text>
                ) : null}
              </g>
              {addlArtLeft ? (
                <g className="aux_art">
                  <circle
                    opacity={mode === "no-art" ? "0" : "1"}
                    cx={vectorWidth / 2.0 - ADDL_ART_OFFSET}
                    cy={mode === "backdrop" ? -512 : 48}
                    r={mode === "backdrop" ? 640 : 32}
                    fill={evergreen}
                    filter="url(#backdropShadow)"
                    style={{
                      transition:
                        "cx 1s linear, cy 1s linear, r 1s linear, opacity 0.5s linear",
                      filter: "url(#backdropShadow)",
                    }}
                  />
                  <image
                    href={addlArtLeft}
                    opacity={mode === "no-art" ? "0" : "1"}
                    x={
                      mode === "backdrop"
                        ? vectorWidth / 2 - 64 - ADDL_ART_OFFSET
                        : vectorWidth / 2 - 28 - ADDL_ART_OFFSET
                    }
                    y={mode === "backdrop" ? 0 : 16 + 4}
                    width={mode === "backdrop" ? 128 : 56}
                    height={mode === "backdrop" ? 128 : 56}
                    stroke="pink"
                    style={{
                      transition:
                        "x 1s linear, y 1s linear, width 1s linear, height 1s linear, opacity 0.5s linear",
                    }}
                  />
                </g>
              ) : null}
              {addlArtRight ? (
                <g className="aux_art">
                  <circle
                    opacity={mode === "no-art" ? "0" : "1"}
                    cx={vectorWidth / 2.0 + ADDL_ART_OFFSET}
                    cy={mode === "backdrop" ? -512 : 48}
                    r={mode === "backdrop" ? 640 : 32}
                    fill={evergreen}
                    filter="url(#backdropShadow)"
                    style={{
                      transition:
                        "cx 1s linear, cy 1s linear, r 1s linear, opacity 0.5s linear",
                      filter: "url(#backdropShadow)",
                    }}
                  />
                  <image
                    href={addlArtRight}
                    opacity={mode === "no-art" ? "0" : "1"}
                    x={
                      mode === "backdrop"
                        ? vectorWidth / 2 - 64 + ADDL_ART_OFFSET
                        : vectorWidth / 2 - 28 + ADDL_ART_OFFSET
                    }
                    y={mode === "backdrop" ? 0 : 16 + 4}
                    width={mode === "backdrop" ? 128 : 56}
                    height={mode === "backdrop" ? 128 : 56}
                    stroke="pink"
                    style={{
                      transition:
                        "x 1s linear, y 1s linear, width 1s linear, height 1s linear, opacity 0.5s linear",
                    }}
                  />
                </g>
              ) : null}
            </>
          ) : null}
        </svg>
        <div
          style={{ width: "100%", maxWidth: "100dvw", position: "relative" }}
        >
          {buttonState?.back?.visible ?? true ? (
            <div
              className="chrome-btn centered-btn"
              id="backward-button"
              style={{
                float: "left",
                margin: "20px",
                pointerEvents: "visible",
              }}
              onClick={clickBackExit}
              onMouseDown={waitForLongPress}
              onMouseUp={cancelLongPress}
            >
              {buttonState?.back?.icon === "back" ? (
                <img
                  src="/images/patient_redesign/chevron.svg"
                  style={{ display: "block" }}
                  alt="navigational back arrow"
                />
              ) : null}
              {buttonState?.back?.icon === "quit" ? <FaTimes /> : null}
            </div>
          ) : null}
          {buttonState?.audio?.visible ?? true ? <AudioButton lockPlaying={buttonState?.audio?.lockPlaying} /> : null}
        </div>
      </div>
      <div
        className={`chrome-btn chrome-btn-lg centered-btn float-bottom-right ${
          nextButtonSettings.active ? "selected" : ""
        }`}
        style={{
          pointerEvents: "fill",
          transition: "opacity 1s linear, bottom 1s linear",
          // opacity: nextButtonSettings.visible ? 1 : 0,
          bottom: nextButtonSettings.visible ? `` : -84,
          background: nextButtonSettings.error
            ? "var(--salmon-red)"
            : useNeutralColor
            ? "white"
            : nextButtonSettings.active
            ? ""
            : "#CCC",
        }}
        onClick={(e) =>
          nextButtonSettings.active ? goForward() : conditionalGoForward(e)
        }
        onMouseDown={waitForLongPress}
        onMouseUp={cancelLongPress}
        onContextMenu={handleMobileContextMenu}
      >
        <img
          src="/images/patient_redesign/arrow-right.svg"
          style={{ filter: useNeutralColor ? "invert(1)" : "" }}
          alt="navigatioanl forward arrow"
        />
      </div>

      {buttonState?.callToAction ? (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100vw",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div
            className="btn btn-lg btn-light"
            style={{
              padding: "12px 48px",
              marginBottom: "12px",
              minWidth: "280px",
              background: "var(--evergreen)",
              fontWeight: "bold",
              pointerEvents: "fill",
            }}
            onClick={() => goForward()}
          >
            {buttonState.callToAction}
          </div>
        </div>
      ) : null}

      {/* <span className="text-muted" style={{opacity: 0.2}}>mode:{mode}</span> */}
    </div>
  );
};
