import { useEffect, useMemo, useRef, useState } from "react";
import { HIGHLIGHT_BY_SVG_NODES, POINTS_ZEROED_TO_SVG_ROOT, PredefinedModels, SVG_SELECTION_TYPE, USE_DYNAMIC_SVG_EMBED, USE_SEMITRANSPARENT_CHOICES } from "../../../../constants/anatomical-models";
import { AnatomicalModel, AnatomicalRegion, AnatomicalView, applyResolutionMethod, ClickedRegion, clickIsInside, DrawableAnatomicalRegion, findRoughCenter, getRootViewOfModel, PointXY, RegionShape, SVGRegionSelectorType } from "../../../../models/anatomical-models";
import { Answer } from "../../../../models/answers";
import { AnatomicalRegionQuestionDefinition } from "../../../../models/questions";
import { isObject, snakeCase } from "../../../../utils";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import { JSObjectDump } from "../../../../utils/UtilComponents";
import { DynamicSVG } from "../../../UI/images/DynamicSVG";
import { LastClickData } from "./__AnatomicalDebugPanel";
import "./selector-styles.css";
import { AudioSequence, audioSlice, requestOrRegister } from "../../../../store/slices/audio";
import { AudioLocation, getAudioLocationFromId } from "../../../../models/audio";
import { func } from "prop-types";
import { UncertainButton } from "../../../UI/buttons/UncertainResponse";
import { IDed } from "../../../../utils/database";

/** 
 * UI configurations that we currently need to set in the code as they 
 * affect dynamic point generation, particularly for drawing lines and placing
 * cards into the canvas.
 * 
 * These *could* be determined based on screen size, but that requires:
 *   1) better understanding of how to draw this UI on small screens
 *   2) a lot of fiddling with reacting to screen resizing, which is error-prone
 * It will definitely be something we do eventually™️ but not for MVP.
 */
const totalWidth = 675;
const totalHeight = totalWidth;
const rightPanelOffset = 420;
const truePanelItemHeight = 44;
const interPanelItemSpacing = 4;
const panelItemHeight = truePanelItemHeight + interPanelItemSpacing;
const paddingForShadowSpread = 10;

function linkPointFor(region: AnatomicalRegion) : PointXY {
  if (region.lineAnchor) {
    return region.lineAnchor;
  } else if (region.shape) {
    return findRoughCenter(region as DrawableAnatomicalRegion);
  } else {
    console.warn(`No valid line anchor or shape data for ${region.value}`);
    return [0, 0];
  }
}

/**
 * A line made of SVG elements with filled dots placed at both ends.
 */
function LineWithEndPoints ({a, b, color = "var(--evergreen)", radius = 6, opacity = 1} : 
  {a: PointXY, b: PointXY, color?: string, radius?: number, opacity?: number}) {
  return (<g style={{opacity}}>
  <circle cx={a[0]} cy={a[1]} r={radius} fill={color} />
  <line
    x1={a[0]}
    y1={a[1]}
    x2={b[0]}
    y2={b[1]}
    fill="transparent"
    stroke={color}
    strokeWidth={4}
    strokeOpacity={0.8}
  />
  <circle cx={b[0]} cy={b[1]} r={radius} fill={color} />
</g>)
}

/**
 * A line made of SVG elements connecting a region within the image with the
 * corresponding choice in the legend area.
 * 
 * This component assumes the legend is displayed in the standard right-anchor
 * position, and should not be used with bottom buttons at present.
 */
function LinkLineOf({region, index, semitransparent = false} : {region: AnatomicalRegion, index: number, semitransparent?: boolean}) {
  const p1 : PointXY = linkPointFor(region);
  if (!Number.isSafeInteger(index)) {
    console.error(`Region ${region.value} has no integer index, placing with random vertical nudge`);
  }
  const p2 : PointXY = [
    rightPanelOffset + paddingForShadowSpread,
    // using Math.random
    (index ?? Math.random()) * panelItemHeight + panelItemHeight / 2 + paddingForShadowSpread,
  ];
  return <LineWithEndPoints a={p1} b={p2} opacity={semitransparent ? 0.3 : 1.0}/>
}

function HighlightedRegion ({region, color, activeRegions} : {region: DrawableAnatomicalRegion, color?: string, activeRegions: string[]}) {
  const active = activeRegions.includes(region.value);
  const finalColor = color || (active ? "green" : "red");
  switch (region.shape) {
    case RegionShape.Circle:
      return (
        <g>
          <circle
            id={region.value}
            cx={region.center[0]}
            cy={region.center[1]}
            r={region.radius}
            fill={finalColor}
            fillOpacity="0.2"
            stroke={finalColor}
            strokeWidth="2"
          />
        </g>
      );
    case RegionShape.Rectangle:
      return (
        <g>
          <rect
            id={region.value}
            x={region.anchor[0]}
            y={region.anchor[1]}
            width={region.size[0]}
            height={region.size[1]}
            fill={finalColor}
            fillOpacity="0.2"
            stroke={finalColor}
            strokeWidth="2"
          />
        </g>
      );
    case RegionShape.Polygon:
      if (region.asCubicBezier) {
        if (region.points.length % 3 !== 1) {
          alert(
            "Expected points array for cubic bezier poly to satisfy mod(len, 3) = 1"
          );
        }
        let pathString = `M ${region.points[0][0]} ${region.points[0][1]} `;
        region.points.slice(1).forEach((pair, i) => {
          if (i % 3 === 0) {
            pathString += `C ${pair[0]} ${pair[1]}, `;
          } else {
            pathString += `${pair[0]} ${pair[1]}${i % 3 === 1 ? "," : ""} `;
          }
        });
        const weightedCenter = region.points
          .reduce(
            (prev, current, i) => [
              prev[0] + current[0],
              prev[1] + current[1],
            ],
            [0, 0]
          )
          .map((c) => c / region.points.length);
        return (
          <g>
            <path
              id={region.value}
              d={pathString}
              fill={finalColor}
              fillOpacity="0.2"
              stroke={finalColor}
              strokeWidth="2"
            />
          </g>
        );
      } else {
        const pointString = region.points
          .map((pair) => `${pair[0]},${pair[1]}`)
          .join(" ");
        const weightedCenter = region.points
          .reduce(
            (prev, current, i) => [
              prev[0] + current[0],
              prev[1] + current[1],
            ],
            [0, 0]
          )
          .map((c) => c / region.points.length);
        return (
          <g>
            <polygon
              id={region.value}
              points={pointString}
              fill={finalColor}
              fillOpacity="0.2"
              stroke={finalColor}
              strokeWidth="2"
            />
          </g>
        );
      }
    default:
      return null;
    // return <text x="10" y={20 + Math.random() * 40} fill="cyan">could not draw shape {region.id}</text>;
  }
}


const SELECT_ALL_SIGNAL: string = "«ALL»";
const NO_ANSWER_SIGNAL: string = "«no answer»";
const UNSURE_SIGNAL: string = "«unsure»";

interface AMRProps {
  answer: Answer<any>;
  questionDefinition: AnatomicalRegionQuestionDefinition;
  updateAnswer: (answerData: any) => void;
  complete: () => void;
  goBack: () => void;
  showTrackingInfo: boolean;
}
function AnatomicalModelResponse({
  answer,
  questionDefinition,
  updateAnswer,
  complete,
  goBack,
  showTrackingInfo
} : AMRProps) {
  const dispatch = useAppDispatch();

  const activeAudio = useAppSelector(s => s.audio.activeAudio);

  /**
   * Reference to the current <svg> element within the component. The primary
   * reason we want to track this is to be able to derive the actual on-screen
   * size of the SVG's bounding box, which is needed to use our spiffy drawing
   * functions.
   */
  const svgRef = useRef<SVGSVGElement | null>(null);

  /**
   * Data about the last click made, whic is only used for debugging.
   */
  const [lastClick, setLastClick] = useState<null | LastClickData>(null);

  /**
   * The model is the overall collection of images, views, and interactions, and
   * the relationships between them. At present we have a single model of the
   * human body, but in the future we will have varying models that provide
   * different levels of detail or different assortments of options.
   * 
   * Note that a response can effectively use a *subtree* of a model by choosing
   * an initialView that isn't the model's root view, but this is the only way
   * a response can tweak the view itself.
   */
  const model : AnatomicalModel = useMemo(() => {
    const m = questionDefinition.model;
    if (typeof m === "string") {
      if (m in PredefinedModels) {
        return PredefinedModels[m];
      } else {
        console.error(`Cannot find model ${m}`);
        return PredefinedModels.EMPTY;
      }
    }
    if (isObject(m, true)) {
      // validate it more? kind of complicated...
      return m as AnatomicalModel;
    }
    return PredefinedModels.default_human;
  }, [questionDefinition]);

  /**
   * The root view is both the initial view shown, as well as the highest point
   * in the model's tree of views that can be accessed. Views that are not
   * descendants of the root view are effectively not able to be shown, and thus
   * their choices can not be selected for the response.
   */
  const rootView = useMemo(() => {
    return questionDefinition.initialView ?? getRootViewOfModel(model);
  }, [questionDefinition, model]);


  /**
   * The currently-selected regions. This is transparently derived from the
   * answer array, but since we use it a lot, it's good to have stored as the
   * empty/null checks + map are kind of a mouthful.
   */
  const activeRegionIds : string[] = (Array.isArray(answer?.values) ? 
    answer.values.map((v) => v.value) : []);

  const hasDecidedChoice: boolean = activeRegionIds.length > 0 && !activeRegionIds.includes(NO_ANSWER_SIGNAL);

  /**
   * The view path is the list of all views that are ancestors of the current
   * answer set. IMPORTANT LOGIC: Since we only allow multiple choices within 
   * a single leaf view (and answers only *exist* at leaf-level), it suffices
   * to examine a single answer's viewPath to see what our navigation options
   * are. We do note when we find conflicting viewPaths in an answer but we
   * don't do anything about it other than emitting a warning.
   */
  let firstViewPath;
  if (answer && Array.isArray(answer.values) && answer.values.length > 0) {
    firstViewPath = answer.values[0].viewPath;
    if (!Array.isArray(firstViewPath)) {
      console.error(`Answer missing array viewPath! ${answer.values[0]}`);
      firstViewPath = undefined;
    } else {
      const flatVP = firstViewPath.join(",");
      answer.values.forEach((a, i) => {
        if (i === 0) return;
        if (a.viewPath.join(",") !== flatVP) {
          console.error(`Found non matching viewPath ${a.viewPath.join(",")} at index ${i}\n should be ${flatVP}`);
          // note that we aren't bailing out here because at least we can work with what we got
        }
      });
    }
  }
  /**
   * The list of ancestors of the current answer set, used for navigation. When
   * answers are not available we instead just use the root. (Note this case is
   * doubly-enforced, since viewIndices outside the array return rootView as 
   * well, see viewId below).
   */
  const viewPath = firstViewPath ?? [rootView];

  /**
   * The view index is our "page number" within the component
   */
  const [viewIndex, setViewIndex] = useState(0);

  /**
   * The viewIdOverride provides a way to force certain views to be displayed
   * regardless of the current answer + index, mostly used for debugging.
   */
  const [viewIdOverride, setViewIdOverride] = useState<string|null>(null);

    /**
   * The viewId is the name of the current view.
   */
    const viewId = viewIdOverride ??
      (viewIndex in viewPath ? viewPath[viewIndex] : rootView);

  /**
   * The view is the subpage within the model response; a specific grouping of
   * image, choices, and interaction regions.
   */
  const view : AnatomicalView = model[viewId] ?? { notFound: true };

  const autoplayAudio = useAppSelector(s => s.audio.autoplay);
  useEffect(() => {
    if (!autoplayAudio) return;
    const locs: AudioLocation[] = [];

    if (questionDefinition.translationId) {
      locs.push(getAudioLocationFromId(questionDefinition.translationId));
    }

    if (questionDefinition.subtextTranslationId) {
      locs.push(getAudioLocationFromId(questionDefinition.subtextTranslationId));
    }
    
    view.choices.forEach(c => {
      if (c.labelTranslationId !== undefined) {
        locs.push(getAudioLocationFromId(c.labelTranslationId));
      }
    });
    if (locs.length > 0) {
      requestOrRegister(locs, dispatch);
      const sequence: Partial<AudioSequence> & IDed = {
        id: `anatomical-question-${questionDefinition.id ?? answer.key}`,
        files: locs.map(l => l.filename),
        current: 0,
        loop: true,
        gap: 500, // 0.5 sec
        loopGap: 10000 // 3 sec
      };
      dispatch(audioSlice.actions.playSequence(sequence));
    }
  }, [view, autoplayAudio]);

  /**
   * The list of region definitions matching the current answer set.
   */
  const activeRegionDefs : AnatomicalRegion[] = Array.isArray(view.choices) ?
    (activeRegionIds
      .map(id => view.choices?.find(({value}) => value === id))
      .filter(x => x !== undefined))
    : []
  
  /**
   * This is a more rigorous method of finding the active region defs that
   * DOES NOT rely on the assumption that all answers are within a single leaf
   * view. As such, it is overcomplicated for the current model, but may be
   * necessary later, and is kept around for debugging bad answers.
   */
  function findActiveRegionDefs_nonUniversal() : AnatomicalRegion[] {
    if (Array.isArray(answer?.values)) {
      return answer.values.map((v, i) => {
        const parentViewId = v.viewPath?.slice(-1)?.[0] ?? "NO VIEW PATH";
        if (parentViewId in model) {
          const parentView = model[parentViewId];
          if (Array.isArray(parentView.choices)) {
            const choice = parentView.choices.find(c => c.value === v.value);
            if (choice) {
              return choice;
            } else {
              console.error(`Answer ${v.value} was not found in view ${parentViewId}`);
            }
          } else {
            console.error(`Answer supposedly chosen from choiceless view ${parentViewId}`);
          }
        } else {
          console.error(`Could not find view ${parentViewId} (in answer#${i}'s viewPath) in the current model`);
        }
        return null;
      }).filter(x => x !== null);
    } else {
      return [];
    }
  }

  /**
   * Keep the highlighting inside of the SVG up-to-date with the React state.
   * (This runs every update!)
   * 
   * TODO: is there a reason it's useEffect-wrapped though, rather than just
   * being in the component body directly? certainly that seems unlikely
   */
  useEffect(() => {
    /** @type {SVGAElement | null} */
    const svgEl = svgRef.current;
    if (svgEl === null) {
      console.warn("empty ref");
      return;
    }
    if (HIGHLIGHT_BY_SVG_NODES) {
      if (SVG_SELECTION_TYPE == SVGRegionSelectorType.GroupDatasetMatchesRegionName) {
        document
          .querySelectorAll<HTMLElement & {dataset: {name: string}}>("#highlight_base [data-name]")
          .forEach((e, k, p) => {
            if (activeRegionIds.includes(e.dataset.name)) {
              console.log(`found ${e.dataset.region} region at ${k}`);
              e.classList.add("highlight-on");
            } else {
              e.classList.remove("highlight-on");
            }
          });
      } else if (SVG_SELECTION_TYPE == SVGRegionSelectorType.GroupIdMatchesRegionHighlightId) {
        const highlightSet = new Set(activeRegionDefs.filter(c => c.highlightRegion).map(c => snakeCase(c.highlightRegion, true)));
        console.warn({highlightSet});
        document
          .querySelectorAll("svg#highlight_base g[id]")
          .forEach((e, k, p) => {
            if (highlightSet.has(e.id)) {
              console.log(`found ${e.id} region at ${k}`);
              e.classList.add("highlight-on");
            } else {
              e.classList.remove("highlight-on");
            }
          });
      } else {
        console.error(`Unknown selection type: ${SVG_SELECTION_TYPE}`);
      }
    }
  });

  /**
   * Calculate the total height of the choices panel AKA "legend"
   */
  const totalPanelHeight = useMemo(() => {
    return ((view.choices?.length ?? 0) + 1) * panelItemHeight + (2 * paddingForShadowSpread);
  }, [view]);

  /**
   * Determine which regions are actually added and removed based on a toggle
   * action, and return the new list of regions as well as a list of actions
   * taken.
   * @param regionName The region to toggle
   */
  function getRegionActionsForToggle (regionName: string): {
    newActiveRegions: string[],
    actionsTaken: string[]
  } {
    let newActiveRegions = activeRegionIds.slice();
    let actionsTaken: string[] = [];

    if (regionName === SELECT_ALL_SIGNAL) {
      // check if all are on!
      const selectable = view.choices.filter(c => c.value !== undefined);
      if (activeRegionIds.length === selectable.length) {
        newActiveRegions = [NO_ANSWER_SIGNAL];
        actionsTaken = ["deselect all"];
      } else {
        newActiveRegions = selectable.map(r => r.value as string);
        actionsTaken = ["select all"];
      }
    } else if (regionName === UNSURE_SIGNAL) {
      newActiveRegions = [UNSURE_SIGNAL];
      actionsTaken = ["became unsure"];
    } else {
      const index = activeRegionIds.indexOf(regionName)
      if (index > -1) {
        newActiveRegions.splice(index, 1);
        if (newActiveRegions.length === 0) {
          newActiveRegions.push(NO_ANSWER_SIGNAL);
        }
        actionsTaken = [`remove ${regionName}`];
      } else {
        if (newActiveRegions.length === 1 && (newActiveRegions[0] === NO_ANSWER_SIGNAL || newActiveRegions[0] === UNSURE_SIGNAL)) {
          newActiveRegions = [];
        }
        newActiveRegions.push(regionName);
        actionsTaken = [`add ${regionName}`];
      }
    }

    return ({newActiveRegions, actionsTaken});
  }

  /**
   * Whether all answers in the current (LEAF) view are selected. Used for
   * displaying the highlight status of the "select all" button.
   */
  const allSelected = view.isLeaf &&
    view.choices.every(choice => choice.value === undefined || activeRegionIds.includes(choice.value));

  /**
   * Toggle the inclusion of a region, including any additional region state
   * changes due to their exclusionary rules.
   * @param regionName The formal unique name of the region
   * @param representsCurrentView Whether the region is the current view-overall
   */
  function toggleChoiceRegion (regionName: string) {
    const {newActiveRegions, actionsTaken} = getRegionActionsForToggle(regionName);
    updateAnswer({
      // id: questionDefinition.id,
      isMulti: true,
      values: newActiveRegions.map((r) => ({ value: r, viewPath: viewPath.slice()})),
    });
    setLastClick({
      event: "DIRECT_ANSWER_TOGGLE",
      regions: [{id: regionName, value: regionName, weight: 1}],
      actionsTaken,
    });
  }

  /**
   * Choose a subview from the current selection.
   */
  function selectSubView (viewName: string) {
    const newViewPath = viewPath.slice(0, viewIndex + 1);
    newViewPath[viewIndex + 1] = viewName;
    updateAnswer({
      // id: questionDefinition.id,
      isMulti: true,
      values: [{value: NO_ANSWER_SIGNAL, viewPath: newViewPath}]
    });
  }

  type ImageClickEvent = MouseEvent & {target: SVGImageElement | SVGSVGElement};
    /**
   * Attempt to toggle all regions matching the current click. This follows
   * roughly the same logic as 
   * @param event 
   * @returns 
   */
  function toggleMatchingImageRegion(event : ImageClickEvent) {
    if (!svgRef.current) {
      console.error(`SVG reference not available, ignoring click`);
      return;
    };
    if (!Array.isArray(view.choices)) {
      console.warn("Current view has no choices, ignoring click");
      return;
    }
    const targetBox = event.target.getBoundingClientRect();
    const outerSVGBox = svgRef.current.getBoundingClientRect();
    const relativeX =
      event.clientX -
      (POINTS_ZEROED_TO_SVG_ROOT ? outerSVGBox.left : targetBox.left);
    const relativeY =
      event.clientY -
      (POINTS_ZEROED_TO_SVG_ROOT ? outerSVGBox.top : targetBox.top);
    console.log({ relativeX, relativeY });

    // HIGHLIGHT_BY_SVG_NODES ? getAncestorRegionData(event.target) :
    const clickedRegions = view.choices
      .map((r) => ({
        id: r.value,
        value: r.value,
        weight: clickIsInside(
          r,
          relativeX,
          relativeY,
        ),
      }))
      .filter((r) : r is ClickedRegion => !!r.weight);

    let actionsTaken: string[] = [];
    let newActiveRegions: string[] = [];
    if (clickedRegions.length > 0) {
      const target = applyResolutionMethod(clickedRegions);
      if (target === null) {
        console.info(`No region found for ${relativeX}, ${relativeY}`);
      } else {
        ({actionsTaken, newActiveRegions} = getRegionActionsForToggle(target.id));
        updateAnswer({
          // id: questionDefinition.id,
          isMulti: true,
          values: newActiveRegions.map((r) => ({ value: r, viewPath: viewPath.slice()})),
        });
      }
    }
    console.warn({ actionsTaken });
    setLastClick({
      ...event,
      relativeX,
      relativeY,
      ratioX: relativeX / targetBox.width,
      ratioY: relativeY / targetBox.height,
      regions: clickedRegions,
      actionsTaken,
    });
  }

  /**
   * Navigate forward 1 view
   */
  function goToNextView(): void {
    if (viewIndex < viewPath.length - 1) {
      setViewIndex(viewIndex + 1)
    } else {
      // if here there weren't any more pages!
      complete();
    }
  }

  /**
   * Navigate back 1 view
   */
  function goToPreviousView(): void {
    if (viewIndex > 0) {
      setViewIndex(viewIndex - 1)
    } else {
      // if here there weren't any more pages!
      goBack();
    }
  }

  function classesForChoiceCard (choice: AnatomicalRegion, asView: boolean) : string {
    const disabledClass = choice.disabled ? "card-dark" : "";
    const selectedClass = (asView ? viewPath.includes(choice.viewId ?? "") : activeRegionIds.includes(choice.value ?? "")) ? "selected" : "";
    const audioPlayingClass = (activeAudio.playing && activeAudio.filename === getAudioLocationFromId(choice.labelTranslationId ?? "").filename) ? "pulsing" : "";
    // choice.viewId && answer?.values?.some((v) => v.ancestors?.includes(choice.viewId)) ? "has-active-descendant" : "";
    return ["card", disabledClass, selectedClass, audioPlayingClass].join(" ");
  }

  return (
    <div style={{}}>
      <div
        className="main-column"
        style={{ margin: "-10px auto 0px auto", paddingTop: 0 }}
      >
        {showTrackingInfo ? <table>
            <tbody>
              <tr>
                <th rowSpan={3}>View</th>
                <th><em>path</em></th>
                <td>
                  [{viewPath.map(vp => 
                    <span
                      className="badge"
                      style={{border: "1px solid goldenrod", borderRadius: 12}}>
                      {vp}
                    </span>
                  )}]
                </td>
              </tr>
              <tr>
                <th><em>index</em></th>
                <td>{viewIndex}</td>
              </tr>
              <tr>
                <th><em>id</em></th>
                <td>{viewId}</td>
              </tr>
              {(!answer.values?.length) ? <tr>
                <th>Answers</th>
                <th><em>raw</em></th>
                <td><JSObjectDump obj={answer}/></td>
              </tr> : <>
              <tr>
                <th rowSpan={answer.values?.length + 1}>Answers</th>
              </tr>
              {answer.values.map((a, i) => <tr>
                <th>{i}</th>
                <td><JSObjectDump obj={a}/></td>
              </tr>)}</>}
            </tbody>
          </table>
          : null}
        
        <div
          className="m-1"
          style={{
            position: "relative",
            width: "100%",
            height: `${Math.max(totalHeight, (view.choices?.length ?? 1) * 50)}px`,
            border: "",
          }}
        >
          <svg
            ref={svgRef}
            width={totalWidth}
            height={Math.max(totalHeight, (view.choices?.length ?? 1) * 50)}
            style={{
              position: "absolute",
              left: "0px",
              top: "0px",
              background: "transparent",  /* "rgba(100, 150, 250, 0.2)" */
            }}
          >
            <g id="highlight_base">
              {view.imgUrl ? (
                USE_DYNAMIC_SVG_EMBED ?
                <DynamicSVG
                  prefix={"Questionnaire/Responses/AnatomicalModel/"}
                  src={view.imgUrl}
                  id="highlight_base"
                  width={totalWidth}
                  height={totalHeight}
                  onClick={toggleMatchingImageRegion}
                  style={{pointerEvents: "all"}}
                /> 
                :
                <image
                  id="highlight_base"
                  href={view.imgUrl}
                  width={totalWidth}
                  height={totalHeight}
                  onClick={toggleMatchingImageRegion}
                  pointerEvents={"all"}
                />
              ) : (
                /* Draw an error box */
                <g>
                  <rect
                    x="0"
                    y="0"
                    width={totalWidth}
                    height={totalHeight}
                    fill="lavender"
                  />
                  <text
                    x={rightPanelOffset / 2}
                    y="30"
                    textAnchor="middle"
                    fill="red"
                  >
                    {view?.notFound
                      ? `View '${viewId}' not found!`
                      : `View '${viewId}' has no imgUrl!`}
                  </text>
                </g>
              )}
            </g>
            <g id="responses" transform={`translate(${rightPanelOffset} ,0)`}>
              <foreignObject
                width={totalWidth - rightPanelOffset}
                height={totalPanelHeight}
              >
                <div
                  className={"anatomical-model-legend" + (USE_SEMITRANSPARENT_CHOICES && hasDecidedChoice ? " see-through-unselected" : "")}
                  style={{ maxHeight: "", overflowY: "initial", padding: paddingForShadowSpread }}
                >
                  {view.isLeaf ?
                    <>
                      {view.choices.map((c, ci) => (
                        <div
                          className={classesForChoiceCard(c, false)}
                          key={c.value ?? `unvalued ${ci}`}
                          id={"LEAF:" + (c.value ?? `unvalued ${ci}`)}
                        >
                          <div
                            className={`card-body`}
                            onClick={() => toggleChoiceRegion(c.value ?? `unvalued ${ci}`)}
                          >
                            {c.label || c.value}
                          </div>
                        </div>
                      ))}
                      <div
                        className={`card ${allSelected ? "selected": ""}`}
                      >
                          <div
                            className={`card-body`}
                            onClick={() => toggleChoiceRegion(SELECT_ALL_SIGNAL)}
                          >
                            All of the Above
                        </div>
                      </div>
                    </>
                  :
                    view.choices.map((c, ci) => (
                      <div
                        className={classesForChoiceCard(c, true)}
                        key={c.viewId ?? `unviewided ${ci}`}
                        id={"nonleaf"+ (c.viewId ?? `unviewided ${ci}`)}
                      >
                        <div
                          className={`card-body`}
                          onClick={() => selectSubView(c.viewId ?? `unviewided ${ci}`)}
                        >
                          {c.label || c.value}
                        </div>
                      </div>
                    ))
                  }
                </div>
              </foreignObject>
            </g>
            <g
              id="advance"
              transform={`translate(${totalWidth - 100}, ${Math.max(
                totalHeight - 100,
                totalPanelHeight
              )})`}
            >
              <foreignObject width="100" height="100">
                <div
                  className={`chrome-btn chrome-btn-lg centered-btn float-bottom-right`}
                  /* "1px solid rgba(0, 0, 0, 0.1)" */
                  style={{
                    pointerEvents: "fill",
                    transition: "opacity 1s linear, bottom 1s linear",
                    // opacity: nextButtonSettings.visible ? 1 : 0,
                  }}
                  onClick={() => goToNextView()}
                  // onMouseDown={waitForLongPress}
                  // onMouseUp={cancelLongPress}
                  // onContextMenu={handleMobileContextMenu}
                >
                  <img
                    src="/images/patient_redesign/arrow-right.svg"
                    style={{ filter: "invert()" }}
                  />
                </div>
              </foreignObject>
            </g>
            <g
              id="retreat"
              transform={`translate(0, -10)`}
            >
              <foreignObject width="100" height="100">
                <div
                  className={`chrome-btn chrome-btn-lg centered-btn`}
                  style={{ margin: "20px" }}
                  onClick={() => goToPreviousView()}
                  // onMouseDown={waitForLongPress}
                  // onMouseUp={cancelLongPress}
                  // onContextMenu={handleMobileContextMenu}
                >
                  <img src="/images/patient_redesign/chevron.svg" />
                </div>
              </foreignObject>
            </g>

            {view.isLeaf ? <g transform={`translate(0, ${Math.max(
                totalHeight - 50,
                totalPanelHeight
              )})`}>
                <foreignObject width="250" height="50" onClick={() => toggleChoiceRegion(UNSURE_SIGNAL)}>
                  <UncertainButton label="Unsure" selected={activeRegionIds.includes(UNSURE_SIGNAL)}/>
                </foreignObject>
            </g> : null}

            <g>
              {view.choices?.map((r, i) =>
               (r.lineAnchor || r.shape) ?
                  <LinkLineOf
                    region={r}
                    index={i}
                    key={r.value}
                    semitransparent={view.isLeaf ? (!activeRegionIds.includes(r.value)) : (viewPath[viewIndex + 1] !== r.viewId)} />
                : null
              )}
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
}

export default AnatomicalModelResponse;
