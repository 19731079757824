import {
  createEntityAdapter,
  EntityState
} from "@reduxjs/toolkit";
import { nanoid } from "nanoid";
import { firestoreCollections } from "../../database/collections";
import {
  AnswerSet,
  AnswerSetSubmissionStatus
} from "../../models/answers";
import { BaseUser } from "../../models/users";
import {
  initialLoadStatus,
  LoadState,
  unwrapQuerySnapshotForThunk,
  WithLoadStatus,
} from "../../utils/database";
import { createSliceWithThunks } from "../../utils/redux";
import { PatientFlowState } from "./patient-flow";

const answerSetAdapter = createEntityAdapter<AnswerSet, string>({
  selectId: (a: AnswerSet) => a.id ?? a.userId + "_" + nanoid(6),
});

export interface AnswersState {
  currentId: string;
  // updateNumber: number;
  // current: { [key: string]: Answer<any> };
  allSets: EntityState<AnswerSet, string> & WithLoadStatus;
  error: any;
}
const initialState: AnswersState = {
  currentId: nanoid(),
  // updateNumber: 0,
  // current: {},
  allSets: answerSetAdapter.getInitialState(initialLoadStatus()),
  error: undefined,
};

function buildCurrentAnswerSet(
  patientFlowState: PatientFlowState,
  user: BaseUser,
  submissionType: AnswerSetSubmissionStatus
): AnswerSet {
  return {
    submitted: Date.now(),
    userId: user.id,
    organizationId: user.organizationId,
    sessionIds: [],
    answers: patientFlowState.answers.entities,
    questionnaire: patientFlowState.questionnaireType,
    language: patientFlowState.language,
    submissionType,
  };
}

export const answerSetSlice = createSliceWithThunks({
  name: "answerSets",
  initialState,
  reducers: (create) => ({
    // THIS IS HANDLED BY PATIENT-FLOW!
    // updateCurrentAnswer: create.reducer((state, action: PayloadAction<Answer<any>>) => {
    //   state.updateNumber += 1;
    //   state.current[action.payload.key] = action.payload;
    // }),

    // startNewAnswerSet: create.reducer((state, action: PayloadAction<void>) => {
    //   state.updateNumber += 1;
    //   // state.current = {};
    //   state.currentId = nanoid();
    // }),

    saveCurrentAnswerSet: create.asyncThunk(
      async (submissionType: AnswerSetSubmissionStatus, thunkApi) => {
        const rootState = thunkApi.getState() as any; // typing this as RootState gives a circular dependency... I'm so confusedddd
        const answerSet = buildCurrentAnswerSet(
          rootState.patientFlow,
          rootState.auth.user,
          submissionType
        );
        if (rootState.metrics.sessionId) {
          answerSet.sessionIds = [rootState.metrics.sessionId];
        }
        console.warn(`Submitting answer set:`);
        console.warn(answerSet);
        const firebaseResponse = await firestoreCollections.answerSets
          .doc(rootState.answerSets.currentId)
          .set(answerSet);
        return { firebaseResponse, answerSet };
      },
      {
        fulfilled: (state, action) => {
          answerSetAdapter.upsertOne(state.allSets, action.payload.answerSet);
        }
      }
    ),

    loadDashboardAnswerSets: create.asyncThunk(
      async (organizationId: string, thunkApi) => {
        const rootState = thunkApi.getState() as any;
        const firebaseResponse = await firestoreCollections.answerSets
          .where("organizationId", "==", organizationId)
          .get();
        return unwrapQuerySnapshotForThunk(firebaseResponse);
      },
      // An object containing `{pending?, rejected?, fulfilled?, settled?, options?}` second
      {
        pending: (state) => {
          // state.loading = true
          state.allSets.loadState = LoadState.Loading;
        },
        rejected: (state, action) => {
          // state.error = action.payload ?? action.error
          state.allSets.loadState = LoadState.Failed_Unspecified;
        },
        fulfilled: (state, action) => {
          answerSetAdapter.upsertMany(state.allSets, action.payload);
          state.allSets.loadState = LoadState.Complete;
          state.allSets.loadTime = Date.now();
          state.allSets.ready = true;
        },
        // settled is called for both rejected and fulfilled actions
        settled: (state, action) => {
          // state.loading = false
        },
      }
    ),
  }),
  extraReducers(builder) {
    builder.addCase("actiontype", (state, action) => {});
  },
});
