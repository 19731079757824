import { useEffect, useMemo, useRef, useState } from "react";
import {
  EnglishNames,
  Language,
  LanguageEndonym,
  LanguageISO,
  SelectorAssets,
} from "../../../constants/locales";
import { useDispatch } from "react-redux";
import { audioSlice, playKnownAudio, registerLocalAudio } from "../../../store/slices/audio";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { defaultInstructionalAudioForType, getLanguageEntryAudioFilename, idToLocalFile } from "../../../models/audio";
import { Card } from "../../UI/Card/Card";
import { patientFlowSlice } from "../../../store/slices/patient-flow";
import { opacity } from "html2canvas/dist/types/css/property-descriptors/opacity";

const SupportedLanguages = [
  Language.English,
  Language.Spanish,
  Language.Arabic,
  Language.Swahili,
  Language.Burmese,
  Language.Kinyarwanda,
  Language.Somali,
];

const disabledStyle : React.CSSProperties = {
  opacity: 0.4,
  filter: "saturation(0.4)",
  pointerEvents: "none"
}

export const LanguageSelectorPage = ({
  listedLanguages = SupportedLanguages,
  disabledLanguages = [],
  useScrolling = true
}: {
  listedLanguages: LanguageISO[],
  disabledLanguages: LanguageISO[],
  useScrolling: boolean
}) => {
  const dispatch = useAppDispatch();
  const activeAudio = useAppSelector(s => s.audio.activeAudio);
  // const [audioToLangHighlight, setAudioToLangHighlight] =
  //   useState<{[file: string]: LanguageISO}>({});

  const promptAudioFilename = useMemo(() => {
    const promptTranslationId = defaultInstructionalAudioForType('!! language selector');
    return (promptTranslationId ? idToLocalFile(promptTranslationId, true) : undefined);
  }, []);
  // NOTE the language selector always ignores the current autoplay setting!
  useEffect(() => {
    const files: string[] = [];
    if (promptAudioFilename != undefined) {
      files.push(promptAudioFilename);
    }
    // const reverseMap: {[file: string]: LanguageISO} = {};
    // The audio files for the language are local
    SupportedLanguages.forEach((l) => {
      if (l in SelectorAssets && SelectorAssets[l].narrationFile) {
        const url = getLanguageEntryAudioFilename(l);
        files.push(url);
        // reverseMap[url] = l;
        // console.log(`${l} - ${url}`);
      }
    });
    dispatch(registerLocalAudio(files));
    dispatch(audioSlice.actions.playSequence({id: "language-selector", files, loop: true, gap: 3000}));
  }, []);

  const chosenLanguage = useAppSelector(s => s.patientFlow.language);

  function pickLanguage(l: LanguageISO) {
    dispatch(patientFlowSlice.actions.selectLanguage(l));
    dispatch(audioSlice.actions.stopAudio());
  }

  return (
    <div
      className="centered-flex-container main-columm pad-for-header"
      style={{ minHeight: "100vh", justifyContent: "center" }}
    >
      <h3 className={`question ${(activeAudio.playing && activeAudio.filename === promptAudioFilename ? 'pulsing' : '')}`}>What is your preferred language?</h3>
      <div className="centered-flex-container main-column">
          <div className="stack-of-cards">
          {listedLanguages.map((l) => (
            <Card
              isSelected={chosenLanguage === l}
              style={disabledLanguages.includes(l) ? disabledStyle : {}}
              onClick={() => pickLanguage(l)}
              stack={true}
              // art={null}
              useGlyphForArt={SelectorAssets[l]?.flags?.slice?.(0, 4) ?? undefined}
              title={LanguageEndonym[l]}
              description={l === Language.English ? null : EnglishNames[l]}
              audioHighlightKeys={[getLanguageEntryAudioFilename(l)]}
              scrollOnAudio={useScrolling}
              />
          ))}
        </div>
      </div>
    </div>
  );
};

/*

<div className="rect-img-card-container">
        {availableLanguages.map((l) => (
          <div
            className={`rect-img-card ${
              fakeLanguage === l ? "selected" : ""
            } `}
            key={l}
            onClick={() => pickLanguage(l)}
            style={{}}
          >
            <div className="image-wrapper">
              <span className="flag-as-image">
                {SelectorAssets[l]?.flags?.slice?.(0, 4)}
              </span>
            </div>
            {l in SelectorAssets ? (
              <div
                className="text-wrapper"
                style={{ justifyContent: "center" }}
              >
                <p className="with-subtext">{LanguageEndonym[l]}</p>
                {l === Language.English ? null : (
                  <p className="choice-subtext">{EnglishNames[l]}</p>
                )}
              </div>
            ) : (
              <p className="text-warning">Unknown language: {l}</p>
            )}
            <div className="icon-wrapper">
              <img src="/images/patient_redesign/speaker.svg" />
            </div>
          </div>
        ))}
      </div>

      */