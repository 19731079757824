import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { dualName, Language } from "../../constants/locales";
import {
  generateQuestionnaireKind,
  ScreeningType,
} from "../../constants/screenings";
import { AnswerSet } from "../../models/answers";
import { UserType } from "../../models/users";
import { answerSetSlice } from "../../store/slices/answerSets";
import { authSlice } from "../../store/slices/auth";
import { requestQuestionnaire } from "../../store/slices/questionnaire-old";
import { isObject, oneline, safeStringify } from "../../utils";
import { isProcessing, LoadState } from "../../utils/database";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { RedesignedReport } from "../Questionnaire/Pages/RedesignedReport";
import { CTA } from "../UI/buttons/CTA";
import { metricsSlice } from "../../store/slices/metrics";
import { MetricsEvent, MetricsEventData, MetricsSession, PageEventData } from "../../models/metrics";
import { useSelector } from "react-redux";
import { JSObjectDump } from "../../utils/UtilComponents";

function summarizingData(
  answerSet: AnswerSet,
  field: "age" | "sex" | "chief complaint" | "report ID"
) {
  let result: any;
  if (field === "age") {
    result = "--";
  }
  if (field === "sex") {
    result = answerSet.answers?.["sex assigned at birth"]?.value ?? "Unknown";
  }
  if (field === "chief complaint") {
    result = answerSet.answers?.["chief complaint"]?.value ?? "Unknown";
  }
  if (field === "report ID") {
    result = answerSet.answers?.["_reportID"]?.value ?? "--";
  }
  // something is wrong with our value atom setup
  if (isObject(result, true)) {
    if ("value" in result) {
      return result.value;
    } else {
      return safeStringify(result);
    }
  } else {
    return result;
  }
}

export const ProviderDashboard = ({
  simulation,
}: {
  simulation?: {
    kioskAction: () => void;
    answerSets: AnswerSet[];
  };
}) => {
  const loadedAnswerSets = useAppSelector((s) => s.answerSets.allSets);
  const tableData = useMemo(() => {
    if (simulation) {
      return simulation.answerSets;
    }
    return loadedAnswerSets.ids.map((i) => loadedAnswerSets.entities[i]);
  }, [simulation, loadedAnswerSets])
  const [sortedTableData, setSortedTableData] = useState(tableData);
  useEffect(() => {
    // preserve sort would be nice
    setSortedTableData(tableData);
  }, [tableData]);
  const user = useAppSelector((s) => s.auth.user);
  const dispatch = useAppDispatch();

  const hardcodedQType = generateQuestionnaireKind({
    type: ScreeningType.ED,
    locale: { language: Language.English },
  });
  // const loadedQuestionnaireIDs = useAppSelector((s) => s.definitions.questionnaires.ids);
  const loadedQuestionnaires = useAppSelector(
    (s) => s.questionnaire.questionnaires
  );
  const qTypeIsReady = loadedQuestionnaires.ids.includes(hardcodedQType);
  const [displayReport, setDisplayReport] = useState<AnswerSet | null>(null);

  function showReport(id: string | undefined) {
    if (!id) {
      alert(`Report has invalid ID!`);
      return;
    }
    const set = tableData.find((as) => as.id === id);
    if (!set) {
      alert(`Could not find id ${id} in table!`);
      return;
    }
    if (!qTypeIsReady) {
      dispatch(
        requestQuestionnaire({
          type: ScreeningType.ED,
          locale: { language: Language.English },
        })
      );
    }
    dispatch(metricsSlice.actions.recordEvent({type: MetricsEvent.ViewDashboardReport}));
    setDisplayReport(set);
  }

  const [displayMetrics, setDisplayMetrics] = useState<string | null>(null);
  const metricsSession = useAppSelector(s => s.metrics.sessions[displayMetrics ?? ""]);
  function showMetrics () {
    if (displayReport === null) return;
    if (displayReport.sessionIds.length < 1) {
      alert(`Report has no available metrics session!`);
      return
    } else if (displayReport.sessionIds.length > 1) {
      alert(`Report has multiple sessions (should not yet be enabled), displaying first...`);
    }
    const id = displayReport.sessionIds[0];
    dispatch(metricsSlice.actions.loadMetricsSession(id));
    setDisplayMetrics(id);
  }

  useEffect(() => {
    if (simulation) return;
    if (loadedAnswerSets.loadState === LoadState.Unstarted) {
      if (
        user.type !== UserType.Provider &&
        user.type !== UserType.Superadmin
      ) {
        console.warn("User is wrong type for dashboard!");
      } else {
        dispatch(
          answerSetSlice.actions.loadDashboardAnswerSets(user.organizationId)
        );
      }
    }
  }, [loadedAnswerSets, user]);

  const [sortedColumn, setSortedColumn] = useState<number>(-1);
  const [sortAscending, setSortAscending] = useState<boolean>(true);
  function sortTable(accessor: (as: AnswerSet) => any, index: number, numeric = false) {
    let ascending = true;
    if (index === sortedColumn) {
      ascending = !sortAscending;
      setSortAscending(ascending);
    } else {
      setSortedColumn(index);
      setSortAscending(true);
    }
    let newTable = tableData.slice();
    const inverter = ascending ? 1 : -1;
    if (numeric) {
      newTable.sort((a, b) => (accessor(a) - accessor(b)) * inverter);
    } else {
      newTable.sort((a, b) => (`${accessor(a)}`.localeCompare(`${accessor(b)}`)) * inverter);
    }
    setSortedTableData(newTable);
  }

  function sortIcon (index: number) {
    return null;
    if (index === sortedColumn) {
      if (sortAscending) {
        return <span className="sort-icon" style={{marginRight: "1em"}}>⬆️</span>;
      } else {
        return <span className="sort-icon" style={{marginRight: "1em"}}>⬇️</span>;
      }
    } else {
      return ' ';
    }
  }

  async function copyToClipboard(text: string) {
    await navigator.clipboard.writeText(text);
    alert(`Copied "${text}" to your clipboard!`);
  }

  function startKioskFlow() {
    const confirmed = window.confirm(
      oneline`You are about to enter kiosk mode, where the app will can only be used to collect patient data. To exit this mode, you will need to log out and log back in. Do you wish to continue?`
    );
    if (confirmed) {
      if (simulation) {
        simulation.kioskAction();
      } else {
        if (
          user.organization?.kioskUsers &&
          user.organization.kioskUsers.length > 0
        ) {
          // In the future when we hve multiple kiosks defined per organization,
          // we would need to have selected one using a UI prior to this call.
          const kioskUserId = user.organization.kioskUsers[0];
          dispatch(
            authSlice.actions.startKioskMode({
              kioskUserId,
              organization: user.organization,
            })
          );
        } else {
          console.error(`Organization has no available kiosk users!`);
        }
      }
    }
  }

  function metricsTimeSince (session: MetricsSession, e: MetricsEventData) {
    const ms = e.timestamp - session.initialTimestamp;
    return `${Math.floor(ms/100)/10}s`;
  }

  return (
    <div
      className="centered-flex-container"
      style={{ minHeight: "100vh", justifyContent: "center" }}
    >
      {displayReport ? (
        qTypeIsReady ? (
          (displayMetrics ? 
            <div
              className="raised-box"
              style={{ maxWidth: "1000px", margin: "3em auto" }}
            >
              <CTA style={{width: 400, backgroundColor: "var(--salmon-red)"}} label="Back to Report" onClick={() => setDisplayMetrics(null)}/>
                {metricsSession ? 
                  <div>
                    <h3>{metricsSession.sessionId}</h3>
                    <h5>{moment(metricsSession.initialTimestamp).format()}</h5>
                    <ol>
                      {metricsSession.events.map(e => <li>
                        ({metricsTimeSince(metricsSession, e)}) 
                        {` ${e.type} `}
                        {Number.isSafeInteger((e as PageEventData).fromPage) ? <JSObjectDump obj={e}/> : null}
                      </li>)}
                    </ol>
                  </div>
                : <strong>Loading metrics...</strong>}
            </div>
          :
          <div
          className="raised-box"
          style={{ maxWidth: "1000px", margin: "3em auto" }}
        >
          <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
          <CTA style={{width: 400}} label="Back to Dashboard" onClick={() => setDisplayReport(null)}/>
          {user.type === UserType.Superadmin ? <CTA style={{width: 400, backgroundColor: "var(--salmon-red)"}} label="View Metrics" onClick={() => showMetrics()}/> : null}
            </div>
          <RedesignedReport
            user={{name: "ED Kiosk"}}
            questionnaireDefinition={loadedQuestionnaires.entities[hardcodedQType]}
            answers={displayReport.answers}
            submissionStatus={displayReport.submissionType}
            language={displayReport.language}
            acceptAnswer={null}
          />
          </div>
          )
        ) : (
          <strong>Loading structure...</strong>
        )
      ) : (
        <div
          className="raised-box"
          style={{ maxWidth: "1000px", margin: "3em auto" }}
        >
          <div className="row">
            <div id="left-text" className="col">
              <p>
                <strong>To review a report,</strong> select a patient from the
                table.
              </p>
              <p>
                <strong>To start a new patient flow, </strong>click the button
                on the right.
              </p>
              <p>
                <strong>For privacy, </strong> log in required to return to this
                page.
              </p>
            </div>
            <div
              style={{ alignSelf: "center", textAlign: "center" }}
              className="col"
            >
              <CTA
                light={false}
                label="Launch Kiosk Mode"
                style={{ minWidth: "300px", fontSize: "18px" }}
                onClick={startKioskFlow}
                disabled={!user.organization?.allowsKioskMode}
              />
            </div>
          </div>
          <h2 className="text-centered">Patient Reports</h2>
          <div className="overflow-x">
            <table className="dashboard-table">
              <thead>
                <tr>
                  {/* <th
                    onClick={() =>
                      sortTable((a) => summarizingData(a, "age"), true)
                    }
                  >
                    Age
                  </th> */}
                  <th
                    onClick={() => sortTable((a) => summarizingData(a, "sex"), 0)}
                  >
                    {sortIcon(0)}
                    Sex
                  </th>
                  <th
                    onClick={() =>
                      sortTable((a) => summarizingData(a, "chief complaint"), 1)
                    }
                  >
                    {sortIcon(1)}
                    Chief Complaint
                  </th>
                  <th
                    colSpan={2}
                    onClick={() => sortTable((a) => a.submitted, 2, true)}
                  >
                    {sortIcon(2)}
                    Time Started
                  </th>
                  <th
                    onClick={() => sortTable((a) => a.language, 3)}>
                    {sortIcon(3)}
                    Language
                  </th>
                  <th onClick={() => sortTable((a) => summarizingData(a, "report ID"), 4)}>
                    {sortIcon(4)}
                    Report ID
                  </th>
                  <th onClick={() => sortTable((a) => a.submissionType, 5, true)}>
                    {sortIcon(5)}
                    Status
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {sortedTableData.length === 0 ? (
                  <tr>
                    <th
                      colSpan={8}
                      style={{ textAlign: "center", padding: "1.5em" }}
                    >
                      {isProcessing(loadedAnswerSets)
                        ? "Loading reports"
                        : "No reports available"}
                    </th>
                  </tr>
                ) : null}
                {sortedTableData.map((row) => (
                  <tr key={row.id ?? row.submitted}>
                    {/* <td className="text-center">
                      {summarizingData(row, "age")}
                    </td> */}
                    <td>{summarizingData(row, "sex")}</td>
                    <td
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "center",
                        maxWidth: "190px",
                      }}
                    >
                      {summarizingData(row, "chief complaint")}
                    </td>
                    <td>{moment(row.submitted).format("MMM D")}</td>
                    <td>{moment(row.submitted).format("h:mma")}</td>
                    <td>{dualName(row.language ?? Language.English)}</td>
                    {row.humanReadableId ? (
                      <th
                        className="monospace"
                        onClick={
                          row.humanReadableId
                            ? () => copyToClipboard(row.humanReadableId!!.id)
                            : undefined
                        }
                      >
                        {row.humanReadableId?.id}
                      </th>
                    ) : (
                      <th className="monospace">
                        {summarizingData(row, "report ID")}
                      </th>
                    )}
                    <td style={{fontSize: "0.7em", textAlign: "center"}}>{row.submissionType}</td>
                    <td>
                      <button className="" onClick={() => showReport(row.id)}>
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};
