import { createAsyncThunk, createEntityAdapter, createSlice, EntityAdapter, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { createSliceWithThunks } from "../../utils/redux";
import { Answer, DerivedAnswerHolder, GeneralAnswer } from "../../models/answers";
import { DisplayFormula } from "../../models/formula";
import { Page, PageType, QuestionPage } from "../../models/pages";
import { QuestionDefinition } from "../../models/questions";
import { reject } from "q";
import { evaluator } from "../../utils/evaluator";
import { Language, LanguageISO } from "../../constants/locales";
import { ScreeningType } from "../../constants/screenings";

const answersAdapter = createEntityAdapter<GeneralAnswer, string>({selectId: a => a.key});

export interface PatientFlowState {
  answers: EntityState<GeneralAnswer, string>;
  answerDependencyMap: Record<string, DisplayFormula[]>;
  questionnaireType: ScreeningType | 'unknown';
  pages: Page[],
  displayFlags: Record<string, boolean>;
  answerUpdateNumber: number;
  pageNumber: number;
  subpage: number[];
  language: LanguageISO;
}
const initialState : PatientFlowState = {
  answers: answersAdapter.getInitialState({}),
  answerDependencyMap: {},
  questionnaireType: 'unknown',
  pages: [{type: PageType.Loading}],
  displayFlags: {},
  answerUpdateNumber: 0,
  pageNumber: 0,
  subpage: [],
  language: Language.UNDETERMINED
};

const DISPLAYABLE_KEY_PREFIX_MAP = {
  page: "¶",
  question: "¿",
  ERROR: "€"
};
function displayableKeyFor (entityType: 'page' | 'question', entityKey: string | number) : string {
  return `${DISPLAYABLE_KEY_PREFIX_MAP[entityType] ?? DISPLAYABLE_KEY_PREFIX_MAP.ERROR}${entityKey}`;
}
function initializeSubpageNumberingFor(p : Page) : number[] {
  // TODO: if subpages are still needed (probably for question modals)
  return [];
}
function evaluateFormula (s: PatientFlowState, f: DisplayFormula): [string, boolean] {
  // TODO: call to evaluator here...
  // const result = evaluator();
  const result = Math.random() > 0.5;
  const displayableKey = displayableKeyFor(f.entity, f.questionKey ?? f.pageIndex ?? "«bad formula»");
  return [displayableKey, result];
}

export type AnswerUpdatePart = [string, QuestionDefinition | DerivedAnswerHolder, GeneralAnswer];

export const patientFlowSlice = createSliceWithThunks({
  name: 'patientFlow',
  initialState,
  reducers: (create) => ({
    selectLanguage: create.reducer((state, action: PayloadAction<LanguageISO>) => {
      state.language = action.payload;
   }),

    /**
     * Attempt to move forward to the next valid page
     */
    advancePage: create.reducer((state, action: PayloadAction<any>) => {
      if (state.pageNumber >= state.pages.length - 1) {
        console.warn(`Cannot advance, already at page ${state.pageNumber} of ${state.pages.length}`);
        return;
      }
      for (let i = state.pageNumber + 1; i < state.pages.length; i++) {
        const page = state.pages[i];
        if (state.displayFlags[displayableKeyFor('page', i)] === false) {
          // this page is not set to be displayed
          continue;
        }
        if (page.type === PageType.Question) {
          if ((page as QuestionPage).questions.every(q => state.displayFlags[displayableKeyFor('question', q)] === false)) {
            // all questions on the page are not to be displayed, so skip the
            // page overall
            continue;
          }
        }
        // accept this as the target page
        state.pageNumber = i;
        state.subpage = initializeSubpageNumberingFor(page);
      }
      console.log(`None of the remaining ${state.pages.length - state.pageNumber - 1} pages are currently active`);
    }),

    /**
     * Attempt to accept one or more new answers into the local cache, updating
     * any relevant display flags or followup formula as needed.
     */
    acceptAnswers: create.reducer((state, action: PayloadAction<Array<AnswerUpdatePart>>) => {
      state.answerUpdateNumber += 1;
      const accepted: Answer<any>[] = [];
      const rejected: Array<Answer<any> & {rejectReason: string}> = [];
      action.payload.forEach(([key, question, answer]: AnswerUpdatePart) => {
        if (!answer.key) {
          answer.key = key;
        }
        if ((answer as any).id === undefined) {
          delete (answer as any).id;
        }
        // step 1, verify keys match


        // step 2, verify the cardinality is as expected

        // step 3, verify the data type is correct

        // step 4, all seems good, add this to the list of updates to make
        accepted.push(answer);
      });

      // use the entity adapter to apply all the new answers
      answersAdapter.upsertMany(state.answers, accepted);
      if (rejected.length > 0) {
        const allReasons = Array.from(new Set(rejected.map(r => r.rejectReason)));
        console.error(`acceptAnswers had ${rejected.length} rejected values, for reasons ${allReasons.join(", ")}`)
      }

      // for all of the accepted answers, we find all of the formulas that rely
      // on them and re-run them, applying the new values to state
      const formulasForUpdate = accepted.map(a => state.answerDependencyMap[a.key] ?? []).flat(1);
      formulasForUpdate.forEach(f => {
        const [displayableKey, shouldDisplay] = evaluateFormula(state, f);
        state.displayFlags[displayableKey] = shouldDisplay;
      });
    }),

    clearAnswers: create.reducer((state, action: PayloadAction<string[]>) => {
      action.payload.forEach(key => {
        if (state.answers.ids.includes(key)) {
          const answer = state.answers.entities[key];
          if (answer.isMulti) {
            answer.values = [];
          } else {
            answer.value = {value: null};
          }
        }
      })
    }),

    clearAll: create.reducer((state, action: PayloadAction<{isEarly: boolean, reason: string}>) => {
      answersAdapter.removeAll(state.answers);
      // reset page number, etc...
    }),

  })
});

export const {advancePage} = patientFlowSlice.actions;