import { useEffect, useState } from "react";
import { QuestionnaireManager } from "./components/QuestionnaireManager";
import { GlobalAlerts } from "./components/UI-old/GlobalAlerts";
// import "./styles/common.scss";
import "./styles/redesign/admin-console.css";
import "./styles/redesign/bootstrap-highlighter.css";
import "./styles/redesign/button.css";
import "./styles/redesign/colors.css";
import "./styles/redesign/debugging.css";
import "./styles/redesign/flow.css";
import "./styles/redesign/typography.css";
import "./styles/redesign/utility.css";
import { PageType, RootPageFlow } from "./models/pages";
import { randHexPair } from "./utils";
import { LoginComponent } from "./components/Auth/Login";
import { useAppDispatch, useAppSelector, useKeyboardEvents } from "./utils/hooks";
import { UserType } from "./models/users";
import { ProviderDashboard } from "./components/Provider/Dashboard";
import { getQuestionnaireByTypeAndKind, requestQuestionnaire } from "./store/slices/questionnaire-old";
import { allows, generateQuestionnaireKind, ScreenerNames, ScreeningType } from "./constants/screenings";
import { Language, LanguageList } from "./constants/locales";
import { RedesignedQuestionnaire } from "./components/Questionnaire/QuestionnaireFlow";
import { patientFlowSlice } from "./store/slices/patient-flow";
import { metricsSlice, useTabActivity } from "./store/slices/metrics";
import { LanguageSelectorPage } from "./components/Questionnaire/Pages/LanguageSelector";
import { NavigationalOverlay } from "./components/Questionnaire/Partials/NavigationalOverlay";
import { SimulatorSettingsPanel } from "./components/SettingsPanel";

function randomDarkColor(): string {
  const a = randHexPair(0, 60);
  const b = randHexPair(0, 120);
  const c = randHexPair(80, 180);
  return (
    "#" +
    (Math.random() > 0.67
      ? a + b + c
      : Math.random() > 0.5
      ? b + c + a
      : c + a + b)
  );
}

const HARDCODED_BUTTON_STATE_FOR_LANG = {
  next: {
    visible: false,
    active: false
  },
  back: {
    visible: false,
    active: false,
    icon: "quit"
  },
  audio: {
    lockPlaying: true
  },
  callToAction: null
}

const App = (props: any) => {
  const dispatch = useAppDispatch();
  /**
   * Temporary page routing until we commit to full router, which may not be
   * before launch. (Most of our navigation is already handled by the
   * questionnaire state management, so adding another router might just be
   * unnecessary complexity until we architect it.)
   */
  const [currentFlow, setCurrentFlow] = useState<RootPageFlow>(
    RootPageFlow.Guest
  );

  useTabActivity();

  const currentUser = useAppSelector((s) => s.auth.user);
  const authLoading = useAppSelector((s) => s.auth.loading);

  // const loadedQuestionnaireIDs = useAppSelector((s) => s.definitions.questionnaires.ids);
  const loadedQuestionnaireIDs = useAppSelector((s) => s.questionnaire.questionnaires.ids);
  
  const chosenLanguage = useAppSelector(s => s.patientFlow.language);
  const qType = generateQuestionnaireKind({type: ScreeningType.ED, locale: { language: chosenLanguage }})
  const qTypeIsReady = loadedQuestionnaireIDs.includes(qType);
  function clearLanguage () {
    dispatch(patientFlowSlice.actions.selectLanguage(Language.UNDETERMINED));
  }

  useEffect(() => {
    if (currentFlow === RootPageFlow.Guest && !authLoading) {
      if (
        currentUser.type === UserType.Provider ||
        currentUser.type === UserType.Superadmin
      ) {
        setCurrentFlow(RootPageFlow.Provider);
      } else {
        // 
      }
    } else if (currentFlow === RootPageFlow.Provider && currentUser.type === UserType.PatientKiosk) {
      setCurrentFlow(RootPageFlow.PatientKiosk);
    }
  }, [currentUser, authLoading]);

  useEffect(() => {
    if (currentFlow === RootPageFlow.PatientKiosk) {
      if (!qTypeIsReady && chosenLanguage !== Language.UNDETERMINED) {
          dispatch(requestQuestionnaire({
            type: ScreeningType.ED,
            locale: { language: chosenLanguage },
          }));
      }
    }
  }, [currentFlow, qTypeIsReady, chosenLanguage])

  const [showSettingsPanel, setShowSettingsPanel] = useState<boolean>(false);
  useKeyboardEvents([
    "F4", (e: KeyboardEvent) => setShowSettingsPanel(x => !x)
  ])

  function quitKiosk () {
    console.log("quitting kiosk");
    // dispatch(patientFlowSlice.actions.clearAll());
    dispatch(patientFlowSlice.actions.selectLanguage(Language.UNDETERMINED));
    dispatch(metricsSlice.actions.startNewMetricsSession());
  }

  const s: React.CSSProperties = {
    margin: "4em auto",
    textAlign: "center",
    color: randomDarkColor(),
    fontWeight: "bold",
  };

  return (
    <>
      {currentFlow === RootPageFlow.Guest ? (
        <div
          className={`redesign page-login`}
          style={{ background: "var(--sky-blue)" }}
        >
          <LoginComponent />
        </div>
      ) : null}
      {currentFlow === RootPageFlow.Simulator ? <QuestionnaireManager /> : null}
      {currentFlow === RootPageFlow.Provider ? (
        <div
          className={`redesign page-login`}
          style={{ background: "var(--sky-blue)" }}
        >
          {showSettingsPanel ?
          <div
            className="centered-flex-container"
            style={{ minHeight: "100vh", justifyContent: "center" }}
            >
              <div
                className="raised-box"
                style={{ maxWidth: "1000px", margin: "3em auto" }}
                >
                  <h2>Debug Settings</h2>
                  <h4 style={{opacity: 0.6}}>Press F4 again to return</h4>
                <SimulatorSettingsPanel showLoadOptions={false} />
              </div>
            </div>
            :
            <ProviderDashboard/>
          }
        </div>
      ) : null}
      {currentFlow === RootPageFlow.PatientKiosk ? (
        (chosenLanguage === Language.UNDETERMINED ?
          <div
            className={`redesign page-${PageType.LanguageSelector} has-backdrop`}
          >
            <NavigationalOverlay
              page={{}}
              mode={"backdrop"}
              artOverride={"/images/AMA_md.png"}
              activeQuestion={undefined}
              activeAnswer={null}
              buttonState={HARDCODED_BUTTON_STATE_FOR_LANG}
              hideFade={false}
              goBackExit={() => {}}
              goForward={() => {}}
              longPress={() => {}}
              useNeutralColor={false}
            />
            {/* disabledLanguages={LanguageList.filter(l => !allows(ScreeningType.ED, l))} */}
            <LanguageSelectorPage disabledLanguages={LanguageList.filter(l => l !== Language.English && l !== Language.Arabic)} />
          </div>
          :
          (!qTypeIsReady ?
            <div style={s}>kiosk is preparing...
            <br/>
            <em style={{opacity: 0.5}} onClick={clearLanguage}>(unselect language)</em>
            </div>
            :
            <RedesignedQuestionnaire
              reportType={ScreeningType.ED}
              language={chosenLanguage}
              quitFn={quitKiosk}
          />
          )
        )
      ) : null}
      {/* Components universal across flows go here: */}
      <GlobalAlerts />
    </>
  );
};

export default App;
