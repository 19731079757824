import { assignOnly, spliceOutOf } from "../utils";
import { Answer } from "./answers";
import { IndexedString, ValueAtom } from "./core-data-types";
import { NonconformingResponseChoice, QuestionDefinition, ResponseChoice } from "./questions";



/**
 * Function to evaluate if a specific choice should be displayed as selected,
 * based on whether that choice is already a part of the answers.
 **/
export function isSelected (choiceIndex : number, questionDefinition : QuestionDefinition, answer : Answer<IndexedString>) {
  if (answer.isExclusionary) return false;
  if (questionDefinition.isMulti) {
    return !!answer?.values?.find(value => value.choiceIndex === choiceIndex);
  } else {
    return answer?.value?.choiceIndex === choiceIndex;
  }
}

export function toggleChoice (item: ResponseChoice, isCurrentlySelected: boolean, choices: ResponseChoice[], isMulti : boolean, answer: Answer<any>, additionalProps = {}) {
  const choiceIndex = choices.findIndex(choice => choice.value === item.value);
  if (isMulti) {
    const newValues = Array.isArray(answer.values) ? answer.values.slice() : [];
    // or, using optional chaining and nullish coalescing
    // (left for learning purposes, we can use the top since it's very clear)
    // const newValues = answer.values?.slice() ?? [];

    // use object destructuring to trim out possible exclusionary answer
    const {value: oldValue, isExclusionary: _, ...answerPartial} = answer;

    if (isCurrentlySelected) {
      const removalIndex = answer.values.findIndex(value => value.choiceIndex === choiceIndex);
      newValues.splice(removalIndex, 1);
      return ({...answerPartial, isMulti, values: newValues});
    } else { // value isn't in answer
      newValues.push({ value: item.value, choiceIndex});
      return ({...answerPartial, isMulti, values: newValues});
    }
  } else {
    return ({
      isMulti: false,
      value: {
        value: item.value,
        choiceIndex
      },
      ...additionalProps // this will usually be used to set ID, or isExclusionary
    });
  }
}

export interface QuestionLikeChoiceHolder {
  isMulti: boolean,
  choices?: ResponseChoice[],
  nonconformingResponses?: NonconformingResponseChoice[]
}
export function selectChoice (item: ResponseChoice, parent: QuestionLikeChoiceHolder, answer: Answer<ValueAtom>, additionalProps: any = {}) {
  const choiceIndex = Array.isArray(parent.choices) ? parent.choices.findIndex(c => c.value === item.value) : -1;
  const currentIsFromChoices = choiceIndex > -1;
  const ncIndex = Array.isArray(parent.nonconformingResponses) ? parent.nonconformingResponses.findIndex(r => r.value === item.value) : -1;
  const currentIsFromNCArray = ncIndex > -1;
  // let isCurrentlySelected = false;

  if (currentIsFromChoices) {// the new choice is a standard option
    if (parent.isMulti === false) { // single-select
      if (answer.value.value === item.value) {
        // We deselected a single-select, return null answer
        return ({
          isMulti: false,
          value: {
            value: null
          },
          ...additionalProps
        });
      } else { 
        // We selected a new single-select, return answer with item as value
        return ({
          isMulti: false,
          value: {
            value: item.value,
            choiceIndex
          },
          ...additionalProps
        });
      }
    }

    const isExclusionary = item.isExclusionary ?? false;
    const answerIndex = answer.values?.findIndex(v => v.value === item.value) ?? -1;

    if (isExclusionary) {
      if (answerIndex > -1) {
        // We deselected an exclusionary value, so return an empty value list
        return ({
          isMulti: true,
          values: []
        });
      } else {
        // We selected a new exclusionary, so the value list is just that item
        return ({
          isMulti: true,
          values: [{
            value: item.value,
            choiceIndex
          }]
        });
      }
    } else {
      if (answerIndex > -1) {
        // We deselected a normal option, splice it out of the values list
        const newValues = answer.values.slice();
        newValues.splice(answerIndex, 1);
        return ({
          isMulti: true,
          values: newValues
        });
      } else {
        // We selected a new normal option, append it to the values list
        return ({
          isMulti: true,
          values: [...(answer.values ?? []), {value: item.value, choiceIndex}]
        });
      }
    }
  } else if (currentIsFromNCArray) { // the new choice is a nonconforming option
    if (parent.isMulti === false) {
      
    }
    const isExclusionary = item.isExclusionary ?? true; // we default NCVs to exclusionary!

  } else {
    // we couldn't find this choice on the question definition at all!
    console.error(`Toggleable item value ${item.value} not found in choices OR nonconforming! Cannot use for answer`);
  }

}