import { configureStore, EnhancedStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { default as alerts } from "./slices/alerts";
import { answerSetSlice } from "./slices/answerSets";
import { audioPlayerMiddleware, audioSlice } from "./slices/audio";
import { authMiddleware, authSlice } from "./slices/auth";
import { definitionsSlice } from "./slices/definitions";
import { default as questionnaire } from "./slices/questionnaire-old.js";
import { simulatorSlice } from "./slices/simulator";
import { patientFlowSlice } from "./slices/patient-flow";
import { metricsSlice } from "./slices/metrics";


// const bindMiddleware = middleware => {
//   if (process.env.NODE_ENV !== 'production') {
//     const { composeWithDevTools } = require('redux-devtools-extension')
//     return composeWithDevTools(applyMiddleware(...middleware))
//   }
//   return applyMiddleware(...mi ddleware)
// }

const appReducer = combineReducers({
  alerts,
  audio: audioSlice.reducer,
  questionnaire,
  simulator: simulatorSlice.reducer,
  definitions: definitionsSlice.reducer,
  answerSets: answerSetSlice.reducer,
  auth: authSlice.reducer,
  patientFlow: patientFlowSlice.reducer,
  metrics: metricsSlice.reducer
});

export const store = configureStore({
  reducer: appReducer,

  // this default set in prod is just [thunk] and in development it is
  // [thunk, immutableStateInvariant, serializableStateInvariant]
  // see https://redux-toolkit.js.org/api/getDefaultMiddleware for details
  middleware: getDefaultMiddleware => {
    // note that this is type Tuple, not a JS array
    return getDefaultMiddleware().concat(thunkMiddleware, authMiddleware, audioPlayerMiddleware);
  },
});


/**
 * Run all actions that are needed when starting the Redux store, and any other
 * generic application initialization.
 */
const startup = (store: EnhancedStore): void => {
  // store.dispatch(verifyAuth()).then((userData) => {
  //   console.warn("UD", userData);
  // });
};

// run it nowwww
startup(store);


// =============================================================================
// These type helpers are essential to actually being able to infer things in
// spite of the circular references. If you're running into circular reference
// issues with them, make sure you are using `import type` not just `import`.
// Also you shouldn't need them for most cases, as we made special constructors
// available -- useAppStore, useAppSelector, & useAppDispatch -- that already
// build in the types. (see utils/hooks.ts)
// =============================================================================

// Get the type of our store variable
export type AppStore = typeof store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore['dispatch']
