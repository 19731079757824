import firebase from "firebase";
import { IndexedString, ValueAtom } from "./core-data-types";
import { MultiValueBox, SingleValueBox } from "./fundamental";
import { ScreeningType } from "../constants/screenings";
import { LanguageISO } from "../constants/locales";

export interface AbstractAnswer {
  key: string
  isMulti: boolean;
  isNonconfomring?: boolean;
  nonconformingValues?: NonconformingWrappedValue[];
  questionKey?: string;
}

export interface SingleValuedAnswer<A extends ValueAtom> extends AbstractAnswer, SingleValueBox<A> {
  isMulti: false;
  value: A;
  values?: never;
};

export interface MultiValuedAnswer<A extends ValueAtom> extends AbstractAnswer, MultiValueBox<A> {
  isMulti: true,
  values: A[],
  value?: never;
}

/**
 * The reason a value doesn't conform with the question's assumptions, which may
 * range from the technical (datatype mismatch) to pragmatic (the user didn't
 * understand or refused to answer).
 */
export enum NonconformingValueKind {
  /**
   * A value that the system doesn't understand and doesn't match the spec.
   * Since we don't know why it's here, we preserve it but can't do much else.
   */
  Noncompliant = "noncompliant_general",
  /**
   * A subtype of Noncompliant used to mark when we know a value is "historical"
   * in the sense that it matches a spec different from the one the answer set
   * is loaded with.
   */
  Historical = "noncomplicant_historical",
  /**
   * A user is choosing not to answer. NOTE: We haven't necessarily settled on
   * whether this value is populated for *every* skipped question; for now one
   * should assume it's only when the user is in a position where they must
   * interact with an answer-like UI element in order to skip and not simply
   * from clicking "next" on a question that didn't enforce completion.
   */
  Skip = "skip_generic",
  /**
   * The question is irrelevant such that its choices, data type, phrasing, or
   * entire premise/context is not accurate to the user.
   */
  NotApplicable = "not_applicable",
  /**
   * The user doesn't know the answer, doesn't know which of multiple options
   * to select, or otherwise can't accurately use the input to provide a value
   * they are confident in.
   */
  Unsure = "unsure",
  /**
   * 
   */
  NotAvailable = "not_available",

  UserDefined = "other_user_defined"
}

export interface NonconformingWrappedValue {
  kind: NonconformingValueKind,
  value: any,
  // originalContext?: any,
  // index?: number
}

export interface  NonconformingAnswer {
  isNonconforming: true,
  nonconformingValues: NonconformingWrappedValue[];
}

export type Answer<V extends ValueAtom> = SingleValuedAnswer<V> | MultiValuedAnswer<V>;
export type GeneralAnswer = SingleValuedAnswer<ValueAtom> | MultiValuedAnswer<ValueAtom> ;

// TODO!
export type DerivedAnswerHolder = {special: string};

export function onlyAcceptMulti (a : GeneralAnswer | undefined | null) : MultiValuedAnswer<ValueAtom> | undefined {
  if (!!a) {
    if (a.isMulti) {
      return (a as MultiValuedAnswer<ValueAtom>);
    }
  }
  return undefined;
}
export function onlyAcceptMultiChoice (a : GeneralAnswer | undefined | null) : MultiValuedAnswer<IndexedString> | undefined {
  if (!!a) {
    if (a.isMulti && a.values.every(v => Number.isSafeInteger((v as IndexedString).choiceIndex))) {
      return (a as MultiValuedAnswer<IndexedString>);
    }
  }
  return undefined;
}
export function onlyAcceptSingleChoice (a : GeneralAnswer | undefined | null) : SingleValuedAnswer<IndexedString> | undefined {
  if (!!a) {
    if (!a.isMulti && (!a.value || Number.isSafeInteger((a.value as IndexedString).choiceIndex))) {
      return (a as SingleValuedAnswer<IndexedString>);
    }
  }
  return undefined;
}


export enum HumanReadableIDAlgorithm {
  Base32 = "base32-random",
  NanoID = "nanoid-random"
}
export interface HumanReadableID {
  algo: HumanReadableIDAlgorithm,
  id: string,
  bytes: firebase.firestore.Blob
}

export enum AnswerSetSubmissionStatus {
  FlowCompleted = 'completed patient flow',
  QuitDialog = 'quit - dialog',
  QuitTimeout = 'quit - timeout',
  QuitTabClosure = 'quit - tabended'
}

export interface AnswerSet {
  /**
   * Timestamp of when this set was last submitted.
   */
  submitted: number;

  /**
   * What was the context/action that submitted this answer set.
   */
  submissionType: AnswerSetSubmissionStatus;

  /**
   * The IDs of all sessions that contributed to this answer set.
   */
  sessionIds: string[];

  /**
   * The ID of the user that this set is associated with.
   */
  userId: string;

  /**
   * The organization this answer set was collected on behalf of. Right now,
   * every answer set is exculusively contained within a single organization.
   * (This greatly simplifies the siloing logic and function of dashboards.)
   */
  organizationId: string;

  /**
   * Name of the questionnaire this answer set was generated from.
   */
  questionnaire: ScreeningType | 'unknown';

  /**
   * The language the questionnaire was completed in.
   */
  language: LanguageISO;

  /**
   * The firebase ID of the answer set itself.
   */
  id?: string;

  /**
   * An optional identifier designed to be used by humans to locate the record.
   */
  humanReadableId?: HumanReadableID;

  /**
   * The current answers as a map object from the answer keys to their values.
   */
  answers: {
    [key: string]: Answer<ValueAtom>
  }
}