import React, { useMemo, useState } from "react";
import { clamp, ordinals } from "../../../utils/index";
import Picker from "react-mobile-picker";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { useSelector } from "react-redux";
import {
  Length,
  Weight,
  KNOWN_MEASUREMENT_TYPES,
} from "../../../constants/measurements";
import {
  SYSTEMS,
  METRIC,
  IMPERIAL,
  toAtomArray,
} from "../../../models/measurements";

export default function MeasurementResponse({
  answer,
  questionDefinition,
  updateAnswer,
}) {
  const [system, setSystem] = useState(
    questionDefinition?.forceSystem ?? IMPERIAL
  );

  // const subunits = useMemo(() => {
  //   if (questionDefinition.measurementType in KNOWN_TYPES) {
  //     return KNOWN_TYPES[questionDefinition.multiUnitType];
  //   } else {
  //     return null;
  //   }
  // }, [questionDefinition]);
  // const subAnswers = useMemo(() => {
  //   if (!subunits) {
  //     return [];
  //   } else {
  //     return subunits.map((su, i) => answer?.values?.[i] ?? {value: su.initial});
  //   }
  // }, [questionDefinition, answer])

  const measurement = useMemo(() => {
    if (questionDefinition.measurementType in KNOWN_MEASUREMENT_TYPES) {
      return KNOWN_MEASUREMENT_TYPES[questionDefinition.measurementType][
        system
      ];
    } else {
      return null;
    }
  }, [questionDefinition, system]);

  const submeasures = useMemo(() => {
    if (!measurement) return null;
    return toAtomArray(measurement);
  }, [measurement]);
  const answerParts = useMemo(() => {
    if (Array.isArray(submeasures)) {
      return submeasures.map((sm, i) => answer?.values?.[i] ?? { value: 0 });
    } else {
      return [];
    }
  }, [submeasures, answer]);
  const answerByUnit = useMemo(() => {
    if (Array.isArray(submeasures)) {
      return Object.fromEntries(
        submeasures.map((sm, i) => {
          return [sm.unit, answer?.values?.[i]?.value ?? 0];
        })
      );
    } else {
      return {};
    }
  }, [submeasures, answer]);

  function clearAnswers(newSystem) {
    updateAnswer({
      // id: questionDefinition.id,
      isMulti: true,
      system: newSystem ?? system,
      values: [],
    });
  }

  function updateAnswerFromNumberInput(event, index) {
    const newValues = answerParts.slice();
    // this is kind of problematic for the input since it needs to mirror the string the user has entered
    // let newValue = +event.target.value;
    // newValues[index] = {value: clamp(newValue, submeasures[index].min ?? 0, submeasures[index].max ?? Number.MAX_SAFE_INTEGER), unit: submeasures[index].unit};
    newValues[index] = {
      value: event.target.value,
      unit: submeasures[index].unit,
    };
    updateAnswer({
      // id: questionDefinition.id,
      isMulti: true,
      system,
      values: newValues,
    });
  }

  // const [pickerValue, setPickerValue_raw] = useState({})
  function setAnswerFromPicker(pickerMap) {
    // TODO: this doesn't support a blended view of both pickers and numbers
    updateAnswer({
      // id: questionDefinition.id,
      isMulti: true,
      system,
      values: submeasures.map((m, i) => ({
        value: pickerMap[m.unit],
        unit: m.unit,
      })),
    });
  }

  const MAX_EXHAUSTIVE = 500;
  function exhaustiveFor(measure) {
    let max = measure.max ?? 100;
    const min = measure.min ?? 0;
    if (max - min > MAX_EXHAUSTIVE) {
      console.error(
        `Measure is attempting to delineate ${
          max - min
        } options, too many for DOM`
      );
      max = min + MAX_EXHAUSTIVE;
    }
    return min === 0
      ? ordinals(max + 1)
      : ordinals(max - min + 1).map((i) => i + min);
  }

  function labelFor(system) {
    if (!measurement) return "n/a";
    const measureForSystem =
      KNOWN_MEASUREMENT_TYPES[questionDefinition.measurementType][system];
    if (!measureForSystem) return "n/a";
    if (measureForSystem.isTuple) {
      // TODO: the general case here gets iffy, hardcoding for now
      return `ft & in`;
    } else {
      return `${measureForSystem.unit}`;
    }
  }

  // ${measureForSystem.unitLong}

  return (
    <div className="main-column" style={{ margin: "0 auto" }}>
      {!questionDefinition.forceSystem ? (
        <ToggleButtonGroup
          color="primary"
          value={system}
          exclusive
          style={{ width: "100%" }}
          className="measurement-system-select"
        >
          {SYSTEMS.map((system) => (
            <ToggleButton
              style={{ flex: 1 }}
              value={system}
              onClick={() => {
                clearAnswers(system);
                setSystem(system);
              }}
            >
              {labelFor(system)}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      ) : null}
      <div
        style={{
          background: "#fff",
          borderRadius: "16px",
          padding: "16px 0px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle drop shadow for depth
          maxWidth: "100%", // Ensures responsiveness on mobile
          margin: "16px auto",
          position: "relative",
        }}
      >
        <Picker
          value={answerByUnit}
          onChange={setAnswerFromPicker}
          wheelMode="normal"
          height={160}
          style={{
            // height: "160px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {submeasures.map((m, i) => (
            <>
              {m.listExhaustively ? (
                <Picker.Column key={m.unit} name={m.unit}>
                  {exhaustiveFor(m).map((option) => (
                    <Picker.Item
                      key={option}
                      value={option}
                      style={
                        answerByUnit[m.unit] === option
                          ? {
                              background: "var(--evergreen)",
                              width: "100%",
                              padding: "12px",
                              // borderRadius: "8px",
                              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                              color: "var(--abyss)",
                              fontSize: "16px",
                            }
                          : {
                              padding: "12px",
                              // margin: "8px 0px",
                              margin: "0px",
                              fontSize: "16px",
                            }
                      }
                    >
                      {option}
                    </Picker.Item>
                  ))}
                </Picker.Column>
              ) : (
                <input
                  type="number"
                  className="text-center"
                  max={m.max ?? 1000}
                  min={m.min ?? 0}
                  style={{
                    flex: "1 1 0%",
                    border: "0px",
                    fontSize: 22,
                  }}
                  value={answerParts[i].value}
                  onChange={(e) => updateAnswerFromNumberInput(e, i)}
                />
              )}
              <div
                style={{
                  height: 24,
                  alignSelf: "center",
                  textAlign: "center",

                  width: "10%",
                }}
              >
                {m.unit}
              </div>
            </>
          ))}
        </Picker>
      </div>
    </div>
  );

  // return (
  //   <div className="main-column" style={{margin: "0 auto"}}>
  //     {subunits ?
  //       subunits.map((su, i) => <div className="mb-5">
  //         <label style={{display: "block"}}>{su.label}</label>
  //         <input
  //           type="number"
  //           value={subAnswers[i].value}
  //           placeholder={su.placeholder}
  //           min={su.min ?? questionDefinition.min ?? Number.MIN_SAFE_INTEGER}
  //           max={su.max ?? questionDefinition.max ?? Number.MAX_SAFE_INTEGER}
  //           step={questionDefinition.step ?? 1}
  //           onChange={e => handleUserInput(e, i)}
  //           />
  //       </div>)
  //     : <h3 className="text-danger">Unknown unit combination: {questionDefinition.multiUnitType}</h3>}
  //   </div>
  // );
}
