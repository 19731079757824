import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { CTA } from "../UI/buttons/CTA";
import { authSlice, Credentials } from "../../store/slices/auth";
import { Spinner } from "react-bootstrap";
import { JSObjectDump } from "../../utils/UtilComponents";


export const LoginComponent = ({actionOverride}: {actionOverride?: (c: Credentials) => void}) => {
  const dispatch = useAppDispatch();
  
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const user = useAppSelector(s => s.auth.user);
  const authProcessing = useAppSelector(s => s.auth.loading);
  const authError = useAppSelector(s => s.auth.error);

  function tryLogin() { 
    if (actionOverride && typeof actionOverride == 'function') {
      actionOverride({username, password});
    } else {
      dispatch(authSlice.actions.login({username, password}));
    }
  }

  return (
    <div
      className="centered-flex-container main-columm"
      style={{ minHeight: "100vh", justifyContent: "center" }}
    >
      <div className="raised-box" style={{}}>
        <h4>
          Welcome to the <u>Valleywise Emergency Department</u> portal for
          LiteraSeed.
        </h4>
        <p>
          This section is for providers and staff of the above organization.
          Please log in below. For login issues, contact LiteraSeed directly.
        </p>
        <hr style={{ marginBottom: "2em" }} />
        <div>
          <label>Username/Email</label>
          <input type="text" placeholder="email" onChange={(e) => setUsername(e.target.value)}></input>
        </div>
        <div style={{ marginTop: "0.5em" }}>
          <label>Password</label>
          <input
            type="password"
            placeholder="password"
            onChange={(e) => setPassword(e.target.value)}
          ></input>
        </div>
        <CTA
          label="Log In"
          style={{ width: "100%", marginTop: "2em" }}
          light={false}
          onClick={tryLogin}
          disabled={authProcessing}
          children={authProcessing ? <><Spinner animation="grow"/></> : null}
        />
        {authError ? (<JSObjectDump obj={authError} style={{background: "#FFE0E0"}} />) : null}
      </div>
    </div>
  );
}