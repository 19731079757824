import { useEffect } from "react";
import { Answer } from "../../../models/answers";
import { AudioLocation, getAudioLocationFromId, getAudioLocationFromIdOrNull } from "../../../models/audio";
import { Page, PageType } from "../../../models/pages";
import { safeStringify } from "../../../utils";
import { CONSENT_MODAL, KNOWN_PAGE_TYPES } from "../constants_to_move";
import { DebugPage } from "./DebugPage";
import { LanguageSelectorPage } from "./LanguageSelector";
import { LoadingPage } from "./LoadingPage";
import { QuestionPage } from "./QuestionPage";
import { RedesignedReport } from "./RedesignedReport";
import { SuccessPage } from "./SuccessPage";
import { TitlePage } from "./TitlePage";
import { AudioSequence, audioSlice, requestOrRegister } from "../../../store/slices/audio";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { IDed } from "../../../utils/database";
import { metricsSlice } from "../../../store/slices/metrics";
import { MetricsEvent } from "../../../models/metrics";
import { SubpageCount } from "../QuestionnaireFlow";

export function StandardPage ({
    questionnaire,
    answers,
    activeQuestionId,
    currentPage,
    pageNumber,
    subpage,
    questionHidden,
    hiddenChoices,
    // actions
    completeQuestion,
    navigateBack,
    acceptAnswer,
    restart,
    setFullPageModal
  }: {
    questionnaire: any,
    answers: any,
    activeQuestionId: string,
    currentPage: Page,
    pageNumber: number,
    subpage: SubpageCount,
    questionHidden: Record<string, boolean>,
    hiddenChoices: string[],
    // actions
    completeQuestion: () => void,
    navigateBack: () => void,
    acceptAnswer: (newAnswer: Answer<any>) => void,
    restart: () => boolean,
    setFullPageModal: (modalDef: any) => void
  }) {
  const dispatch = useAppDispatch();

  function recordConsent () {
    dispatch(metricsSlice.actions.recordEvent({type: MetricsEvent.KioskProvideConsent}));
    completeQuestion();
  }

  /**
   * Create an AudioSequence to narrate the elements of this page
   */
  function buildAudioLocationList () : AudioLocation[] {
    const locations: Array<AudioLocation|null> = [];

    switch (currentPage.type) {
      case PageType.Title:
      case PageType.KioskConsent:
      case PageType.KioskStart:
        locations.push(getAudioLocationFromIdOrNull(currentPage.titleTranslationId));
        locations.push(getAudioLocationFromIdOrNull(currentPage.descriptionTranslationId));
        locations.push(getAudioLocationFromIdOrNull(currentPage.textChildTranslationId));
        locations.push(getAudioLocationFromIdOrNull(currentPage.callToActionTranslationId));
        break;
      case PageType.Success:
        locations.push(getAudioLocationFromIdOrNull(currentPage.titleTranslationId));
        locations.push(getAudioLocationFromIdOrNull(currentPage.descriptionTranslationId));
        locations.push(getAudioLocationFromIdOrNull(currentPage.feedbackPromptTranslationId));
        if (Array.isArray(currentPage.feedbackChoices)) {
          currentPage.feedbackChoices.forEach(choice => {
            locations.push(getAudioLocationFromIdOrNull(choice.titleTranslationId));
          });
        }
        break
      default:
        break;
    }

    locations.push()

    // submit
    return locations.filter(l => l !== null);
  }
  
  const autoplayAudio = useAppSelector(s => s.audio.autoplay);
  /** Queue up the AudioSequence for the page */
  useEffect(() => {
    if (!autoplayAudio) return;
    if (currentPage && currentPage.type !== PageType.Question) {
      const locs = buildAudioLocationList();
      if (locs.length > 0) {
        requestOrRegister(locs, dispatch);
        const sequence: Partial<AudioSequence> & IDed = {
          id: `page-${currentPage.type}-${pageNumber}`,
          files: locs.map(l => l.filename),
          current: 0,
          loop: true,
          gap: 500, // 0.5 sec
          loopGap: 5000 // 5 sec
        };
        dispatch(audioSlice.actions.playSequence(sequence));
      }
    }
  }, [currentPage, autoplayAudio]);

  if (!KNOWN_PAGE_TYPES.includes(currentPage.type)) {
    return (
      <div
        className="centered-flex-container main-columm"
        style={{ minHeight: "100vh", justifyContent: "center" }}
      >
        <h3 className="text-center text-warning">
          Unknown Page Type: {`${currentPage.type}`}
        </h3>
        <pre
          className="p-2 mb-0"
          style={{
            whiteSpace: "pre-wrap",
            background: "#E0E0FF",
            borderRadius: "8px",
          }}
        >
          {safeStringify(currentPage)}
        </pre>
      </div>
    );
  }

  switch (currentPage.type) {
    case PageType.Loading:
      console.log(currentPage, questionnaire?.pages);
      return <LoadingPage />;
    case PageType.Error:
      return (
        <div
          className="centered-flex-container main-columm"
          style={{ minHeight: "100vh", justifyContent: "center" }}
        >
          <h3 className="text-center text-warning">{currentPage.error}</h3>
          {currentPage.trace ? (
            <pre
              className="p-2 mb-0"
              style={{
                whiteSpace: "pre-wrap",
                background: "#E0E0FF",
                borderRadius: "8px",
              }}
            >
              {currentPage.trace}
            </pre>
          ) : null}
        </div>
      );
    case PageType.Debug:
      return <DebugPage {...currentPage} advance={completeQuestion} />;
    case PageType.Title:
      return <TitlePage {...currentPage} actOnCTA={completeQuestion} />;
    case PageType.KioskStart:
      return (
        <TitlePage
          {...currentPage}
          callToAction="Start"
          actOnCTA={completeQuestion}
        />
      );
    case PageType.KioskConsent:
      return (
        <TitlePage {...currentPage} actOnCTA={recordConsent}>
          <a
            onClick={() =>
              setFullPageModal({
                type: "markdown",
                title: "Consent Form",
                content: CONSENT_MODAL,
              })
            }
            // style={{
            //   display: "block",
            //   textDecoration: "underline",
            //   width: "100%"
            // }}
          >
            Learn more about the study
          </a>
        </TitlePage>
      );
    case PageType.LanguageSelector:
      return <LanguageSelectorPage />;
    case PageType.Review:
      return (
        <div
          className="centered-flex-container main-column"
          style={{ minHeight: "100vh", justifyContent: "center" }}
        >
          <RedesignedReport
            questionnaireDefinition={questionnaire}
            answers={answers}
            user={{ name: "Prototype User" }}
            acceptAnswer={acceptAnswer}
            {...currentPage}
          />
        </div>
      );
    case PageType.Success:
      return (
        <div
          className="centered-flex-container main-column"
          style={{
            height: "100vh",
            justifyContent: "space-between",
            paddingBottom: 0,
          }}
        >
          <hr style={{ opacity: "0%" }} />
          <SuccessPage restartAction={restart} />
        </div>
      );
    case PageType.Question:
      return (
        <QuestionPage
          {...currentPage}
          questionnaire={questionnaire}
          answers={answers}
          activeQuestionId={activeQuestionId}
          subpage={subpage}
          questionHidden={questionHidden}
          hiddenChoices={hiddenChoices}
          openModal={setFullPageModal}
          acceptAnswer={acceptAnswer}
          completeQuestion={completeQuestion}
          navigateBack={navigateBack}
        />
      );
    case PageType.HybridQuestionSummary:
    case PageType.UniqueQuestion:
      // do nothing, these are not yet standardized and have custom blocks in
      // QuestionnaireFlow until better handled
      return null;
    default:
      // treat as unknown page type! but means we have a mistake in the code here...
      console.error(
        `Page type has no display case, but listed in KNOWN_TYPES: ${currentPage.type}`
      );
      return (
        <div
          className="centered-flex-container main-columm"
          style={{ minHeight: "100vh", justifyContent: "center" }}
        >
          <h3 className="text-center text-warning">
            Unknown Page Type: {`${currentPage.type}`}
          </h3>
          <pre
            className="p-2 mb-0"
            style={{
              whiteSpace: "pre-wrap",
              background: "#E0E0FF",
              borderRadius: "8px",
            }}
          >
            {safeStringify(currentPage)}
          </pre>
        </div>
      );
  }
}