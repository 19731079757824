
export enum ResponseLayout {
  GridCards = "grid",
  StackCards = "stack",
  Dropdown = "dropdown",
  Calendar = "calendar",
  Numeric = "numeric",
  Measurement = "measurement",
  ShortAnswer = "short answer",
  AnatomicalRegion = "anatomical region",
  Empty = "empty"
}

export type ChoiceLikeLayout = ResponseLayout.GridCards | ResponseLayout.StackCards | ResponseLayout.Dropdown;