import React, { ComponentProps, useMemo } from "react";
import { toggleChoice, isSelected } from "../../../models/choices";
import { ImageWithFallback } from "../../UI/images/ImageWithFallback";
import { Card } from "../../UI/Card/Card";
import { ResponseChoice } from "../../../models/questions";
import { JSObjectDump } from "../../../utils/UtilComponents";

function SqrImgCard({
  item,
  handleClick,
  isSelected,
  style = {},
}: ComponentProps<"div"> & {
  item: ResponseChoice,
  handleClick: (i: ResponseChoice, current: boolean) => void,
  isSelected: boolean
}) {
  return (
    <Card
      isSelected={isSelected}
      onClick={() => handleClick(item, isSelected)}
      art={item.art}
      title={item.label}
      stack={false}
      description={item.description}
      {...item}
    />
  );
}

export default function GridResponse({
  questionDefinition,
  answer,
  updateAnswer,
  hiddenChoices = [],
  showTrackingInfo = false,
  exclusionaryAnswersInline = true,
  displayInfoModal,
}) {
  function handleClick(item, isCurrentlySelected) {
    // debugger;
    console.log({ isCurrentlySelected });
    if (
      Array.isArray(questionDefinition?.nonconformingResponses) &&
      questionDefinition.nonconformingResponses.includes(item)
    ) {
      return handleExclusionary(item);
    }
    const updatedAnswerObj = toggleChoice(
      item,
      isCurrentlySelected,
      questionDefinition.choices,
      questionDefinition.isMulti,
      answer,
      // { id: questionDefinition.id }
    );
    updateAnswer(updatedAnswerObj);
  }

  function handleExclusionary(item) {
    const updatedAnswerObj = toggleChoice(
      item,
      answer.value?.value === item.value,
      questionDefinition.nonconformingResponses,
      false,
      answer,
      {
        // id: questionDefinition.id,
        isExclusionary: true,
      }
    );
    updateAnswer(updatedAnswerObj);
  }

  const shownChoices = useMemo(() => {
    if (!questionDefinition) {
      return [];
    }
    if (showTrackingInfo) {
      return questionDefinition.choices;
    }
    return questionDefinition.choices.filter((choice, index) => !hiddenChoices.includes(index) || isSelected(index, questionDefinition, answer));
  }, [questionDefinition, hiddenChoices, answer, showTrackingInfo])

  const anyHasDisplayLogic = questionDefinition.choices.some(
    (c) => c.displayWhen || c.skipWhen
  );

  const responses = questionDefinition.choices.map((choice, index) => (
    <>
      {showTrackingInfo ||
      !hiddenChoices.includes(index) ||
      isSelected(index, questionDefinition, answer) ? (
        <SqrImgCard
          key={choice.label}
          item={choice}
          handleClick={handleClick}
          handleIconClick={() => displayInfoModal(choice)}
          isSelected={isSelected(index, questionDefinition, answer)}
          style={hiddenChoices.includes(index) ? { opacity: 0.3 } : {}}
        />
      ) : null}
      {showTrackingInfo && anyHasDisplayLogic ? (
        <div>
          {hiddenChoices.includes(index) ? (
            <span className="badge badge-danger mr-1">hidden</span>
          ) : (
            <span className="badge badge-success mr-1">shown</span>
          )}
          {choice.displayWhen ? (
            <>
              <label>#{index} displayWhen: </label>
              <JSObjectDump
                obj={choice.displayWhen}
                style={{ maxHeight: "300px" }}
              />
            </>
          ) : null}
          {choice.skipWhen ? (
            <>
              <label>#{index} skipWhen: </label>
              <JSObjectDump
                obj={choice.skipWhen}
                style={{ maxHeight: "300px" }}
              />
            </>
          ) : null}
          {!choice.displayWhen && !choice.skipWhen ? (
            <em>#{index} (no display logic)</em>
          ) : null}
        </div>
      ) : null}
    </>
  ));

  const exclusionaries =
    exclusionaryAnswersInline &&
    Array.isArray(questionDefinition?.nonconformingResponses)
      ? questionDefinition.nonconformingResponses.map((choice, index) => (
          <>
            <SqrImgCard
              key={choice.label}
              item={choice}
              handleClick={handleExclusionary}
              handleIconClick={() => displayInfoModal(choice)}
              isSelected={answer?.value?.value === choice.value}
              style={{}}
            />
            {showTrackingInfo && anyHasDisplayLogic ? (
              <div>
                <span className="badge badge-dark mr-1">exclusionary</span> #
                {index}
              </div>
            ) : null}
          </>
        ))
      : null;

  const cardCount = useMemo(() => {
    return shownChoices.length + (Array.isArray(questionDefinition?.nonconformingResponses) ? questionDefinition.nonconformingResponses.length : 0);
  }, [shownChoices, questionDefinition])

  return (
    <div className="main-column" style={{ margin: "auto" }}>
      <div className={`grid-of-cards count-${cardCount}`}>
        {responses}
        {exclusionaries}
      </div>
    </div>
  );
}
