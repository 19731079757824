import React, { useState } from "react";

export default function FreeTextResponse({
  answer,
  questionDefinition,
  updateAnswer,
}) {
  function handleUserInput(event) {
    // call updateAnswer here with value: event.target.value and other things set as in ListQuestion
    updateAnswer({
      // id: questionDefinition.id,
      isMulti: false,
      value: {
        ...answer,
        value: event.target.value,
      },
    });
  }

  return (
    <div
      className="main-column"
      style={{
        margin: "0 auto",
        padding: questionDefinition.nonconformingResponses ? "" : "",
      }}
    >
      <textarea
        className={
          questionDefinition.nonconformingResponses
            ? "stack-height"
            : "full-height"
        }
        value={answer.value?.value}
        onChange={handleUserInput}
        placeholder={questionDefinition.placeholder ?? "Type your answer here"}
      />
    </div>
  );
}
